var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search-dnd-wrap",
      on: { touchstart: _vm.searchdndClickFn },
    },
    [
      _c("drag-mirror"),
      _c("div", { staticClass: "search-dnd-comp" }, [
        _vm.topLabels.length
          ? _c(
              "div",
              { ref: "cloneItemWrap", staticClass: "search-dnd-comp__banner" },
              [
                _vm._l(_vm.topLabels, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class: [
                        "search-dnd-comp__banner-list",
                        _vm.searchType === "judgementSearch"
                          ? "search-dnd-color"
                          : "",
                        _vm.searchType === "lawSearch"
                          ? "search-law-color"
                          : "",
                        _vm.searchType === "ledgeSearch"
                          ? "search-ledge-color"
                          : "",
                      ],
                    },
                    _vm._l(item, function (c) {
                      return _c("clone-item", {
                        key: c.value,
                        attrs: { data: c, searchType: _vm.searchType },
                        on: {
                          onTouchstart: _vm.onTouchstart,
                          onTouchmove: _vm.onTouchmove,
                          onTouchend: _vm.onTouchend,
                          onTouchcancel: _vm.onTouchcancel,
                        },
                      })
                    }),
                    1
                  )
                }),
                _c("div", { staticClass: "selp2-btdiv" }, [_vm._v("标题")]),
              ],
              2
            )
          : _vm._e(),
        _c("div", { ref: "flexArea", staticClass: "flex-area" }, [
          _c(
            "div",
            { staticClass: "query-tag__wrap" },
            [
              _vm._l(_vm.fixedTags, function (t) {
                return _c(
                  "div",
                  { key: t.key + t.value, staticClass: "fixed-tag query-item" },
                  [
                    _c("div", { staticClass: "sec" }),
                    _c("div", { staticClass: "combined" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tag-btn",
                          style: {
                            background: t.color ? "#409eff" : "#E8E8E8",
                            borderColor: t.color ? "#409eff" : "#E8E8E8",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color: t.color ? "#ffc369" : "#666666",
                              },
                            },
                            [_vm._v(_vm._s(_vm.fTagFmt2(t)[0]) + ":")]
                          ),
                          _c(
                            "span",
                            {
                              style: {
                                color: t.color ? "#FFFFFF" : "#999999",
                              },
                            },
                            [_vm._v(_vm._s(_vm.fTagFmt2(t)[1]))]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "detach",
                          on: {
                            click: function ($event) {
                              return _vm.deleteFixed(t)
                            },
                          },
                        },
                        [_c("i", { staticClass: "van-icon van-icon-cross" })]
                      ),
                    ]),
                    _c("div", { staticClass: "sec" }),
                  ]
                )
              }),
              _vm._l(_vm.dragTags, function (q, index) {
                return _c("query-item", {
                  key: q.id,
                  ref: q.id,
                  refInFor: true,
                  attrs: { id: q.id, itemData: q, itemindex: index },
                  on: {
                    "update:synonym": _vm.updateSynonym,
                    update: _vm.handleUpdate,
                    updateLabel: _vm.handleUpdateLabel,
                    combine: _vm.handleCombine,
                    detach: _vm.handleDetach,
                    onTouchstart: _vm.onTouchstart,
                    onTouchmove: _vm.onTouchmove,
                    onTouchend: _vm.onTouchend,
                    onTouchcancel: _vm.onTouchcancel,
                  },
                })
              }),
            ],
            2
          ),
        ]),
        _c("div", [
          _c(
            "div",
            { class: ["actions", _vm.milieu !== "ios" ? "actions-ios" : ""] },
            [
              _c(
                "van-button",
                { attrs: { plain: "" }, on: { click: _vm.clear } },
                [_vm._v("取消")]
              ),
              _c(
                "van-button",
                {
                  staticClass: "full-btn",
                  attrs: { type: "info", plain: "" },
                  on: { click: _vm.queryOld },
                },
                [_vm._v("检索")]
              ),
              _c(
                "van-checkbox",
                {
                  attrs: {
                    shape: "square",
                    value: _vm.checked,
                    change: _vm.onChange,
                  },
                  on: { click: _vm.checkedc },
                },
                [_vm._v("分词检索")]
              ),
            ],
            1
          ),
          _vm.milieu === "ios"
            ? _c("div", { staticClass: "actions-iosbottom" })
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }