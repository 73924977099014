var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "recommendTag" }, [
    _c("div", { staticStyle: { "text-align": "center" } }, [
      _vm._v(" 使用精确搜索未匹配到相应数据。请"),
      _c(
        "span",
        {
          staticStyle: { color: "#409eff" },
          on: {
            click: function ($event) {
              return _vm.$emit("selectClick")
            },
          },
        },
        [_vm._v("调整关键词或逻辑关系")]
      ),
      _vm._v("。 "),
    ]),
    !_vm.loading && _vm.fenciData.length
      ? _c("div", { staticClass: "fenci" }, [
          _c("p", { staticStyle: { "margin-bottom": "4px" } }, [
            _vm._v("律呗推荐"),
          ]),
          _c(
            "div",
            { staticClass: "fenci-list" },
            _vm._l(
              _vm.fenciData.filter((_, index) => index < 9),
              function (item, index) {
                return _c(
                  "div",
                  { key: index + "fenci", staticClass: "fenci-item" },
                  _vm._l(item.value, function (itemb, indexb) {
                    return _c(
                      "div",
                      {
                        key: indexb + "fencib",
                        staticClass: "fenci-list-button",
                        attrs: { type: "info", size: "large" },
                        on: {
                          click: function ($event) {
                            return _vm.useRecommendTag(itemb)
                          },
                        },
                      },
                      [
                        _c("div", {}, [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.label,
                                  expression: "item.label",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(item.label) + ":")]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "leading-tight text-left",
                              staticStyle: {
                                "white-space": "normal",
                                height: "auto",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(itemb.case_name || itemb.title) +
                                  " "
                              ),
                              itemb.name_chinese
                                ? _c(
                                    "span",
                                    { staticStyle: { "margin-left": "6px" } },
                                    [_vm._v(_vm._s(itemb.name_chinese))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          itemb.case_id
                            ? _c("p", [
                                _vm._v(" " + _vm._s(itemb.case_id) + " "),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              }
            ),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }