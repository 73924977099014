<template>
  <div
    :class="['clone-item', data.name === '标题' ? 'selp2' : '']"
    @touchstart="onTouchstart"
    @touchmove="onTouchmove"
    @touchend="onTouchend"
    @touchcancel="onTouchcancel"
    ref="cloneItem"
  >
    <div class="label-text">{{ data.name }}</div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'clone-item',
  props: {
    data: Object,
    searchType: String
  },
  data() {
    return {
      arr:
        '法律,司法解释,行政法规,部门规章,军事法规,团体规章,行业规定,地方法规,地方规范'
    }
  },
  methods: {
    ...mapMutations('dnd', [
      'SET_CLONE_DRAG',
      'SET_DRAG_SOURCE_DATA',
      'SET_POP_VISIBLE'
    ]),
    cloneitemClick() {
      console.log('cloneitem click')
      // 法律页面点击则需要增加新标签
      console.log(
        'clone click',
        this.arr.indexOf(this.data.name) !== -1,
        this.searchType === 'lawSearch'
      )
      if (
        this.searchType === 'lawSearch' &&
        this.arr.indexOf(this.data.name) !== -1
      ) {
        const tab = {
          id: 'potency_level_法律' + Math.ceil(Math.random() * 10),
          key: 'potency_level',
          label: '效力级别',
          value: [this.data.name]
        }
        this.$bus.$emit('judClickEmit', tab, false)
      } else {
        this.$bus.$emit('clone-item-click', {
          value: [this.data.name],
          key: this.data.value
        })
        console.log('发送emit', this.data.name, this.data)
      }
    },
    // select
    // touch 事件处理
    onTouchstart(e) {
      if (this.arr.indexOf(this.data.name) !== -1) {
        return
      }
      console.log('touch start 事件')
      const data = this.calcTouchData(e)
      this.SET_CLONE_DRAG(true)
      const payload = {
        type: 'clone',
        data: this.data
      }
      this.SET_DRAG_SOURCE_DATA(payload)
      this.$emit('onTouchstart', data)
    },
    onTouchmove(e) {
      const data = this.calcTouchData(e)
      this.$emit('onTouchmove', data)
    },
    onTouchend(e) {
      const data = this.calcTouchData(e)
      this.SET_CLONE_DRAG(false)
      this.$emit('onTouchend', data)
    },
    onTouchcancel(e) {
      this.SET_CLONE_DRAG(false)
      this.$emit('onTouchcancel', null)
    },
    calcTouchData(e) {
      const wrap = document.querySelector('div.search-dnd-wrap')
      const wrapSize = wrap.getBoundingClientRect()
      const touch = e.touches[0] || e.changedTouches[0]
      const size = this.$refs.cloneItem.getBoundingClientRect()
      const d = {
        type: 'clone',
        pos: touch,
        wrapSize,
        size,
        text: this.data.name
      }
      return d
    }
  },
  created() {},
  mounted() {
    this.$bus.$on('lefttreedata', res => {
      // console.log('左侧树数据接受到', res)
      this.lefttreedata = res
    })
    this.$bus.$on('search-tuodong', res => {
      // this.SET_CLONE_DRAG(res)
    })
  }
}
</script>

<style lang="stylus" scoped>
.clone-item
  height 32px
  line-height 32px
  padding 0 4px
  text-align center
  border-radius 2px
  margin-right 8px
  border 1px solid #cccccc
  color #666
  cursor grab
  user-select none
  &:last-child
    margin-right 0
  .label-text
    font-size 14px
</style>
