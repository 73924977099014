<template>
  <div class="search-list" :style="`${clientHeight}`" ref="searchList">
    <div class="judVtabsCaseC" v-show="reportCount" @click="handleCollect">
      <transition name="slide-fade">
        <div class="badge-box" v-show="showAdd">
          <span>+1</span>
        </div>
      </transition>
      <transition name="slide-fade">
        <div class="badge-box" v-show="showReduce">
          <span>-1</span>
        </div>
      </transition>
      <span class="collect">集</span>
      <span class="count">{{ reportCount }}</span>
    </div>
    <fb-member :btnLeft="btnLeft" v-if="false" />
    <!-- 新邀请注册弹框 -->
    <fb-nonmember :showLimit.sync="showLimit" />
    <fb-loading :loading="loadingFull" />
    <div v-show="loading">
      <!-- 判例 -->
      <div v-if="contentType === 'judgementSearch'">
        <template v-if="listData && listData.length">
          <div v-show="placest" class="Placeholder" style="height: 145px"></div>
          <div :class="placest === true ? 'judVtabsCaseA' : 'judVtabsCase'" v-show="dragtagsData.length > 0">
            <van-tabs v-model="judgementVtabsIndex" sticky class="search-tabs" ref="judgeTabs" scroll-lock style="width: 90%" line-height="0px" title-active-color="#409EFF">
              <van-tab title="综合排序"></van-tab>
              <van-tab title="常规排序"></van-tab>
              <van-tab title="裁判时间"></van-tab>
            </van-tabs>
            <div :class="[placest === true ? 'judgementIconA' : 'judgementIcon']" @click="selectClick">
              <van-icon name="search" class="judgementVicon" />
            </div>
            <div class="clear"></div>
          </div>
          <div :class="placestB === true ? 'judVtabsCaseB' : 'judVtabsCase'">
            <van-button plain type="info" @click="handleCancle">{{ selectJudgeIds.length }}&nbsp;取消</van-button>
            <van-button plain type="info" @click="saveCondition">保存查询条件</van-button>
            <van-button plain type="info" @click="download2">下载</van-button>
            <van-button plain type="info" @click="actionReport">{{ reportCount }}&nbsp;报告</van-button>
            <!-- <van-button plain type="info">分享</van-button> -->
          </div>
          <div ref="titcasieDom" @touchstart="titbtndown" @touchend="titbtnup" class="titcasie" v-show="reFresh">
            <!-- <div v-for="(item, index) in this.casie" :key="index + 'tt'"> -->
            <van-button type="default" v-for="(itema, indexa) in casieCopy" :key="indexa + 'td'" v-show="itema.label !== '普通案例'" @click="judClickFn(itema)">
              <div v-if="reFresh" class="titcasie-label">
                {{ itema.label }}
              </div>
              <div v-if="reFresh">{{ itema.num }}</div>
            </van-button>
            <!-- </div> -->
          </div>
          <div class="item van-hairline--top-bottom" v-for="(item, index) in listData" :key="index">
            <judgement-item ref="juditem" :itemindex="index" :item="item" :selectedIds="selectJudgeIds" @selectJudgeItem="handleSelectJudgeItem" @toDetail="toDetail" />
          </div>
        </template>
        <recommendTag
          v-if="!listData.length && contentType === 'judgementSearch'"
          :keywords="keywords"
          :contentType="contentType"
          @selectClick="selectClick"
          @goJudgementDetil="toDetail"
          @goLawDetil="toLawDetail"
        />
        <!-- <template v-else>
          <div class="empty-list" v-show="!loadingFull">
            未检索到相关内容,请重新<span @click="selectClick" style="color: #409eff"
              >调整关键词</span
            >。
          </div>
        </template> -->
      </div>
      <!-- 法律 -->
      <div v-if="contentType === 'lawSearch'">
        <div v-show="placest" class="Placeholder" style="height: 145px"></div>
        <div :class="placest === true ? 'judVtabsCaseA' : 'judVtabsCase'" v-show="dragtagsData.length > 0">
          <van-tabs v-model="judgementVtabsIndex" sticky class="search-tabsB" ref="judgeTabs" style="width: 90%" swipe-threshold="7" line-height="0px" title-active-color="#409EFF">
            <van-tab v-for="index in lawTabsTitle" :title="index" :key="index"></van-tab>
          </van-tabs>
          <!-- 推荐 常规  实施 效力  引用 最新-->
          <div :class="[placest === true ? 'judgementIconA' : 'judgementIcon']" @click="selectClick" v-show="contentType !== 'ledgeSearch' && keywordsArr[contentType].length">
            <van-icon name="search" class="judgementVicon" />
          </div>
        </div>
        <div :class="placestB === true ? 'judVtabsCaseB' : 'judVtabsCase'">
          <van-button plain type="info" @click="handleCancle">{{ selectLawIds.length }}&nbsp;取消</van-button>
          <van-button plain type="info" @click="saveCondition">保存查询条件</van-button>
          <van-button plain type="info" @click="download2">下载</van-button>
          <van-button plain type="info" @click="actionReport">{{ reportCount }}&nbsp;报告</van-button>
        </div>
        <div ref="titcasieDom" @touchstart="titbtndown" @touchend="titbtnup" class="titcasie" v-show="reFresh">
          <!-- <div v-for="(item, index) in this.casie" :key="index + 'tt'"> -->
          <van-button type="default" v-for="(itema, indexa) in lawCasieCopy" :key="indexa + 'td'" @click="judClickFn(itema)">
            <div v-if="reFresh" class="titcasie-label">{{ itema.label }}</div>
            <div v-if="reFresh">{{ itema.num }}</div>
          </van-button>
          <!-- </div> -->
        </div>
        <div class="item van-hairline--top-bottom" v-for="(item, index) in listData" :key="index">
          <!-- @click="toLawDetail(item)" -->
          <!-- <div
          class="title"
          :style="{ color: item.itemClickst ? '#ccc' : '#333' }"
        >
          <span
            class="CanNot"
            v-if="item.timeliness !== '现行有效' && item.timeliness !== ''"
          >
            {{ item.timeliness }}
          </span>
          <span
            class="text"
            v-html="item.title"
            :style="{ color: itemClickst ? '#ccc' : '#333' }"
          ></span>
          <div
            class="title-share-report"
            @click.stop="handleSelectLawItem(item)"
          >
            <van-checkbox
              class="vancheck"
              v-model="item.selected"
              shape="square"
            >
              <span class="check-num" v-show="!item.selected">
                {{ index + 1 }}
              </span>
            </van-checkbox>
          </div>
        </div> -->
          <law-item ref="lawitem" :itemindex="index" :item="item" :selectedIds="selectLawIds" @toDetail="toLawDetail" @handleSelectLawItem="handleSelectLawItem" />
          <div v-if="item.hit_datas && item.hit_datas.content">
            <template v-if="listData && listData.length">
              <div class="zkcontent" v-for="(iter, num) in item.hit_datas.content" v-show="iter.value" @click.stop="toLawDetailWithCatalog(item, num)" :key="num"></div>
            </template>
          </div>
        </div>
        <recommendTag v-if="contentType === 'lawSearch'" :keywords="keywords" :contentType="contentType" @selectClick="selectClick" @goLawDetil="toLawDetail" />
      </div>
      <!-- 知识 -->
      <div style="overflow: hidden; padding-bottom: 10px" v-if="contentType === 'ledgeSearch'">
        <div :class="placestB === true ? 'judVtabsCaseB' : 'judVtabsCase'">
          <van-button plain type="info" @click="handleCancle">{{ selectKnowledgeIds.length }}&nbsp;取消</van-button>
          <van-button plain type="info" @click="saveCondition">保存查询条件</van-button>
          <van-button plain type="info" @click="download2">下载</van-button>
          <van-button plain type="info" @click="actionReport">{{ reportCount }}&nbsp;报告</van-button>
        </div>
        <template v-if="listData && listData.length">
          <div ref="titcasieDom" @touchstart="titbtndown" @touchend="titbtnup" class="titcasie" v-show="reFresh">
            <!-- <div v-for="(item, index) in this.casie" :key="index + 'tt'"> -->
            <van-button type="default" v-for="(itema, indexa) in ledgeCasieCopy" :key="indexa + 'td'" @click="judClickFn(itema)">
              <div v-if="reFresh" class="titcasie-label">
                {{ itema.label }}
              </div>
              <div v-if="reFresh">{{ itema.num }}</div>
            </van-button>
            <!-- </div> -->
          </div>
          <div class="item van-hairline--top-bottom" v-for="(item, index) in listData" :key="index">
            <div class="title">
              <div class="text" @click="toDetail(item.url, item.source, 'zhishi')" v-html="item.title"></div>
              <div class="title-share-report" @click.stop="handleSelectKnowledgeItem(item)">
                <van-checkbox class="vancheck" v-model="item.selected" shape="square">
                  <span class="check-num" v-show="!item.selected">
                    {{ index + 1 }}
                  </span>
                </van-checkbox>
              </div>
            </div>
            <div class="zkcontent" v-for="(iter, num) in item.content" v-show="iter.value" @click="toDetail(item.url, item.source, 'zhishi')" :key="num">
              <!-- <h4 style="display:inline;">{{iter.name}}&nbsp;</h4> -->
              <span v-html="iter.value"></span>
            </div>
            <div class="showrow">
              <div class="text">{{ item.origin }}{{ ' - ' + item.pub_date }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="empty-list" v-show="!loadingFull">未检索到相关内容,请重新<span @click="selectClick" style="color: #409eff">调整关键词</span>。</div>
        </template>
      </div>
      <!-- 标准 -->
      <div style="overflow: hidden; padding-bottom: 10px" v-if="contentType === 'criterionSearch'">
        <div :class="placestB === true ? 'judVtabsCaseB' : 'judVtabsCase'">
          <van-button plain type="info" @click="handleCancle">{{ selectCriterionIds.length }}&nbsp;取消</van-button>
          <van-button plain type="info" @click="saveCondition">保存查询条件</van-button>
          <van-button plain type="info" @click="download2">下载</van-button>
          <van-button plain type="info" @click="actionReport">{{ reportCount }}&nbsp;报告</van-button>
          <!-- <van-button plain type="info">分享</van-button> -->
        </div>
        <template v-if="listData && listData.length">
          <div class="item van-hairline--top-bottom" v-for="(item, index) in listData" :key="index" @click="criterionSearchDetail(item.url)">
            <div class="title">
              <div class="text">
                <span class="CanNot" v-if="item.stdStatus !== '现行'">
                  {{ item.stdStatus }}
                </span>
                {{ item.id + ' ' }}{{ item.stdTitle }}
              </div>
              <div class="title-share-report" @click.stop="handleSelectCriterionItem(item)">
                <van-checkbox class="vancheck" v-model="item.selected" shape="square">
                  <span class="check-num" v-show="!item.selected">
                    {{ index + 1 }}
                  </span>
                </van-checkbox>
              </div>
            </div>
            <div class="showrow">
              <div class="text">{{ item.department + ' ' }}{{ item.publishDate + ' ' }}{{ item.execDate }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="empty-list" v-show="!loadingFull">未检索到相关内容,请重新<span @click="selectClick" style="color: #409eff">调整关键词</span>。</div>
        </template>
      </div>
      <!-- <template
        v-if="
          (contentType === 'judgementSearch' || contentType === 'lawSearch') &&
          listData.length === 0
        "
        ><recommendTag
          :keywords="keywords"
          :contentType="contentType"
          @selectClick="selectClick"
          @goJudgementDetil="toDetail"
          @goLawDetil="toLawDetail"
      /></template> -->
    </div>

    <div class="loading-hint">
      <fb-loading :loading="loadingMore" />
    </div>
    <div class="more" v-if="listData.length >= 10 && !loadingMore && loading && isNext">
      <span @click="next">加载更多</span>
    </div>
  </div>
</template>

<script>
import judgementItem from './judgementItem'
import lawItem from './lawItem'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import FbMember from '@/components/fb-member'
import { caseBase, pydataBase } from '~api-config'
import recommendTag from '../../compoments/recommendTag.vue'
export default {
  name: 'search-list',
  props: {
    loading: Boolean,
    loadingFull: Boolean,
    loadingMore: Boolean,
    isNext: Boolean,
    contentType: {
      type: String,
      default: 'judgementSearch',
    },
    listData: Array,
    sort: Number,
    conditions: [Object, Array],
    keywordsArr: Object,
  },
  computed: {
    ...mapState('user', ['userInfo', 'userinfo']),
    ...mapState('nonmemberLimit', ['isValid']),
    ...mapState('searchReport', ['searchCollectionId', 'judgeReports', 'lawReports', 'criterionReports', 'knowledgeReports', 'clearCheck']),
    ...mapGetters('searchReport', ['reportCount']),
    ...mapGetters('user', ['userId']),
    selectJudgeIds() {
      return this.selectJudgeList && this.selectJudgeList.length ? this.selectJudgeList.map((item) => item.doc_id) : []
    },
    selectLawIds() {
      return this.selectLawList.length ? this.selectLawList.map((item) => item.law_id) : []
    },
    selectKnowledgeIds() {
      return this.selectKnowledgeList.length ? this.selectKnowledgeList.map((item) => item.url) : []
    },
    selectCriterionIds() {
      return this.selectCriterionList.length ? this.selectCriterionList.map((item) => item.nsId) : []
    },
    keywords() {
      return this.keywordsArr[this.contentType]
    },
  },
  components: {
    judgementItem,
    FbMember,
    lawItem,
    recommendTag,
  },
  data() {
    return {
      showLimit: false, // 是否显示非会员
      selectJudgeList: [], // 选中的判例
      selectLawList: [], // 选中的法律
      selectKnowledgeList: [], // 选中的知识
      selectCriterionList: [], // 选中的标准
      showAdd: false,
      showReduce: false,
      lastCount: 0,
      // selectedIds: [],
      btnLeft: 0,
      clientHeight: 'min-height:' + document.body.clientHeight + 'px',
      judgeTabsDom: null,
      placest: false,
      judgementVtabsIndex: 0,
      lawTabsTitle: ['推荐', '常规', '实施', '效力', '引用', '最新'],
      casie: [],
      placestB: false,
      placestC: false,
      checkarr: [],
      searchParams: [],
      reFresh: true,
      dragtagsData: [],
      casieCopy: [],
      checkarrNum: 0,
      lawConstantList: ['法律', '司法解释', '地方司法文件', '行政法规', '地方性法规', '部门规章', '行业规定'],
      lawCasieCopy: [],
      lawCasie: [],
      ledgeCasieCopy: [],
      ledgeCasie: [],
    }
  },
  methods: {
    // ...mapMutations('nonmemberLimit', ['SET_INVITE_DIALOG_SHOW']),
    ...mapMutations('searchReport', ['SET_SEARCH_COLLECTION_ID', 'SET_KNOWLEDGE_REPORT', 'SET_CRITERION_REPORT', 'SET_JUDGE_REPORT', 'SET_LAW_REPORT', 'SET_CLEAR_CHECK', 'SET_LAW_ID']),
    ...mapActions('searchReport', ['getStampReport', 'setStampReport']),
    initReportData() {
      // console.log('-------- initReportData函数中 -------')
      const value = this.contentType
      if (value === 'judgementSearch') {
        this.selectJudgeList = this.judgeReports
        this.listData.forEach((item) => {
          const index = this.selectJudgeIds.findIndex((val) => val === item.doc_id)
          if (index !== -1) {
            item.selected = true
          } else {
            item.selected = false
          }
        })
      } else if (value === 'lawSearch') {
        this.selectLawList = this.lawReports
        this.listData.forEach((item, i) => {
          const index = this.selectLawIds.findIndex((val) => val === item.law_id)
          if (index !== -1) {
            this.$set(this.listData[i], 'selected', true)
          } else {
            this.$set(this.listData[i], 'selected', false)
          }
        })
        // 修改样式
        /* this.$nextTick(() => {
          console.log(this.$refs.judgeTab)
        }) */
      } else if (value === 'criterionSearch') {
        this.selectCriterionList = this.criterionReports
        this.listData.forEach((item, i) => {
          const index = this.selectCriterionIds.findIndex((val) => val === item.nsId)
          if (index !== -1) {
            this.$set(this.listData[i], 'selected', true)
          } else {
            this.$set(this.listData[i], 'selected', false)
          }
        })
      } else if (value === 'ledgeSearch') {
        this.selectKnowledgeList = this.knowledgeReports
        this.listData.forEach((item, i) => {
          const index = this.selectKnowledgeIds.findIndex((val) => val === item.url)
          if (index !== -1) {
            this.$set(this.listData[i], 'selected', true)
          } else {
            this.$set(this.listData[i], 'selected', false)
          }
        })
      }
    },
    // 打开会员弹框
    async openMemberDialog(secKind) {
      if (this.userinfo && this.userinfo.memberStatus !== 1 && this.userinfo.restNum <= 0) {
        const url = `${this.$base}/management/users/${this.userInfo.id}`
        const res = await this.$axios.get(url)
        const { code, data } = res.data
        if (code === 200) {
          const temp = this.userInfo
          temp.memberStatus = data.memberStatus
          this.$store.commit('user/resumeLogin', {
            userInfo: temp,
          })
          if (data.memberStatus !== 1) {
            switch (secKind) {
              case 'panli':
                this.btnLeft = 0
                break
              case 'falv':
                this.btnLeft = 1
                break
              case 'zhishi':
                this.btnLeft = 2
                break
              case 'biaozhun':
                this.btnLeft = 3
                break
            }
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    },
    /**
     * 跳转到法律详情页
     */
    async toLawDetail(item, catlog, secKind) {
      // 这里临时取消大数据的非会员限制
      // var flag = await this.openMemberDialog('falv')
      // if (flag) {
      //   return this.$bus.$emit('fb-member', {
      //     dialogVisible: true,
      //     kind: 'dashuju',
      //     secKind: secKind
      //   })
      // }
      console.log('item, catlog', item)
      this.SET_LAW_ID(item.law_id)
      const payload = { law_id: item.law_id, hit_datas: { catlog: [item.catlog] } }
      this.$emit('toLawDetail', payload)
    },
    // 打开详情页 携带 #第n条# 参数
    toLawDetailWithCatalog(item, catalogIndex) {
      const toCatalog = (item.catlog && item.catlog[catalogIndex]) || ''
      this.toLawDetail(item, toCatalog)
    },
    // 跳转到判例详情
    // 知识
    async toDetail(payload, source, secKind) {
      console.log('toDetail', payload, source, secKind)
      if (secKind === undefined) {
        secKind = 'panli'
      }

      // 这里临时取消大数据的非会员限制
      // var flag = await this.openMemberDialog(secKind)
      // if (flag) {
      //   return this.$bus.$emit('fb-member', {
      //     dialogVisible: true,
      //     kind: 'dashuju',
      //     secKind: secKind
      //   })
      // }
      this.$emit('toDetail', payload, source)
    },
    // 跳转到标准详情
    async criterionSearchDetail(url) {
      if (JSON.parse(sessionStorage.getItem('userInfo') || '').thirdPartySource === 'ZH') {
        // 智和调用方法
        window.parent.location.href = url
      }
      var flag = await this.openMemberDialog('biaozhun')
      if (flag) {
        return this.$bus.$emit('fb-member', {
          dialogVisible: true,
          kind: 'dashuju',
          secKind: 'biaozhun',
        })
      }
      const ua = navigator.userAgent
      if (ua === 'fb-flutter-Webview') {
        window.showNews.postMessage(url)
      } else if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.showNews(url)
      } else if (ua === this.$ua.i) {
        return this.$notify('苹果端不支持查看')
        // window.webkit.messageHandlers.showNews.postMessage(url)
      } else if (this.$route.query.isMiniApp) {
        this.$notify({
          message: '请前往律呗APP端查看',
          duration: 1000,
          type: 'warning',
        })
      }
    },
    selectClick() {
      this.$emit('onMockInputFocus')
    },
    next() {
      window.console.log('select userinfo1231', this.userInfo)
      const { isMiniVip } = this.$route.query
      console.log(isMiniVip, 'isminivip')
      if (isMiniVip) {
        this.$emit('next')
        return
      }
      if (this.userinfo && this.userinfo.memberStatus !== 1 && this.userinfo.restNum <= 0) {
        this.SET_INVITE_DIALOG_SHOW(true)
        this.showLimit = true
        return
      }
      this.$emit('next')
    },
    judClickFn(e) {
      console.log('点击了', e)
      this.$bus.$emit('judClickEmit', {
        color: true,
        id: e.key + '_' + e.label,
        key: e.key,
        label: e.partit,
        value: [e.label],
      })
      this.reFreshFn()
    },
    reFreshFn() {
      console.log('重新渲染')
      this.reFresh = false
      this.$nextTick(() => {
        setTimeout(() => {
          this.reFresh = true
        }, 500)
      })
    },
    conditionsinit() {
      this.casie = []
      this.lawCasie = []
      this.ledgeCasie = []
      console.log('this.conditions=======>', this.conditions)
      if (this.conditions && this.conditions.judgementSearch) {
        this.conditions.judgementSearch.forEach((item) => {
          if (item.title === '案件属性' || item.title === '法院层级') {
            item.data.forEach((itema) => {
              if (itema.label !== '普通案例') {
                itema.partit = item.title
                this.casie.push(itema)
              }
            })
          }
        })
      }
      if (this.conditions && this.conditions.lawSearch) {
        this.conditions.lawSearch.forEach((item) => {
          if (item.title === '效力级别') {
            item.data.map((itemb) => {
              for (var i = 0; i < this.lawConstantList.length; i++) {
                if (itemb.label.indexOf(this.lawConstantList[i]) !== -1) {
                  const currentItem = itemb.child.find((v) => v.label === itemb.label) || itemb
                  currentItem.partit = '效力级别'
                  this.lawCasie.push(currentItem)
                  break
                }
              }
            })
          }
        })
      }
      if (this.conditions && this.conditions.ledgeSearch) {
        this.conditions.ledgeSearch.forEach((item) => {
          if (item.title === '来源') {
            item.data.forEach((itema) => {
              itema.partit = item.title
              this.ledgeCasie.push(itema)
            })
          }
        })
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.casieCopy = []
          this.casieCopy = JSON.parse(JSON.stringify(this.casie))
          this.lawCasieCopy = []
          this.lawCasieCopy = JSON.parse(JSON.stringify(this.lawCasie))
          this.ledgeCasieCopy = []
          this.ledgeCasieCopy = JSON.parse(JSON.stringify(this.ledgeCasie))
          this.$forceUpdate()
          // console.log('按钮数据处理完成', this.casieCopy)
        }, 10)
      })
    },
    titbtndown() {
      // console.log('关闭滑动')
      this.$bus.$emit('swipeable', false)
    },
    titbtnup() {
      // console.log('开启滑动') Missing space before value for key 'name'
      this.$bus.$emit('swipeable', true)
    },
    datainit() {
      // console.log(this.checkarr)
      const item = this.checkarr[0]
      const data = {
        name: item.data.case_name,
        children: this.checkarr.map((itema) => {
          return {
            lawid: itema.data.id,
            name: itema.data.case_name,
            type: 0,
            children: itema.data.content.map((itemb) => {
              return {
                name: itemb.name,
                lawid: itema.data.id,
                text: ((reg) => {
                  let txt = ''
                  reg.forEach((itemc) => {
                    txt += itemc
                  })
                  txt = txt.replace(/<\/?[^>]*>/g, '')
                  return txt
                })(itemb.value),
              }
            }),
          }
        }),
      }
      console.log('data处理完成', data)
      return data
    },
    // mark会改变dom解构添加dom
    // obmark() {
    //   var targetNode = document.getElementById('el-test')

    //   // 观察者的选项(要观察哪些突变)
    //   var config = { attributes: true, childList: true, subtree: true }

    //   // 当观察到突变时执行的回调函数
    //   var callback = function(mutationsList) {
    //     mutationsList.forEach(function(item, index) {
    //       if (item.type == 'childList') {
    //         console.log('有节点发生改变，当前节点的内容是：')
    //         console.log(item.target.innerHTML)
    //       } else if (item.type == 'attributes') {
    //         console.log('修改了' + item.attributeName + '属性')
    //       }
    //     })
    //   }

    //   // 创建一个链接到回调函数的观察者实例
    //   var observer = new MutationObserver(callback)

    //   // 开始观察已配置突变的目标节点
    //   observer.observe(targetNode, config)

    //   // 停止观察
    //   // observer.disconnect();
    // },
    saveCondition() {
      console.log('保存查询条件', this.searchParams)
      this.$axios
        .post(`${pydataBase}/api/nvi/judgementSearchRecord`, this.searchParams)
        .then(({ data }) => {
          if (data.message === '保存数据成功') {
            this.$notify({
              message: '保存成功',
              background: '#1989fa',
            })
          } else {
            this.$notify('保存失败')
          }
        })
        .catch((err) => {
          this.$notify('保存失败')
          console.log(err)
        })
    },
    // 勾选判例
    handleSelectJudgeItem(payload) {
      this.SET_CLEAR_CHECK(false)
      // console.log('###### 勾选判例 #######')
      if (this.selectJudgeIds.indexOf(payload.doc_id) !== -1) {
        this.selectJudgeList = this.selectJudgeList.filter((item) => item.doc_id !== payload.doc_id)
      } else {
        this.selectJudgeList = [...this.selectJudgeList, payload]
      }
      // this.SET_JUDGE_REPORT(this.selectJudgeList)
      this.setStampReport({
        userId: this.userId,
        type: 'judgeReports',
        reportData: [...this.selectJudgeList],
        callback: () => {
          console.log('callback fn -------- 勾选判例 回调函数 --------')
          this.initReportData()
        },
      })
    },
    // 勾选法律
    handleSelectLawItem(payload) {
      this.SET_CLEAR_CHECK(false)
      if (this.selectLawIds.indexOf(payload.law_id) !== -1) {
        this.selectLawList = this.selectLawList.filter((item) => item.law_id !== payload.law_id)
      } else {
        this.selectLawList = [...this.selectLawList, payload]
      }
      // this.SET_LAW_REPORT(this.selectLawList)
      this.setStampReport({
        userId: this.userId,
        type: 'lawReports',
        reportData: [...this.selectLawList],
      })
      this.titsearETFN({
        data: payload,
        id: payload.law_id,
        check: !payload.selected,
        callback: () => {
          console.log('callback fn -------- 勾选法律 回调函数 --------')
          this.initReportData()
        },
      })
    },
    // 勾选知识
    handleSelectKnowledgeItem(payload) {
      this.SET_CLEAR_CHECK(false)
      let arr = []
      if (this.selectKnowledgeIds.indexOf(payload.url) !== -1) {
        arr = this.selectKnowledgeList.filter((item) => item.url !== payload.url)
      } else {
        arr = [...this.selectKnowledgeList, payload]
      }
      // this.SET_KNOWLEDGE_REPORT(this.selectKnowledgeList)
      this.setStampReport({
        userId: this.userId,
        type: 'knowledgeReports',
        reportData: [...arr],
        callback: () => {
          console.log('callback fn -------- 勾选知识 回调函数 --------')
          this.initReportData()
        },
      })
      this.titsearETFN({
        data: payload,
        id: payload.url,
        check: !payload.selected,
      })
    },
    // 勾选标准
    handleSelectCriterionItem(payload) {
      this.SET_CLEAR_CHECK(false)
      let arr = []
      if (this.selectCriterionIds.indexOf(payload.nsId) !== -1) {
        arr = this.selectCriterionList.filter((item) => item.nsId !== payload.nsId)
      } else {
        arr = [...this.selectCriterionList, payload]
      }
      // this.SET_CRITERION_REPORT(this.selectCriterionList)
      this.setStampReport({
        userId: this.userId,
        type: 'criterionReports',
        reportData: [...arr],
        callback: () => {
          console.log('callback fn -------- 勾选标准 回调函数 --------')
          this.initReportData()
        },
      })
      this.titsearETFN({
        data: payload,
        id: payload.nsId,
        check: !payload.selected,
      })
    },
    handleCancle() {
      this.placestB = false
    },
    download2() {
      console.log('dwnload2')
      console.log(this.checkarr)
      const urlarr = []
      for (const i of this.checkarr) {
        setTimeout(() => {
          console.log('下载*******', i)
          this.$axios.get(`${caseBase}/document/legal/precedent/${i.id}/file`).then((res) => {
            if (res.data.code === '200') {
              const url = res.data.data.path
              urlarr.push({ url })
              if (urlarr.length === this.checkarr.length) {
                for (let j = 0; j < urlarr.length; j++) {
                  setTimeout(() => {
                    this.downloadUrl(urlarr[j])
                  }, j * 10)
                }
              } else {
                console.log('未到最后一个', urlarr)
                // console.log(i.length, this.checkarr.length)
              }
            } else {
              this.$notify('下载失败')
            }
          })
        }, 200 * i)
      }
    },
    downloadUrl(arr) {
      console.log('a标签下载', arr.url)
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none' // 防止影响页面
      iframe.style.height = 0 // 防止影响页面
      iframe.src = arr.url
      document.body.appendChild(iframe) // 这一行必须，
      // iframe挂在到dom树上才会发请求
      // 5分钟之后删除（onload方法对于下载链接不起作用，就先抠脚一下吧）
      setTimeout(() => {
        iframe.remove()
      }, 5 * 60 * 1000)
    },
    handleCollect() {
      this.$emit('collect')
    },
    actionReport() {
      this.$router.push('/transform/searchReport')
      // window.open(`${window.origin}/transform`)
    },
    actionShare() {
      const ua = this.ua
      // if (ua() !== this.$ua.a && ua() !== this.$ua.i) {
      //   this.$notify('非原生端不支持 分享')
      //   return
      // }
      const date = new Date()
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, {
          name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《法律依据》`,
          children: this.selectLawForAction,
        })
        .then((res) => {
          const name = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《法律依据》`
          if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-line
            try {
              window.shareCaseFile.postMessage(
                JSON.stringify({
                  filename: name,
                  url: window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`),
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.a) {
            // eslint-disable-next-line no-undef
            AND2JS.shareCaseFile(name, window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`))
          } else if (ua === this.$ua.i) {
            window.webkit.messageHandlers.shareCaseFile.postMessage(
              JSON.stringify({
                filename: name,
                url: window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`),
              })
            )
          } else {
            this.$notify('异常，暂无法使用')
          }
        })
        .catch((err) => {
          this.$notify('分享失败')
          Promise.reject(err)
        })
    },
    /* 选中显示顶部按钮placestB 替换为 显示侧边收集placestC */
    titsearETFN(data) {
      // 判断是否已保存
      for (let i = 0; i < this.checkarr.length; i++) {
        if (this.checkarr[i].id === data.id && !data.check) {
          // 删除当前
          this.checkarr.splice(i, 1)
          i -= 1
          // this.placestB = this.checkarr.length > 0
          this.placestC = this.checkarr.length > 0
          return
        }
      }
      if (data.check) {
        this.checkarr.push(data)
        // this.placestB = this.checkarr.length > 0
        this.placestC = this.checkarr.length > 0
      }
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    actionDownload() {
      const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      const date = new Date()
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, {
          name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 下载内容`,
          children: this.datainit(),
        })
        .then((res) => {
          console.log(res, '====>>> 接口返参13', '没使用的方法')
          // this.$toast('网址为：' + res.data.data.path)
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // eslint-disable-next-line
            AND2JS.showFilePreview(res.data.data.path)
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-linetodo0927
            try {
              // 之前的方法
              // window.fbDownloadFile.postMessage(res.data.data.path)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: res.data.data.path,
                  fileName: time,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.i) {
            // ios环境
            // eslint-disable-next-line
            window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data.path)
          }
        })
        .catch((err) => {
          this.$notify('下载失败')
          Promise.reject(err)
        })
    },
    ua() {
      return navigator.userAgent
    },
    async getUserInfo() {
      this.$axios.get(`${this.$base}/management/users/${this.userInfo.id || this.$route.query.id}`).then(({ data: res }) => {
        if (res.code === 200) {
          window.console.log('send data', res)
          this.$store.commit('user/setUserinfo', res.data)
          window.console.log(this.$store.state.user.userinfo)
        }
      })
    },
    selectLawForAction() {
      // const data = this.selectLawList.map(law => {
      //   const { law_id: lawid, name, checked: flag } = law
      //   return {
      //     flag,
      //     lawid,
      //     name,
      //     children: law.selectedChildren.map(item => {
      //       const { category, law_id: lawid, title: name, flag, text } = item
      //       return { category, lawid, name, flag, text }
      //     })
      //   }
      // })
      // return data
      return []
    },
  },
  updated() {},
  mounted() {
    this.$bus.$on('searchlist-show', (res) => {
      if (this.placest !== res) {
        this.placest = res
      }
    })
    this.$bus.$on('titsearET', (res) => {
      this.titsearETFN(res)
    })
    this.$bus.$on('searchParams', (res) => {
      this.searchParams = res
    })
    this.$bus.$on('dragtagsData', (res) => {
      this.dragtagsData = res
    })
    this.initReportData()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created() {
    if (!sessionStorage.getItem('thirdParty')) {
      this.getUserInfo()
    }
  },
  watch: {
    reportCount(nv, ov) {
      this.lastCount = ov
      if (nv === ov + 1) {
        this.showAdd = true
        setTimeout(() => {
          this.showAdd = false
        }, 600)
      } else if (nv === ov - 1) {
        this.showReduce = true
        setTimeout(() => {
          this.showReduce = false
        }, 600)
      }
      if (ov === 0) {
        console.log(nv, ov, 'reportCount+++++++++++++watch监听++++++++++')
        this.initReportData()
      } else if (nv === 0) {
        console.log(nv, ov, 'reportCount+++++++++++++watch监听++++++++++')
        this.initReportData()
      }
    },
    contentType(value, oval) {
      console.log(value, oval, 'contentType----watch')
      switch (value) {
        case 'judgementSearch':
          this.btnLeft = 0
          break
        case 'lawSearch':
          this.btnLeft = 1
          break
        case 'ledgeSearch':
          this.btnLeft = 2
          break
        case 'criterionSearch':
          this.btnLeft = 3
          break
      }
      if (this.clearCheck) {
        this.listData.forEach((item) => {
          item.selected = false
        })
        this.selectJudgeList = []
        this.selectCriterionList = []
        this.selectKnowledgeList = []
        this.selectLawList = []
      }
    },
    sort(nv) {
      this.judgementVtabsIndex = nv
    },
    judgementVtabsIndex(nv) {
      console.log('juindex', nv)
      if (this.$refs.titcasieDom) {
        this.$refs.titcasieDom.scrollIntoView()
      }
      this.$emit('setSort', nv)
    },
    conditions: {
      deep: true,
      handler(nv) {
        console.log('condition变化', nv)
        this.conditionsinit()
      },
    },
    listData: {
      deep: true,
      handler(nv) {
        this.$nextTick(() => {
          // console.log(nv, 'listData++++++++++++watch监听++++++++++')
          // this.initReportData()
        })
      },
    },
    clearCheck(val) {
      if (val) {
        if (this.contentType === 'judgementSearch') {
          this.selectJudgeList = []
        } else {
          this.selectCriterionList = []
          this.selectKnowledgeList = []
          this.selectLawList = []
        }
        this.listData.forEach((item) => {
          item.selected = false
        })
      }
    },
  },
}
</script>

<style lang="stylus">
.search-list {
  position: relative;

  & .empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 320px;
    font-size: 14px;
    color: #999;
  }

  & .judgement-item, .item {
    position: relative;

    & .title {
      width: 100%;
      padding: 10px;
      overflow: hidden;
      cursor: pointer;
      display: flex;
      flex-direction: row;

      & .text {
        font-size: 16px;
        color: #333333;
        float: left;
        font-weight: 600;
        flex: 1;
      }

      & .CanNot {
        height: 21px;
        box-sizing: border-box;
        background: #ff9c39;
        color: #fff;
        line-height: 21px;
        padding: 0 5px;
        border-radius: 3px;
      }
    }

    & .showrow {
      width: 100%;
      padding: 10px;
      padding-top: 0;
      overflow: hidden;

      & .text {
        font-size: 12px;
        color: #999999;
        font-weight: normal;
        float: left;
      }

      & .open {
        float: left;
        font-size: 12px;
        color: #999999;
        font-weight: normal;
        overflow: hidden;
        margin-right: 10px;
        cursor: pointer;

        & i {
          font-size: 14px;
        }

        & .tip {
          overflow: hidden;
          padding: 3px 5px 3px;
          background-color: #ff9c39;
          font-size: 14px;
          color: white;
          border-radius: 3px;
        }
      }
    }

    & .zkcontent {
      width: 100%;
      padding: 0 10px;
      margin-bottom: 10px;

      & h4 {
        font-size: 14px;
      }

      & span {
        font-size: 12px;
      }
    }
  }

  & .more {
    color: #666666;
    font-size: 16px;
    text-align: center;
    margin: 20px 0px 20px 0px;
  }
}
</style>
<style scoped lang="stylus">
.title-share-report {
  float: right;
  position: relative;
}

.title-share-report /deep/ .van-checkbox__label {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}
</style>
<style scoped>
.judgementIcon {
  position: absolute;
  top: 13px;
  right: 15px;
  z-index: 100;
}
.judgementIconA {
  position: fixed;
  top: 13px;
  right: 15px;
  z-index: 100;
}
.search-tabs > ::v-deep .van-tabs__content {
  display: none;
  /* width: 90%; */
}
.search-tabs > ::v-deep div {
  outline: rgb(255, 255, 255) dotted thick;
}
.search-tabsB > ::v-deep .van-tabs__content {
  display: none;
}
.judVtabsCase {
  position: relative;
  background: #fff;
  display: none;
}
.judVtabsCaseA {
  position: fixed;
  width: 100%;
  z-index: 50;
  top: 0px;
  background: #fff;
}
.titcasie > .van-button > .van-button__content > .van-button__text > .titcasie-label >>> mark {
  /* display: none; */
  color: #323233;
  font-weight: 500;
}
.judVtabsCaseC {
  position: fixed;
  width: 45px;
  height: 45px;
  z-index: 60;
  bottom: 50px;
  right: 20px;
  border-radius: 50%;
  background: #b6b6b6;
  box-shadow: 0px 0px 2px 0px rgba(204, 204, 204, 0.56);
  text-align: center;
  line-height: 45px;
  margin-bottom: 10px;
  color: #ffffff;
  font-size: 24px;
}
.judVtabsCaseC .badge-box {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 16px;
  text-align: right;
  color: red;
  z-index: 999;
  font-weight: bold !important;
  font-size: 14px;
}
.count {
  font-size: 16px !important;
  position: absolute;
  line-height: 16px;
  color: red;
  right: 3px;
  top: 3px;
}
.judVtabsCaseB {
  position: fixed;
  width: 100%;
  z-index: 60;
  top: 0px;
  background: #fff;
}
::v-deep.judVtabsCaseB > button:nth-child(1) {
  background: #999;
  color: #fff;
  border: none;
}
::v-deep.judVtabsCaseB > button:not(:nth-child(1)) {
  background: #1989fa;
  color: #fff;
  border-color: #fff;
  float: right;
}
.clear {
  clear: both;
}
::v-deep.van-tab.van-tab--active {
  background: #000;
}
.judgementVicon {
  font-size: 20px;
}
.titcasie {
  display: flex;
  overflow: auto;
}

.titcasie > button {
  white-space: nowrap;
  border-radius: 15px;
  height: 35px;
  font-size: 12px;
}
.titcasie-label :nth-child(2) {
  color: #323233 !important;
  font-weight: inherit;
  display: none !important;
}
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translate(-30px, -30px) scale(3);
  opacity: 0;
}
</style>
<style lang="stylus">
.search-tabs {
  & .van-tabs__line {
    height: 0px !important;
  }
}

.search-tabsB {
  & .van-tabs__line {
    height: 0px !important;
  }
}
</style>
