<template>
  <div class="judgement-item" @click="toDetail(tabsData.law_id, tabsData.case_attr, '', $event)">
    <div class="title" :style="{ color: itemClickst ? '#ccc' : '#333' }">
      <span class="CanNot" v-if="tabsData.timeliness !== '现行有效' && tabsData.timeliness !== ''">
        {{ tabsData.timeliness }}
      </span>
      <span class="text" v-html="tabsData.title" :style="{ color: itemClickst ? '#ccc' : '#333' }"></span>
      <div class="title-share-report" @click.stop="handleSelectLawItem(item)">
        <van-checkbox class="vancheck" v-model="isSelected" shape="square">
          <span class="check-num" v-show="!isSelected">
            {{ itemindex + 1 }}
          </span>
        </van-checkbox>
      </div>
    </div>
    <div class="extra-info" :style="{ color: itemClickst ? '#ccc' : '#333' }">
      <span class="attrs" v-if="hasAttr">
        <span class="attr" v-if="item.publish_machinery">{{ item.publish_machinery }}</span>
        <span class="attr" v-if="item.pub_date">{{ item.pub_date }}</span>
        <span class="attr" v-if="item.exc_date">{{ item.exc_date }}</span>
        <span class="attr" v-if="item.potency_level">{{ item.potency_level }}</span>
      </span>
    </div>
    <div @touchstart="titbtndown" @touchend="titbtnup" @click.stop="" ref="lawcontentDom" class="vantabshull" v-if="tabList.length > 0">
      <van-tabs type="card" v-model="activeName">
        <van-tab v-for="(itb, index) in tabList" :key="index + 'card'" v-show="tabList.length > 0" :title="itb.name" :name="itb.name">
          <template #title v-if="itb.f">
            <van-tag round type="primary">{{ parseTabName(itb) }}</van-tag>
          </template>
          <template #title v-else>
            {{ itb.name }}
          </template>
          <div :ref="`tabsA${index}`" :id="`tab-${activeName}`" class="tabsA-div" v-if="isAddition(itb.name)">
            <law-addition :name="itb.name" :list="itb.value" @toLaw="handleToLaw" />
          </div>

          <div class="tabsA-div" :id="`tab-${activeName}`" :ref="`tabsA${index}`" v-else-if="itb.name === '常用法条' || itb.name === '相关法律' || itb.name === '引用判例'">
            <div :class="itb.name === '引用法条' ? 'tabsAspan' : ''" v-for="(item1, index1) in itb.value" @click.stop="toDetail(tabsData.law_id, '', '', $event)" :key="index1 + 'cardsp'">
              <span v-if="itb.catlog && itb.catlog[index1]" class="tabsA-div-titsp">{{ itb.catlog[index1] }}</span>
              <span>
                {{ item1.title }}
              </span>
              <span style="color: #1f459e"> 被{{ item1.num }}篇判例引用 </span>
            </div>
          </div>
          <div :id="`tab-${activeName}`" class="tabsA-div" :ref="`tabsA${index}`" v-else>
            <div
              :class="itb.name === '引用法条' ? 'tabsAspan' : ''"
              v-for="(item1, index1) in itb.value"
              @click.stop="toDetail(tabsData.law_id, '', itb.catlog[index1], $event)"
              :key="index1 + 'cardsp'"
            >
              <span v-if="itb.catlog && itb.catlog[index1]" class="tabsA-div-titsp">{{ itb.catlog[index1] }}</span>
              <span v-html="item1"></span>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div ref="sanjiaoDOM" class="san-jiao-hull" v-show="tabList.length > 0">
      <div v-show="sanjiaoShow" class="san-jiao" ref="sanJiao" @click.stop="sanJiaoClick"></div>
      <div ref="sanjiaoDOMB" class="san-jiao-cen"></div>
    </div>
  </div>
</template>

<script>
import LawAddition from '@/components/fb-bigdata/law-addition.vue'
import { isFilledArray, typeOf, deepCopy } from '@/utils/assist.js'
const DEFAULT_HEIGHT = 95
export default {
  name: 'law-item',
  props: {
    item: Object,
    selected: Boolean,
    selectedIds: Array,
    itemindex: Number,
  },
  components: {
    LawAddition,
  },
  data() {
    return {
      data: null,
      isSelected: false,
      modulename: '',
      titleShearstate: '',
      // cardtabs: '结果命中',
      activeName: '结果命中',
      content: [],
      tabList: [],
      tabsData: {},
      sanjiaoShow: false,
      itemClickst: false,
      vantabsIndex: 0,
      selectTabs: [],
    }
  },
  computed: {
    // 属性字段是否有值
    hasAttr() {
      const item = this.item
      return item.court_name || item.case_id || item.pub_date || item.exc_date
    },
  },
  methods: {
    // 关联法条或历史沿革类型
    isAddition(name) {
      return name === '关联法条' || name === '历史沿革'
    },
    select() {
      this.$emit('select', this.item)
    },
    selectJudgeItem() {
      this.$emit('selectJudgeItem', this.item)
    },
    handleToLaw(payload) {
      this.$emit('toDetail', payload, payload.toCatalog)
    },
    toDetail(docid, caseAttr, catlog = '', event) {
      console.log('lawitem----', event.target.className)
      // console.log(docid, caseAttr, catlog)
      if (event.target.className.indexOf('van-tab__text') !== -1) {
        console.log('不跳转')
      } else {
        this.$emit('toDetail', this.item, catlog)
        this.itemClickst = true
      }
    },
    download(id) {
      this.$emit('download', id)
    },
    save(name, id, title) {
      this.$emit('save', name, id, title)
    },
    titbtndown() {
      console.log('titbtndoem')
      this.$bus.$emit('swipeable', false)
    },
    titbtnup() {
      console.log('titbtnup')
      this.$bus.$emit('swipeable', true)
    },
    titshearFN(e) {
      // this.titleShearstate = !this.titleShearstate
      // console.log('titleShearstate***', this.titleShearstate)
      // 发送数据
      this.$bus.$emit('titsearET', {
        data: this.tabsData,
        id: this.tabsData.id,
        check: this.titleShearstate,
      })
      // 处理数据
      // console.log('data****', data)
    },
    handleSelectLawItem(res) {
      this.$emit('handleSelectLawItem', res)
    },
    cleartitleShear() {
      this.titleShearstate = false
    },
    copyTxt(value) {
      // console.log('复制', value)
      let text = ''
      if (typeof value === 'string') {
        text = value
      } else {
        value.forEach((item) => {
          text += item
        })
      }
      text = text.replace(/<[^>]*>/g, '')
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      // this.$notify('内容已复制')
      this.$notify({
        message: '内容已复制',
        duration: 500,
        background: '#1989fa',
      })
      // console.log('复制完成')
      // args = false
    },
    getStyle(obj, styleName) {
      if (obj.currentStyle) {
        return obj.currentStyle[styleName]
      } else {
        return getComputedStyle(obj, false)[styleName]
      }
    },
    sanjiaoSFN(num = 0, index = 0) {
      // console.log('sanjiaosfn length')
      var that = this
      num += 1
      this.$nextTick(() => {
        if (that.$refs[`tabsA${index}`] && that.$refs[`tabsA${index}`][0]) {
          const hei = that.$refs[`tabsA${index}`][0].offsetHeight
          if (hei && hei > 94) {
            that.sanjiaoShow = true
          } else {
            that.sanjiaoShow = false
          }
        } else if (num < 10) {
          that.sanjiaoSFN(num)
        }
      })
    },
    activeBwidth() {
      setTimeout(() => {
        if (this.$refs.antabsA) {
          // console.log(this.$refs.antabsA.$children[0].$el.offsetWidth)
          // const width = this.$refs.antabsA.$children[0].$el.offsetWidth
          // this.tabsBwidth = 823 - width - 20
          // this.tabsshow = true
        }
      }, 1)
    },
    tabsDataInit() {
      this.tabsData = JSON.parse(JSON.stringify(this.item))
      // console.log('this.tabsdata', this.tabsData)
      if (!this.tabsData.content) {
        // console.log('content 无')
        this.tabsData.content = this.tabsData.hit_datas.content
        this.tabsData.content.forEach((item) => {
          item.value = [item.value]
        })
      }
      for (let i = 0; i < this.tabsData.content.length; i++) {
        if (this.tabsData.content[i].value.length === 0 || (this.tabsData.content[i].value.length === 1 && this.tabsData.content[i].value[0] === '')) {
          this.tabsData.content.splice(i, 1)
          i -= 1
        }
      }
      this.content = this.tabsData.content

      try {
        this.vantabsIndex = this.content.length - 1
      } catch {
        console.log('%c tabsdatainit length错误', 'color:#ccc', this.content)
      }
      // this.sanjiaoSFN()
      // console.log('tabsdatainit 完成', this.tabsData)
    },
    sanJiaoClick() {
      console.log('点击了三角2')
      const trannum = this.$refs.sanJiao.style.transform
      // console.log(trannum)
      if (trannum + '' === 'rotate(180deg) translateZ(0px)') {
        this.sanjiaoshow = false
        this.$refs.sanJiao.style.transform = 'rotate(0deg) translateZ(0)'
        this.tabList.forEach((item, index) => {
          if (this.$refs[`tabsA${index}`]) {
            this.$refs[`tabsA${index}`][0].classList.remove('sanjiaoj')
          }
        })
        // this.$refs.tabsA.forEach(item => {
        //   item.classList.remove('sanjiaoj')
        // })
      } else {
        this.sanjiaoshow = true
        this.$refs.sanJiao.style.transform = 'rotate(180deg) translateZ(0)'
        this.tabList.forEach((item, index) => {
          if (this.$refs[`tabsA${index}`]) {
            this.$refs[`tabsA${index}`][0].classList.add('sanjiaoj')
          }
        })
        // this.$refs.tabsA.forEach(item => {
        //   item.classList.add('sanjiaoj')
        // })
      }
    },
    // 解析tab名称 区分num是否有值
    parseTabName(item) {
      const { name, num } = item
      return num ? `${name} (${num})` : name
    },
    // 对数据进行预处理
    prepareItemData(item) {
      const preferActive = '结果命中'
      const { content = [], word_frequency: wordfrequency = [] } = deepCopy(item)
      // 添加 f 作为词频标记
      const wf = wordfrequency.map((w) => ({ ...w, f: true })).filter((w) => isFilledArray(w.value) && w.value[0])
      const list = [...content, ...wf]

      // 如果value是字符串则转为数组
      list.forEach((item) => {
        const { value } = item
        if (typeOf(value) === 'string') {
          item.value = value ? [value] : null
        } else if (isFilledArray(value)) {
          item.value = value.filter((t) => t)
        } else {
          item.value = null
        }
      })

      this.tabList = list.filter((item) => isFilledArray(item.value))

      if (this.tabList.length > 0) {
        // 判断有没有 **结果命中**，如果有就默认选中结果命中，没有就显示其他的
        const jgmz = this.tabList.find((item) => item.name === preferActive)
        if (jgmz) {
          this.activeName = preferActive
        } else {
          this.activeName = wf.length > 0 ? wf[0].name : this.tabList[0].name
        }
      }
      this.$nextTick(() => {
        this.checkClamp()
      })
    },
    checkClamp() {
      setTimeout(() => {
        const lawcontentDom = this.$refs.lawcontentDom
        if (!lawcontentDom) return
        const tabId = lawcontentDom.querySelector(`#tab-${this.activeName}`)
        if (tabId && tabId.scrollHeight > DEFAULT_HEIGHT) {
          this.sanjiaoShow = true
        } else {
          this.sanjiaoShow = false
        }
      }, 60)
    },
  },
  created() {
    this.tabsDataInit()
    // this.isSelected = this.item.selected
    const index = this.selectedIds.findIndex((v) => v === this.item.law_id)
    if (index !== -1) {
      this.isSelected = true
    } else {
      this.isSelected = false
    }
  },
  watch: {
    item: {
      deep: true,
      handler(nv) {
        this.tabsData = JSON.parse(JSON.stringify(this.item))
        // console.log('item变化::::::::', nv)
        this.prepareItemData(nv)
        // this.tabsDataInit()
        // this.sanjiaoSFN()
      },
    },
    selectedIds(val) {
      const index = val.findIndex((v) => v === this.item.law_id)
      if (index !== -1) {
        this.isSelected = true
      } else {
        this.isSelected = false
      }
      // console.log(this.isSelected, 'selectLawIds----watch')
    },
    vantabsIndex(nv) {
      // console.log('计算三角')
      // this.sanjiaoSFN(0, nv)
    },
    titleShearstate(nv) {
      try {
        // 只要执行就会报错 即使是空方法
        this.titshearFN()
      } catch {
        console.log('this.titshearfn 错误')
      }
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.sanjiaoSFN()
    //   }, 500)
    // })
  },
}
</script>

<style lang="stylus" scoped>
.judgement-item
  position relative
  padding-bottom 10px
  & .extra-info
    padding 0 10px
    & .attr
      margin-right 3px
      &:after
        content '/'
        margin 0 4px
      &:last-child
        margin-right 0
        &:after
          content none
.title-share-report
  float right
  position relative
.title-share-report
  ::v-deep .van-checkbox__label
    position absolute
    left 0
    top 0
    margin-left 0
    height 100%
    width 100%
    text-align center
</style>

<style scoped>
.tipsty {
  background: #ff9c39;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
}
/* mark会改变解构 */
::v-deep.tipsty mark {
  color: #fff !important ;
}

.tipsty :nth-child(2) {
  display: none;
}
.tabsA-div {
  max-height: 95px;
  /* min-height: 100px; */
  overflow: hidden;
  margin: 0 16px;
  padding: 5px 5px 0 5px;
  border-left: 1px solid #1890ff;
  border-right: 1px solid #1890ff;
  border-top: 1px solid #1890ff;
}

::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__content {
  min-height: auto;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav > .van-tab {
  /* flex-basis: 20% !important; */
  flex: none;
  border: auto;
  /* border-bottom: 1px solid #1890ff; */
  color: #999;
  border-right: none;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav > .van-tab--active {
  color: #1890ff;
  border: 1px solid #1890ff;
  border-bottom: 1px solid #fff;
  border-radius: 5px 5px 0 0;
  background: #fff;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav {
  /* border: 0px solid #1890ff; */
  /* border-bottom: 1px solid #1890ff; */
  border: none;
  flex: 0;
  /* display: -webkit-box; */
  /* border-bottom: 1px solid #1890ff; */
}
::v-deep.judgement-item > .vantabshull > .van-tabs.van-tabs--card > .van-tabs__wrap > .van-tabs__nav.van-tabs__nav--card > .van-tab.van-tab--active {
  flex: none;
  /* -webkit-flex: initial; */
  top: 2px;
}

.san-jiao-hull {
  height: 5px;
  border: 1px solid #1890ff;
  border-top: none;
  /* width: 100%; */
  padding: 5px 5px 0 5px;
  margin: 0 16px;
}
.san-jiao {
  width: 0px;
  background: rgba(190, 215, 239, 0);
  border-bottom: solid 16px #bcd8f2;
  border-left: solid 16px rgba(190, 215, 239, 0);
  /* float: right; */
  margin-right: 10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.sanjiaoj {
  max-height: 190px;
  overflow-y: auto;
}
.title-shear {
  /* position: absolute;
  right: 10px; */
  float: right;
  position: relative;
}
.tabsAspan {
  display: block;
}
.icon-copy {
  width: 15px;
  height: 15px;
  position: absolute;
  background: #fff;
}
.icon-copy-a:last-of-type {
  fill: #333;
}
.icon-copy-b:last-of-type {
  fill: #999;
}
.icon-copy-c:last-of-type {
  fill: #000;
}
.san-jiao-cen {
  width: 100%;
}
.tabsA-div-titsp {
  font-weight: bold;
  padding-right: 20px;
}
.vancheck::v-deep > .van-checkbox__icon > .van-icon {
  width: 20px;
  height: 20px;
  /* display: none; */
}
.vancheck::v-deep {
  overflow: visible;
}
.van-check-num {
  position: absolute;
  top: 0;
  right: 8px;
  text-align: center;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
::v-deep.vantabshull > .van-tabs > .van-tabs__wrap span {
  font-size: smaller;
}
</style>
