var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "cloneItem",
      class: ["clone-item", _vm.data.name === "标题" ? "selp2" : ""],
      on: {
        touchstart: _vm.onTouchstart,
        touchmove: _vm.onTouchmove,
        touchend: _vm.onTouchend,
        touchcancel: _vm.onTouchcancel,
      },
    },
    [_c("div", { staticClass: "label-text" }, [_vm._v(_vm._s(_vm.data.name))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }