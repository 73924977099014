var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      { staticClass: "suggestion-list-wrap" },
      _vm._l(_vm.suggestions, function (item) {
        return _c(
          "li",
          {
            key: item.key,
            class: { "group-item": true },
            attrs: { role: "option" },
          },
          [
            _c("ul", { staticClass: "el-select-group__wrap" }, [
              item.values.length
                ? _c("li", { staticClass: "el-select-group__title" }, [
                    _vm._v(" " + _vm._s(item.category) + " "),
                  ])
                : _vm._e(),
              _c("li", [
                _c(
                  "ul",
                  { staticClass: "el-select-group" },
                  _vm._l(item.values, function (s, i) {
                    return _c("li", {
                      key: i,
                      staticClass: "group-item-li",
                      domProps: { innerHTML: _vm._s(s.label) },
                      on: {
                        touchstart: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectSuggestionItem(s, item)
                        },
                      },
                    })
                  }),
                  0
                ),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }