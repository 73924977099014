var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "searchList",
      staticClass: "search-list",
      style: `${_vm.clientHeight}`,
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.reportCount,
              expression: "reportCount",
            },
          ],
          staticClass: "judVtabsCaseC",
          on: { click: _vm.handleCollect },
        },
        [
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAdd,
                    expression: "showAdd",
                  },
                ],
                staticClass: "badge-box",
              },
              [_c("span", [_vm._v("+1")])]
            ),
          ]),
          _c("transition", { attrs: { name: "slide-fade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showReduce,
                    expression: "showReduce",
                  },
                ],
                staticClass: "badge-box",
              },
              [_c("span", [_vm._v("-1")])]
            ),
          ]),
          _c("span", { staticClass: "collect" }, [_vm._v("集")]),
          _c("span", { staticClass: "count" }, [
            _vm._v(_vm._s(_vm.reportCount)),
          ]),
        ],
        1
      ),
      false ? _c("fb-member", { attrs: { btnLeft: _vm.btnLeft } }) : _vm._e(),
      _c("fb-nonmember", {
        attrs: { showLimit: _vm.showLimit },
        on: {
          "update:showLimit": function ($event) {
            _vm.showLimit = $event
          },
          "update:show-limit": function ($event) {
            _vm.showLimit = $event
          },
        },
      }),
      _c("fb-loading", { attrs: { loading: _vm.loadingFull } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _vm.contentType === "judgementSearch"
            ? _c(
                "div",
                [
                  _vm.listData && _vm.listData.length
                    ? [
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.placest,
                              expression: "placest",
                            },
                          ],
                          staticClass: "Placeholder",
                          staticStyle: { height: "145px" },
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.dragtagsData.length > 0,
                                expression: "dragtagsData.length > 0",
                              },
                            ],
                            class:
                              _vm.placest === true
                                ? "judVtabsCaseA"
                                : "judVtabsCase",
                          },
                          [
                            _c(
                              "van-tabs",
                              {
                                ref: "judgeTabs",
                                staticClass: "search-tabs",
                                staticStyle: { width: "90%" },
                                attrs: {
                                  sticky: "",
                                  "scroll-lock": "",
                                  "line-height": "0px",
                                  "title-active-color": "#409EFF",
                                },
                                model: {
                                  value: _vm.judgementVtabsIndex,
                                  callback: function ($$v) {
                                    _vm.judgementVtabsIndex = $$v
                                  },
                                  expression: "judgementVtabsIndex",
                                },
                              },
                              [
                                _c("van-tab", { attrs: { title: "综合排序" } }),
                                _c("van-tab", { attrs: { title: "常规排序" } }),
                                _c("van-tab", { attrs: { title: "裁判时间" } }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                class: [
                                  _vm.placest === true
                                    ? "judgementIconA"
                                    : "judgementIcon",
                                ],
                                on: { click: _vm.selectClick },
                              },
                              [
                                _c("van-icon", {
                                  staticClass: "judgementVicon",
                                  attrs: { name: "search" },
                                }),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "clear" }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            class:
                              _vm.placestB === true
                                ? "judVtabsCaseB"
                                : "judVtabsCase",
                          },
                          [
                            _c(
                              "van-button",
                              {
                                attrs: { plain: "", type: "info" },
                                on: { click: _vm.handleCancle },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.selectJudgeIds.length) + " 取消"
                                ),
                              ]
                            ),
                            _c(
                              "van-button",
                              {
                                attrs: { plain: "", type: "info" },
                                on: { click: _vm.saveCondition },
                              },
                              [_vm._v("保存查询条件")]
                            ),
                            _c(
                              "van-button",
                              {
                                attrs: { plain: "", type: "info" },
                                on: { click: _vm.download2 },
                              },
                              [_vm._v("下载")]
                            ),
                            _c(
                              "van-button",
                              {
                                attrs: { plain: "", type: "info" },
                                on: { click: _vm.actionReport },
                              },
                              [_vm._v(_vm._s(_vm.reportCount) + " 报告")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.reFresh,
                                expression: "reFresh",
                              },
                            ],
                            ref: "titcasieDom",
                            staticClass: "titcasie",
                            on: {
                              touchstart: _vm.titbtndown,
                              touchend: _vm.titbtnup,
                            },
                          },
                          _vm._l(_vm.casieCopy, function (itema, indexa) {
                            return _c(
                              "van-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: itema.label !== "普通案例",
                                    expression: "itema.label !== '普通案例'",
                                  },
                                ],
                                key: indexa + "td",
                                attrs: { type: "default" },
                                on: {
                                  click: function ($event) {
                                    return _vm.judClickFn(itema)
                                  },
                                },
                              },
                              [
                                _vm.reFresh
                                  ? _c(
                                      "div",
                                      { staticClass: "titcasie-label" },
                                      [_vm._v(" " + _vm._s(itema.label) + " ")]
                                    )
                                  : _vm._e(),
                                _vm.reFresh
                                  ? _c("div", [_vm._v(_vm._s(itema.num))])
                                  : _vm._e(),
                              ]
                            )
                          }),
                          1
                        ),
                        _vm._l(_vm.listData, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item van-hairline--top-bottom",
                            },
                            [
                              _c("judgement-item", {
                                ref: "juditem",
                                refInFor: true,
                                attrs: {
                                  itemindex: index,
                                  item: item,
                                  selectedIds: _vm.selectJudgeIds,
                                },
                                on: {
                                  selectJudgeItem: _vm.handleSelectJudgeItem,
                                  toDetail: _vm.toDetail,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ]
                    : _vm._e(),
                  !_vm.listData.length && _vm.contentType === "judgementSearch"
                    ? _c("recommendTag", {
                        attrs: {
                          keywords: _vm.keywords,
                          contentType: _vm.contentType,
                        },
                        on: {
                          selectClick: _vm.selectClick,
                          goJudgementDetil: _vm.toDetail,
                          goLawDetil: _vm.toLawDetail,
                        },
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.contentType === "lawSearch"
            ? _c(
                "div",
                [
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.placest,
                        expression: "placest",
                      },
                    ],
                    staticClass: "Placeholder",
                    staticStyle: { height: "145px" },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dragtagsData.length > 0,
                          expression: "dragtagsData.length > 0",
                        },
                      ],
                      class:
                        _vm.placest === true ? "judVtabsCaseA" : "judVtabsCase",
                    },
                    [
                      _c(
                        "van-tabs",
                        {
                          ref: "judgeTabs",
                          staticClass: "search-tabsB",
                          staticStyle: { width: "90%" },
                          attrs: {
                            sticky: "",
                            "swipe-threshold": "7",
                            "line-height": "0px",
                            "title-active-color": "#409EFF",
                          },
                          model: {
                            value: _vm.judgementVtabsIndex,
                            callback: function ($$v) {
                              _vm.judgementVtabsIndex = $$v
                            },
                            expression: "judgementVtabsIndex",
                          },
                        },
                        _vm._l(_vm.lawTabsTitle, function (index) {
                          return _c("van-tab", {
                            key: index,
                            attrs: { title: index },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.contentType !== "ledgeSearch" &&
                                _vm.keywordsArr[_vm.contentType].length,
                              expression:
                                "contentType !== 'ledgeSearch' && keywordsArr[contentType].length",
                            },
                          ],
                          class: [
                            _vm.placest === true
                              ? "judgementIconA"
                              : "judgementIcon",
                          ],
                          on: { click: _vm.selectClick },
                        },
                        [
                          _c("van-icon", {
                            staticClass: "judgementVicon",
                            attrs: { name: "search" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class:
                        _vm.placestB === true
                          ? "judVtabsCaseB"
                          : "judVtabsCase",
                    },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.handleCancle },
                        },
                        [_vm._v(_vm._s(_vm.selectLawIds.length) + " 取消")]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.saveCondition },
                        },
                        [_vm._v("保存查询条件")]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.download2 },
                        },
                        [_vm._v("下载")]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.actionReport },
                        },
                        [_vm._v(_vm._s(_vm.reportCount) + " 报告")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.reFresh,
                          expression: "reFresh",
                        },
                      ],
                      ref: "titcasieDom",
                      staticClass: "titcasie",
                      on: {
                        touchstart: _vm.titbtndown,
                        touchend: _vm.titbtnup,
                      },
                    },
                    _vm._l(_vm.lawCasieCopy, function (itema, indexa) {
                      return _c(
                        "van-button",
                        {
                          key: indexa + "td",
                          attrs: { type: "default" },
                          on: {
                            click: function ($event) {
                              return _vm.judClickFn(itema)
                            },
                          },
                        },
                        [
                          _vm.reFresh
                            ? _c("div", { staticClass: "titcasie-label" }, [
                                _vm._v(_vm._s(itema.label)),
                              ])
                            : _vm._e(),
                          _vm.reFresh
                            ? _c("div", [_vm._v(_vm._s(itema.num))])
                            : _vm._e(),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._l(_vm.listData, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item van-hairline--top-bottom",
                      },
                      [
                        _c("law-item", {
                          ref: "lawitem",
                          refInFor: true,
                          attrs: {
                            itemindex: index,
                            item: item,
                            selectedIds: _vm.selectLawIds,
                          },
                          on: {
                            toDetail: _vm.toLawDetail,
                            handleSelectLawItem: _vm.handleSelectLawItem,
                          },
                        }),
                        item.hit_datas && item.hit_datas.content
                          ? _c(
                              "div",
                              [
                                _vm.listData && _vm.listData.length
                                  ? _vm._l(
                                      item.hit_datas.content,
                                      function (iter, num) {
                                        return _c("div", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: iter.value,
                                              expression: "iter.value",
                                            },
                                          ],
                                          key: num,
                                          staticClass: "zkcontent",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.toLawDetailWithCatalog(
                                                item,
                                                num
                                              )
                                            },
                                          },
                                        })
                                      }
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm.contentType === "lawSearch"
                    ? _c("recommendTag", {
                        attrs: {
                          keywords: _vm.keywords,
                          contentType: _vm.contentType,
                        },
                        on: {
                          selectClick: _vm.selectClick,
                          goLawDetil: _vm.toLawDetail,
                        },
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.contentType === "ledgeSearch"
            ? _c(
                "div",
                {
                  staticStyle: { overflow: "hidden", "padding-bottom": "10px" },
                },
                [
                  _c(
                    "div",
                    {
                      class:
                        _vm.placestB === true
                          ? "judVtabsCaseB"
                          : "judVtabsCase",
                    },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.handleCancle },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.selectKnowledgeIds.length) + " 取消"
                          ),
                        ]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.saveCondition },
                        },
                        [_vm._v("保存查询条件")]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.download2 },
                        },
                        [_vm._v("下载")]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.actionReport },
                        },
                        [_vm._v(_vm._s(_vm.reportCount) + " 报告")]
                      ),
                    ],
                    1
                  ),
                  _vm.listData && _vm.listData.length
                    ? [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.reFresh,
                                expression: "reFresh",
                              },
                            ],
                            ref: "titcasieDom",
                            staticClass: "titcasie",
                            on: {
                              touchstart: _vm.titbtndown,
                              touchend: _vm.titbtnup,
                            },
                          },
                          _vm._l(_vm.ledgeCasieCopy, function (itema, indexa) {
                            return _c(
                              "van-button",
                              {
                                key: indexa + "td",
                                attrs: { type: "default" },
                                on: {
                                  click: function ($event) {
                                    return _vm.judClickFn(itema)
                                  },
                                },
                              },
                              [
                                _vm.reFresh
                                  ? _c(
                                      "div",
                                      { staticClass: "titcasie-label" },
                                      [_vm._v(" " + _vm._s(itema.label) + " ")]
                                    )
                                  : _vm._e(),
                                _vm.reFresh
                                  ? _c("div", [_vm._v(_vm._s(itema.num))])
                                  : _vm._e(),
                              ]
                            )
                          }),
                          1
                        ),
                        _vm._l(_vm.listData, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "item van-hairline--top-bottom",
                            },
                            [
                              _c("div", { staticClass: "title" }, [
                                _c("div", {
                                  staticClass: "text",
                                  domProps: { innerHTML: _vm._s(item.title) },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toDetail(
                                        item.url,
                                        item.source,
                                        "zhishi"
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "title-share-report",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.handleSelectKnowledgeItem(
                                          item
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "van-checkbox",
                                      {
                                        staticClass: "vancheck",
                                        attrs: { shape: "square" },
                                        model: {
                                          value: item.selected,
                                          callback: function ($$v) {
                                            _vm.$set(item, "selected", $$v)
                                          },
                                          expression: "item.selected",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !item.selected,
                                                expression: "!item.selected",
                                              },
                                            ],
                                            staticClass: "check-num",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(index + 1) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._l(item.content, function (iter, num) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: iter.value,
                                        expression: "iter.value",
                                      },
                                    ],
                                    key: num,
                                    staticClass: "zkcontent",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDetail(
                                          item.url,
                                          item.source,
                                          "zhishi"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(iter.value),
                                      },
                                    }),
                                  ]
                                )
                              }),
                              _c("div", { staticClass: "showrow" }, [
                                _c("div", { staticClass: "text" }, [
                                  _vm._v(
                                    _vm._s(item.origin) +
                                      _vm._s(" - " + item.pub_date)
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          )
                        }),
                      ]
                    : [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadingFull,
                                expression: "!loadingFull",
                              },
                            ],
                            staticClass: "empty-list",
                          },
                          [
                            _vm._v("未检索到相关内容,请重新"),
                            _c(
                              "span",
                              {
                                staticStyle: { color: "#409eff" },
                                on: { click: _vm.selectClick },
                              },
                              [_vm._v("调整关键词")]
                            ),
                            _vm._v("。"),
                          ]
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
          _vm.contentType === "criterionSearch"
            ? _c(
                "div",
                {
                  staticStyle: { overflow: "hidden", "padding-bottom": "10px" },
                },
                [
                  _c(
                    "div",
                    {
                      class:
                        _vm.placestB === true
                          ? "judVtabsCaseB"
                          : "judVtabsCase",
                    },
                    [
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.handleCancle },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.selectCriterionIds.length) + " 取消"
                          ),
                        ]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.saveCondition },
                        },
                        [_vm._v("保存查询条件")]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.download2 },
                        },
                        [_vm._v("下载")]
                      ),
                      _c(
                        "van-button",
                        {
                          attrs: { plain: "", type: "info" },
                          on: { click: _vm.actionReport },
                        },
                        [_vm._v(_vm._s(_vm.reportCount) + " 报告")]
                      ),
                    ],
                    1
                  ),
                  _vm.listData && _vm.listData.length
                    ? _vm._l(_vm.listData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item van-hairline--top-bottom",
                            on: {
                              click: function ($event) {
                                return _vm.criterionSearchDetail(item.url)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "title" }, [
                              _c("div", { staticClass: "text" }, [
                                item.stdStatus !== "现行"
                                  ? _c("span", { staticClass: "CanNot" }, [
                                      _vm._v(
                                        " " + _vm._s(item.stdStatus) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(item.id + " ") +
                                    _vm._s(item.stdTitle) +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "title-share-report",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.handleSelectCriterionItem(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "van-checkbox",
                                    {
                                      staticClass: "vancheck",
                                      attrs: { shape: "square" },
                                      model: {
                                        value: item.selected,
                                        callback: function ($$v) {
                                          _vm.$set(item, "selected", $$v)
                                        },
                                        expression: "item.selected",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !item.selected,
                                              expression: "!item.selected",
                                            },
                                          ],
                                          staticClass: "check-num",
                                        },
                                        [_vm._v(" " + _vm._s(index + 1) + " ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "showrow" }, [
                              _c("div", { staticClass: "text" }, [
                                _vm._v(
                                  _vm._s(item.department + " ") +
                                    _vm._s(item.publishDate + " ") +
                                    _vm._s(item.execDate)
                                ),
                              ]),
                            ]),
                          ]
                        )
                      })
                    : [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.loadingFull,
                                expression: "!loadingFull",
                              },
                            ],
                            staticClass: "empty-list",
                          },
                          [
                            _vm._v("未检索到相关内容,请重新"),
                            _c(
                              "span",
                              {
                                staticStyle: { color: "#409eff" },
                                on: { click: _vm.selectClick },
                              },
                              [_vm._v("调整关键词")]
                            ),
                            _vm._v("。"),
                          ]
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "loading-hint" },
        [_c("fb-loading", { attrs: { loading: _vm.loadingMore } })],
        1
      ),
      _vm.listData.length >= 10 && !_vm.loadingMore && _vm.loading && _vm.isNext
        ? _c("div", { staticClass: "more" }, [
            _c("span", { on: { click: _vm.next } }, [_vm._v("加载更多")]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }