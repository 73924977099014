var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "search",
      staticClass: "content_search",
      attrs: { id: "searchpage" },
    },
    [
      _vm.isZH && !_vm.isCloseNotify
        ? _c("NoticeBar", {
            attrs: {
              text: "智拾法律数据库（律呗版）",
              "left-icon": "volume-o",
              mode: "closeable",
            },
            on: { close: _vm.closeNoticeBar },
          })
        : _vm._e(),
      _c("iosVersionTip"),
      _c(
        "van-popup",
        {
          staticClass: "mainsearch-hull",
          style: { height: "100%", width: "100%" },
          attrs: { position: "right", "lazy-render": false },
          model: {
            value: _vm.mainSearchVisible,
            callback: function ($$v) {
              _vm.mainSearchVisible = $$v
            },
            expression: "mainSearchVisible",
          },
        },
        [
          _c("main-search", {
            ref: "mainSearch",
            attrs: {
              keywordArr: _vm.keywordsArr[_vm.active],
              queryCache: _vm.queryPayload,
            },
            on: {
              "update:synonym": _vm.updateSynonym,
              toLawDetail: _vm.openLawDetail,
              cancel: _vm.onSearchCancel,
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "100%" },
          attrs: { position: "right", "lazy-render": false },
          model: {
            value: _vm.filterVisible,
            callback: function ($$v) {
              _vm.filterVisible = $$v
            },
            expression: "filterVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "filter-box" },
            _vm._l(_vm.filterList, function (item) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "filter",
                  style: item.value === _vm.sort ? "color:#1989FA" : "",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.setSort(item.value)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.text) + " ")]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "van-tabs",
        {
          staticClass: "search-nav-tab",
          attrs: {
            "title-active-color": _vm.color,
            swipeable: _vm.swipeableShow,
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        _vm._l(_vm.searchClassifyShow, function (item) {
          return _c(
            "van-tab",
            { key: item.key, attrs: { name: item.key, title: item.name } },
            [
              _c("div", { staticClass: "mock-input-wrap" }, [
                _c(
                  "div",
                  {
                    staticClass: "mock-input",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.onMockInputFocus.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.keywordsArr[_vm.active].length,
                            expression: "keywordsArr[active].length",
                          },
                        ],
                        staticClass: "icon1",
                        on: {
                          mousedown: function ($event) {
                            $event.stopPropagation()
                          },
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.ShowFilter.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "van-icon van-icon-filter-o" })]
                    ),
                    _c(
                      "div",
                      { staticClass: "mock-input__key-word" },
                      [
                        _vm._l(_vm.calcFilterTag(_vm.active), function (item) {
                          return _c(
                            "span",
                            { key: item.key, staticClass: "keywords_fixed" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.label ? item.label + ":" : "") +
                                  _vm._s(item.value.join("、")) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        _vm._l(_vm.keywordsArr[_vm.active], function (item) {
                          return _c("span", {
                            key: item,
                            staticClass: "keyword-span hl-ignore",
                            domProps: { innerHTML: _vm._s(item) },
                          })
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "icon",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openFilter.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm.active === "ledgeSearch"
                          ? _c("van-icon", { attrs: { name: "search" } })
                          : _c("i", { staticClass: "iconfont icon-paixu2" }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "tab-content-wrap",
                  attrs: { id: "list-page-highlight-area" },
                },
                [
                  _vm.isNetError
                    ? _c("div", { staticClass: "net-error" }, [
                        _c("span", [_vm._v("检测到网络波动，")]),
                        _c(
                          "span",
                          {
                            staticClass: "text-btn",
                            on: { click: _vm.reload },
                          },
                          [_vm._v("点击刷新")]
                        ),
                      ])
                    : item.key === _vm.active
                    ? _c("search-list", {
                        ref: "searchList",
                        refInFor: true,
                        attrs: {
                          loading: _vm.loading,
                          listData: _vm.currentList,
                          loadingFull: _vm.loadingFull,
                          loadingMore: _vm.loadingMore,
                          conditions: _vm.conditions,
                          contentType: _vm.active,
                          sort: _vm.sort,
                          keywordsArr: _vm.keywordsArr,
                          isNext: _vm.isNext,
                        },
                        on: {
                          setSort: _vm.setSort,
                          onMockInputFocus: _vm.onMockInputFocus,
                          next: _vm.next,
                          collect: _vm.handleCollect,
                          toDetail: _vm.toDetail,
                          toLawDetail: _vm.toLawDetail,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _vm.detailshow.flag
        ? _c(
            "div",
            { staticClass: "nav", attrs: { id: "nav" } },
            [
              _c("percentDetail", {
                attrs: {
                  id: _vm.detailshow.case_id,
                  words: _vm.detailshow.words,
                  titleName: _vm.detailshow.name,
                  toCatloag: _vm.detailshow.toCatloag,
                },
                on: {
                  changeflag: _vm.changeshow,
                  highlight: _vm.handleHighlight,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showReport
        ? _c(
            "div",
            { staticClass: "nav collect-report" },
            [
              _c("searchReport", {
                attrs: { from: "search" },
                on: { close: _vm.closeReport },
              }),
            ],
            1
          )
        : _vm._e(),
      Object.keys(_vm.TreeSelect.data).length > 0
        ? _c("treeSelect", {
            attrs: { keywords: _vm.save_keyword },
            on: { search: _vm.pushFilterCondition, delData: _vm.delData },
            model: {
              value: _vm.TreeSelect,
              callback: function ($$v) {
                _vm.TreeSelect = $$v
              },
              expression: "TreeSelect",
            },
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          style: { height: "100%" },
          attrs: { "get-container": "#outter", position: "right" },
          model: {
            value: _vm.lawDetailPopupVisible,
            callback: function ($$v) {
              _vm.lawDetailPopupVisible = $$v
            },
            expression: "lawDetailPopupVisible",
          },
        },
        [
          _c("law-detail", {
            attrs: { entry: _vm.lawDetailProp, miniuserInfo: _vm.miniuserInfo },
            on: { back: _vm.closeLawDetailPopup },
          }),
        ],
        1
      ),
      _vm.isPublicRouteDetail
        ? _c(
            "div",
            { staticClass: "app-download-tip" },
            [_c("download-app")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }