<template>
  <div class="judgement-item" @click="toDetail(item.doc_id, item.case_attr, '', $event)">
    <div class="title">
      <div class="text" :style="{ color: itemClickst ? '#ccc' : '#333' }">
        <!-- <svg
          v-show="titleShearstate"
          t="1596533984189"
          class="icon-copy icon-copy-a"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2157"
          width="16"
          height="16"
          @click.stop="copyTxt(item.case_name)"
        >
          <path
            d="M702.144 892.224c0 35.008-28.352 63.36-63.36 63.36L131.776 955.584c-34.944 0-63.36-28.352-63.36-63.36L68.416 385.28c0-35.008 28.416-63.36 63.36-63.36l63.36 0 0-63.36-63.36 0c-70.016 0-126.72 56.768-126.72 126.72l0 507.008c0 70.08 56.704 126.72 126.72 126.72L638.72 1019.008c70.08 0 126.72-56.704 126.72-126.72l0-63.36-63.36 0L702.08 892.224zM892.224 4.992 385.28 4.992c-70.016 0-126.72 56.768-126.72 126.72L258.56 638.72c0 69.952 56.704 126.72 126.72 126.72l507.008 0c70.08 0 126.72-56.832 126.72-126.72L1019.008 131.776C1019.008 61.76 962.304 4.992 892.224 4.992zM955.648 638.72c0 35.072-28.352 63.36-63.36 63.36L385.28 702.08c-34.944 0-63.36-28.288-63.36-63.36L321.92 131.776c0-35.008 28.416-63.36 63.36-63.36l507.008 0c35.008 0 63.36 28.352 63.36 63.36L955.648 638.72z"
            p-id="2158"
          ></path>
        </svg> -->
        <span v-show="item.case_attr !== '普通案例' && item.case_attr !== null" class="tipsty">
          {{ item.case_attr }}
        </span>
        <span v-html="item.case_name"></span>
      </div>
      <div class="title-shear" @click.stop="selectJudgeItem">
        <van-checkbox class="vancheck" v-model="titleShearstate" shape="square">
          <div class="van-check-num" v-show="!titleShearstate">
            {{ itemindex + 1 }}
          </div>
        </van-checkbox>
      </div>
    </div>
    <!-- <div
      class="zkcontent van-multi-ellipsis--l3"
      v-for="(iter, num) in item.content"
      v-show="iter.value"
      @click="toDetail(item.doc_id, item.case_attr, iter.value)"
      :key="num"
    >
      <span v-html="iter.value"></span>
    </div> -->
    <div class="extra-info" :style="{ color: itemClickst ? '#999' : '#333' }">
      <span v-if="item.court_name" :style="{ color: itemClickst ? '#ccc' : '#999' }">{{ item.court_name }}</span>
      <span v-if="item.case_id" :style="{ color: itemClickst ? '#ccc' : '#999' }">{{ item.case_id }}</span>
      <span v-if="item.pub_date" :style="{ color: itemClickst ? '#ccc' : '#999' }">{{ item.pub_date }}</span>
    </div>
    <div @touchstart="titbtndown" @touchend="titbtnup" @click.stop="" class="vantabshull" ref="zkcontentDom" v-if="tabList.length > 0">
      <van-tabs type="card" v-model="activeName">
        <van-tab v-for="(itb, index) in tabList" :key="index + 'card'" v-show="tabList.length > 0" :title="itb.name" :name="itb.name">
          <template #title v-if="itb.f">
            <van-tag round type="primary">{{ parseTabName(itb) }}</van-tag>
          </template>
          <template #title v-else>
            {{ itb.name }}
          </template>
          <div :id="`tab-${itb.name}`" class="tabsA-div" ref="tabsA" v-if="itb.name !== '引用法条'">
            <span class="full-law-text" :class="itb.name === '引用法条' ? 'tabsAspan' : ''" v-for="(item1, index1) in itb.value" :key="index1 + 'cardsp'" @click="toJudgementDetail(itb)">
              <template v-if="typeof item1 === 'object'">
                <p>{{ item1.title }}</p>
              </template>
              <span v-else v-html="item1"></span>
            </span>
          </div>
          <div :id="`tab-${itb.name}`" class="tabsA-div" ref="tabsA" v-if="itb.name === '引用法条'">
            <span class="full-law-text" :class="itb.name === '引用法条' ? 'tabsAspan' : ''" v-for="(item1, index1) in itb.value" :key="index1 + 'cardsp'" @click="toJudgementDetail(itb)">
              {{ item1.full_law }}
            </span>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div ref="sanjiaoDOM" class="san-jiao-hull" v-show="tabList.length > 0">
      <div v-show="sanjiaoShow" class="san-jiao" ref="sanJiao" @click.stop="sanJiaoClick"></div>
      <div ref="sanjiaoDOMB" class="san-jiao-cen"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isFilledArray, typeOf, deepCopy } from '@/utils/assist.js'
const DEFAULT_HEIGHT = 95
export default {
  name: 'judgement-item',
  props: {
    item: Object,
    selectedIds: Array,
    // selected: Boolean,
    itemindex: Number,
  },
  computed: {
    ...mapState('searchReport', ['clearCheck', 'judgeReports']),
    checkedIds() {
      return this.judgeReports.length ? this.judgeReports.map((item) => item.doc_id) : []
    },
  },
  data() {
    return {
      data: null,
      modulename: '',
      titleShearstate: '',
      // cardtabs: '结果命中',
      activeName: '结果命中',
      content: [],
      tabsData: {},
      sanjiaoShow: false,
      itemClickst: false,
      // vantabsIndex: 0,
      // vantabsactive: 0,
      selectTabs: [],
    }
  },
  methods: {
    toJudgementDetail(itb) {
      if (!itb.f) {
        this.$emit('toDetail', {
          docid: this.item.doc_id,
          caseAttr: this.item.case_attr,
          toCatloag: itb.name,
        })
      }
    },
    select() {
      this.$emit('select', this.item)
    },
    selectJudgeItem() {
      this.$emit('selectJudgeItem', this.item)
    },
    toDetail(docid, caseAttr, catlog = '', event) {
      console.log(event.target.className)
      if (event.target.className.indexOf('van-tab__text') !== -1) {
      } else {
        this.$emit('toDetail', { docid, caseAttr, catlog })
        this.itemClickst = true
      }
    },
    download(id) {
      this.$emit('download', id)
    },
    save(name, id, title) {
      this.$emit('save', name, id, title)
    },
    titbtndown() {
      // console.log('titbtndoem')
      this.$bus.$emit('swipeable', false)
    },
    titbtnup() {
      // console.log('titbtnup')
      this.$bus.$emit('swipeable', true)
    },
    titshearFN(e) {
      // this.titleShearstate = !this.titleShearstate
      // console.log('titleShearstate***', this.titleShearstate)
      // 发送数据
      this.$bus.$emit('titsearET', {
        data: this.tabsData,
        id: this.tabsData.id,
        check: this.titleShearstate,
      })
      // 处理数据
      // console.log('data****', data)
    },
    cleartitleShear() {
      this.titleShearstate = false
    },
    copyTxt(value) {
      // console.log('复制', value)
      let text = ''
      if (typeof value === 'string') {
        text = value
      } else {
        value.forEach((item) => {
          text += item
        })
      }
      text = text.replace(/<[^>]*>/g, '')
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      // this.$notify('内容已复制')
      this.$notify({
        message: '内容已复制',
        duration: 500,
        background: '#1989fa',
      })
      // console.log('复制完成')
      // args = false
    },
    getStyle(obj, styleName) {
      if (obj.currentStyle) {
        return obj.currentStyle[styleName]
      } else {
        return getComputedStyle(obj, false)[styleName]
      }
    },
    sanjiaoSFN() {
      // num += 1
      // setTimeout(() => {
      //   console.log(this.$refs.tabsA, this.vantabsIndex)
      //   if (this.$refs.tabsA && this.$refs.tabsA[0]) {
      //     const hei = this.$refs.tabsA[this.vantabsIndex].offsetHeight
      //     console.log('hei****', hei)
      //     if (hei > 95) {
      //       this.sanjiaoShow = true
      //     } else {
      //       this.sanjiaoShow = false
      //     }
      //   } else if (num < 10) {
      //     this.sanjiaoSFN(num)
      //   }
      // }, 1000)

      // 一个字24.5 一行28个字 宽度不固定 28 x 6  <con的总数
      let width = (this.$refs.sanjiaoDOMB && this.$refs.sanjiaoDOMB.clientWidth) || 0
      if (width === 0) {
        width = 686
      }
      let connum = 0
      // console.log('宽度', width)
      // console.log('报错*****8', this.content, this.vantabsIndex)
      if (this.vantabsIndex !== -1 && this.content[this.vantabsIndex].name === '引用法条') {
        if (this.content[this.vantabsIndex].value.length > 6) {
          this.sanjiaoShow = true
        } else {
          let len = this.content[this.vantabsIndex].value.length
          this.content[this.vantabsIndex].value.forEach((item) => {
            if (item.full_law.length > 27) {
              len += 1
            }
          })
          // console.log('计算full_law长度', len)
          if (len > 5) {
            this.sanjiaoShow = true
          } else {
            this.sanjiaoShow = false
          }
        }
        return
      }
      if (this.content.length > 0) {
        // console.log(this.content[this.vantabsIndex], this.vantabsIndex)
        const arr = this.content[this.vantabsIndex].value
        if (arr instanceof Array) {
          arr.forEach((itema) => {
            if (typeof connum === 'string') {
              connum += itema.replace(/<[^>]+>/g, '').length
            }
          })
        } else {
          connum += arr.length
        }
      }
      if (28 * 5 > connum) {
        // console.log('不显示')
        this.sanjiaoShow = false
      } else {
        this.sanjiaoShow = true
      }
    },
    activeBwidth() {
      setTimeout(() => {
        if (this.$refs.antabsA) {
          // console.log(this.$refs.antabsA.$children[0].$el.offsetWidth)
          // const width = this.$refs.antabsA.$children[0].$el.offsetWidth
          // this.tabsBwidth = 823 - width - 20
          // this.tabsshow = true
        }
      }, 1)
    },
    tabsDataInit() {
      // this.tabsData = []
      // console.table('tabsdatainit', this.item)
      this.tabsData = JSON.parse(JSON.stringify(this.item))
      // console.log('this.tabsdata', this.tabsData)
      this.selectTabs = []
      if (this.tabsData.word_frequency) {
        this.tabsData.word_frequency.forEach((item) => {
          if (item.name === '结果命中') {
            item.init = true
            this.tabsData.content.push(item)
          } else {
            this.selectTabs.push(item)
          }
        })
      } else {
        // console.log('啊啊', this.tabsData)
      }
      for (let i = 0; i < this.tabsData.content.length; i++) {
        const value = this.tabsData.content[i].value
        if (value.length === 0) {
          this.tabsData.content.splice(i, 1)
          i -= 1
        } else if (value.length === 1 && value[0] === '') {
          this.tabsData.content.splice(i, 1)
          i -= 1
        }
      }
      this.content = this.tabsData.content
      console.table('数据处理完成aaaa', this.tabsData)
      // this.activeBwidth()
      // this.$set(this.tabsData, 0, this.tabsData[0])
      this.vantabsIndex = this.content.length - 1
      this.vantabsactive = this.content.length - 1
      if (this.vantabsactive === -1) this.vantabsactive = 0
    },
    sanJiaoClick() {
      console.log('点击了三角2')
      // console.log('aaa', this.$refs.tabsA)
      const trannum = this.$refs.sanJiao.style.transform
      // console.log(trannum)
      if (trannum + '' === 'rotate(180deg) translateZ(0px)') {
        this.sanjiaoshow = false
        this.$refs.sanJiao.style.transform = 'rotate(0deg) translateZ(0)'
        this.$refs.tabsA.forEach((item) => {
          item.classList.remove('sanjiaoj')
        })
      } else {
        this.sanjiaoshow = true
        this.$refs.sanJiao.style.transform = 'rotate(180deg) translateZ(0)'
        this.$refs.tabsA.forEach((item) => {
          item.classList.add('sanjiaoj')
        })
      }
    },
    // 解析tab名称 区分num是否有值
    parseTabName(item) {
      const { name, num } = item
      return num ? `${name} (${num})` : name
    },
    // 对数据进行预处理
    prepareItemData(item) {
      const preferActive = '结果命中'
      const { content = [], word_frequency: wordfrequency = [] } = deepCopy(item)
      // 添加 f 作为词频标记
      const wf = wordfrequency.map((w) => ({ ...w, f: true })).filter((w) => isFilledArray(w.value) && w.value[0])
      const list = [...content, ...wf]

      // 如果value是字符串则转为数组
      list.forEach((item) => {
        const { value } = item
        if (typeOf(value) === 'string') {
          item.value = value ? [value] : null
        } else if (isFilledArray(value)) {
          item.value = value.filter((t) => t)
        } else {
          item.value = null
        }
      })

      this.tabList = list.filter((item) => isFilledArray(item.value))

      if (this.tabList.length > 0) {
        // 判断有没有 **结果命中**，如果有就默认选中结果命中，没有就显示其他的
        const jgmz = this.tabList.find((item) => item.name === preferActive)
        if (jgmz) {
          this.activeName = preferActive
        } else {
          this.activeName = wf.length > 0 ? wf[0].name : this.tabList[0].name
        }
      }
      this.$nextTick(() => {
        this.checkClamp()
      })
    },
    checkClamp() {
      setTimeout(() => {
        const zkcontentDom = this.$refs.zkcontentDom
        if (!zkcontentDom) return
        const tabId = zkcontentDom.querySelector(`#tab-${this.activeName}`)
        if (tabId && tabId.scrollHeight > DEFAULT_HEIGHT) {
          this.sanjiaoShow = true
        } else {
          this.sanjiaoShow = false
        }
      }, 60)
    },
  },
  created() {
    // this.tabsDataInit()
    // console.log('this.item', this.item)
    // this.titleShearstate = this.item.selected
    const index = this.selectedIds.findIndex((v) => v === this.item.doc_id)
    if (index !== -1) {
      this.titleShearstate = true
    } else {
      this.titleShearstate = false
    }
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(nv) {
        this.tabsData = JSON.parse(JSON.stringify(this.item))
        this.prepareItemData(nv)
        // console.log('item变化---', nv)
        // this.titleShearstate = this.item.selected
        // this.tabsDataInit()
        // this.sanjiaoSFN()
      },
    },
    activName() {
      this.$nextTick(() => {
        this.checkClamp()
      })
    },
    selectedIds(val) {
      const index = val.findIndex((v) => v === this.item.doc_id)
      // console.log(index, val, 'selectedIds----index')
      if (index !== -1) {
        this.titleShearstate = true
      } else {
        this.titleShearstate = false
      }
    },
    clearCheck(val) {
      if (val) {
        this.cleartitleShear()
        console.log(val, 'watch judge-------clearCheck')
      }
    },
    vantabsIndex(nv) {
      // console.log('计算三角')
      // this.sanjiaoSFN()
    },
    titleShearstate(nv) {
      try {
        // 只要执行就会报错 即使是空方法
        this.titshearFN()
      } catch {
        console.log('this.titshearfn 错误')
      }
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.sanjiaoSFN()
    //   }, 500)
    // })
  },
}
</script>

<style lang="stylus" scoped>
.judgement-item
  position relative
  padding-bottom 10px
  & .extra-info
    padding 0 10px
    & span
      margin-right 6px
      color #999
      &:after
        content '/'
        margin 0 0 0 6px
        color #999
      &:last-child
        margin-right 0
        &:after
          content none
  & .vantabshull
    & .full-law-text
      font-weight normal
      color #7f7f7f
      >>>em
        color red
</style>
<style scoped>
.tipsty {
  background: #ff9c39;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
}
/* mark会改变解构 */
::v-deep.tipsty mark {
  color: #fff !important ;
}
.tipsty :nth-child(2) :nth-child(1) :nth-child(1) {
  color: #fff !important ;
  /* display: none; */
}
.tipsty :nth-child(2) {
  display: none;
}
.tabsA-div {
  max-height: 95px;
  /* min-height: 100px; */
  overflow: hidden;
  /* margin: 0 16px; */
  margin: 0 10px;
  padding: 5px 5px 0 5px;
  border-left: 1px solid #1890ff;
  border-right: 1px solid #1890ff;
  border-top: 1px solid #1890ff;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__content {
  min-height: auto;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav > .van-tab {
  /* flex-basis: 20% !important; */
  flex: none;
  border: auto;
  /* border-bottom: 1px solid #1890ff; */
  color: #999;
  border-right: none;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav > .van-tab--active {
  color: #1890ff;
  border: 1px solid #1890ff;
  border-bottom: 1px solid #fff;
  border-radius: 5px 5px 0 0;
  background: #fff;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav {
  /* border: 0px solid #1890ff; */
  /* border-bottom: 1px solid #1890ff; */
  border: none;
  flex: 0;
  margin: 0 10px;
  /* display: -webkit-box; */
  /* border-bottom: 1px solid #1890ff; */
}
::v-deep.judgement-item > .vantabshull > .van-tabs.van-tabs--card > .van-tabs__wrap > .van-tabs__nav.van-tabs__nav--card > .van-tab.van-tab--active {
  flex: none;
  /* -webkit-flex: initial; */
  top: 2px;
}

.san-jiao-hull {
  height: 5px;
  border: 1px solid #1890ff;
  border-top: none;
  /* width: 100%; */
  padding: 5px 5px 0 5px;
  margin: 0 10px;
}
.san-jiao {
  width: 0px;
  background: rgba(190, 215, 239, 0);
  border-bottom: solid 16px #bcd8f2;
  border-left: solid 16px rgba(190, 215, 239, 0);
  /* float: right; */
  margin-right: 10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.sanjiaoj {
  max-height: 190px;
  overflow-y: auto;
}
.title-shear {
  /* position: absolute;
  right: 10px; */
  float: right;
  position: relative;
}
.tabsAspan {
  display: block;
}
.icon-copy {
  width: 15px;
  height: 15px;
  position: absolute;
  background: #fff;
}
.icon-copy-a:last-of-type {
  fill: #333;
}
.icon-copy-b:last-of-type {
  fill: #999;
}
.icon-copy-c:last-of-type {
  fill: #000;
}
.san-jiao-cen {
  width: 100%;
}

.vancheck::v-deep > .van-checkbox__icon > .van-icon {
  width: 20px;
  height: 20px;
  /* display: none; */
}
.vancheck::v-deep {
  overflow: visible;
}
.van-check-num {
  position: absolute;
  top: 0;
  right: 8px;
  text-align: center;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
::v-deep.vantabshull > .van-tabs > .van-tabs__wrap span {
  font-size: smaller;
}
</style>
