var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.dragMirrorPropStart || _vm.dragMirrorPropMove,
          expression: "dragMirrorPropStart || dragMirrorPropMove",
        },
      ],
      staticClass: "drag-mirror",
      style: _vm.mirrorStyle,
    },
    [
      _c(
        "div",
        { staticClass: "mirror-node" },
        [
          _vm.isCloneItem
            ? _c("div", { staticClass: "text-span" }, [
                _vm._v(" " + _vm._s(_vm.dragMirrorPropStart.text) + " "),
              ])
            : _vm._e(),
          _c("label-item", { attrs: { item: _vm.itemData } }),
          _vm.itemData
            ? _c("text-wrap", { attrs: { item: _vm.itemData } })
            : _vm._e(),
          _c("div", { staticClass: "text-node" }),
          _vm.animationHintVisible
            ? _c("div", { staticClass: "tooltiptext" }, [
                _c("i", { staticClass: "iconfont icon-4" }),
                _vm._v(" 拖过去 "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }