<template>
  <div>
    <ul class="suggestion-list-wrap">
      <li
        v-for="item in suggestions"
        :key="item.key"
        :class="{ 'group-item': true }"
        role="option"
      >
        <ul class="el-select-group__wrap">
          <li class="el-select-group__title" v-if="item.values.length">
            {{ item.category }}
          </li>
          <li>
            <ul class="el-select-group">
              <li
                v-for="(s, i) in item.values"
                @touchstart.stop.prevent="selectSuggestionItem(s, item)"
                :key="i"
                class="group-item-li"
                v-html="s.label"
              ></li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'search-suggestion',
  props: {
    suggestions: Array
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {
    selectSuggestionItem(s, item) {
      const payload = {
        category: item,
        item: s
      }
      this.$emit('selectSuggestionItem', payload)
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="stylus" scoped>
.suggestion-list-wrap
  position absolute
  width 100%
  top 40px
  left 0
  background #ffffff
  z-index 1001
  max-height 60vh
  overflow-y auto
  box-shadow 0 8px 12px #ebedf0
  & .group-item
    padding 4px 10px
    font-size 14px
    & .el-select-group__wrap
      & .el-select-group__title
        font-size 12px
        color #999
      & .el-select-group
        padding-left 10px
        & .group-item-li
          margin 4px
    & li
      &:hover
        background-color transparent
    li.group-item-li
      &:hover
        background-color #F5F7FA
</style>
