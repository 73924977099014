var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "suggestion-item-comp" },
    [
      _c("div", { staticClass: "suggestion-item-comp_title" }, [
        _c("div", [_vm._v("历史记录")]),
        _c(
          "div",
          {
            staticClass: "delete-all-btn",
            on: {
              touchend: function ($event) {
                $event.stopPropagation()
                return _vm.deleteAll.apply(null, arguments)
              },
            },
          },
          [_vm._v("全部删除")]
        ),
      ]),
      _vm._l(_vm.muster, function (t, i) {
        return _c("div", { key: i, staticClass: "text-tag-list" }, [
          _c(
            "div",
            {
              staticClass: "text-tag-content",
              on: {
                touchstart: function ($event) {
                  $event.stopPropagation()
                  return _vm.useTag(t)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.fmtText(t)) + " ")]
          ),
          _c(
            "div",
            {
              staticClass: "delete",
              on: {
                touchend: function ($event) {
                  $event.stopPropagation()
                  return _vm.deleteTagDirectly(t)
                },
              },
            },
            [_c("i", { staticClass: "iconfont icon-clear-2" })]
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }