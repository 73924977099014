var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item && _vm.item.label
    ? _c("div", { staticClass: "text-label" }, [
        _c(
          "span",
          {
            staticClass: "label-text",
            style: {
              color: _vm.item.color ? "#ffc369" : "#666666",
            },
          },
          [_vm._v(_vm._s(_vm.item.label))]
        ),
        _c(
          "span",
          {
            staticClass: "label-connector",
            style: {
              color: _vm.item.color ? "#ffc369" : "#666666",
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(_vm.item.label && _vm.item.value.length ? ":" : "") +
                " "
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }