<template>
  <div class="main-search-comp" @touchstart.stop="mainClickFn">
    <div class="tanceng" v-show="tancengshow"></div>
    <div class="slep1-hull" v-show="tancengshow">
      <div v-show="slep === 1">
        <div class="slep1-text">
          <span>点击添加或键盘空格、回车、确定按钮</span>
        </div>
        <div class="slep1-left"></div>
        <div class="slep1-bottom"></div>
        <div class="slep1-sanjiao-top"></div>
        <div class="slep1-sanjiao-bottom"></div>
      </div>
      <div v-show="slep === 2">
        <div class="slep2-text">
          <span>拖拽生成新标签</span>
        </div>
        <div class="slep2-quxian">
          <canvas id="slep2-canvas"></canvas>
        </div>
        <div class="slep2-sanjiao-top"></div>
        <div class="slep2-sanjiao-bottom"></div>
      </div>
      <div v-show="slep === 3">
        <div class="slep3-text">
          <span>拖拽生成新标签</span>
        </div>
      </div>
      <div v-show="slep === 4">
        <div class="slep4-text">
          <span>单击 "&" 可设置顺序和间隔字数</span>
        </div>
      </div>
      <div v-show="slep === 5">
        <div class="slep4-text">
          <span>双击显示同义词</span>
        </div>
        <div class="slep5-quxian">
          <canvas id="slep5-canvas"></canvas>
        </div>
        <div class="slep5-sanjiao-bottom"></div>
      </div>
    </div>
    <div class="search-area selp1">
      <div class="search-wrap">
        <div class="search-dropdown">
          <van-dropdown-menu>
            <van-dropdown-item :value="activeType" @change="onSearchTypeChange" :options="searchOption" />
          </van-dropdown-menu>
        </div>
        <div class="search-input" id="step1">
          <van-search
            v-model="inputValue"
            ref="inputRef"
            @keyup.native="hanldeKeySpace"
            @keydown.enter.native="handleKeyEnter"
            @focus="handleFocus"
            @blur="handleBlur"
            @input="onInputValueChange"
            placeholder="请输入搜索关键词"
            show-action
            shape="round"
          >
            <div slot="action">
              <span @click="search">添加</span>
              <i @click="help" class="iconfont icon-bangzhu"></i>
            </div>
          </van-search>
        </div>
        <suggestion-item
          v-if="muster.length > 0"
          v-show="showHistory"
          :searchType="activeType"
          :muster="muster"
          :shard="shard"
          @use="handleUseTag"
          @quote="handleQuoteTag"
          @query="handleQueryTag"
          @delete="handleDeleteHistory"
        />
        <search-suggestion v-show="showSuggestion" :suggestions="suggestions" @selectSuggestionItem="selectSuggestionItem" />
      </div>
    </div>
    <!-- <keep-alive> -->
    <search-dnd
      :searchType="activeType"
      ref="searchDnd"
      :loadingData="loadingData"
      :keywordArr="keywordArr"
      :fixedTags="fixedTags"
      :dragTags="dragTags"
      :cloneItemList="cloneItemList"
      @update:synonym="updateSynonym"
      @deleteMe="handleDeleteFixedTags"
      @update="handleUpdate"
      @combine="handleCombine"
      @delete="deleteById"
      @detach="handleDetach"
      @clear="cancel"
      @query="emitQueryOld"
      @queryOld="emitQuery"
    />
    <!-- </keep-alive> -->
    <van-action-sheet v-model="synonymsActionSheetVisible" description="使用同义词替换" close-on-click-action>
      <div class="synonym-list">
        <div class="item" v-for="s in synonymList" :key="s">
          <van-button type="info">{{ s }}</van-button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Qs from 'qs'
import { debounce } from 'throttle-debounce'
import { locCondition } from '@/common/searchConst'

// import Intro from '../../pages/Search/pages/tabs/introConfig'
// import NavigationMixin from './navigation-mixin'
import shareQueryMixin from './sharequery-mixin'

import SearchDnd from '@/components/search-dnd'
import SuggestionItem from './components/suggestionItem'
import SearchSuggestion from './components/searchSuggestion'
// import SearchHistory from './components/searchHistory'

export default {
  name: 'main-search',
  emit: ['update:synonym'],
  props: {
    keywordArr: Array,
    loadingData: Boolean,
    type: String,
    active: String,
    contentType: String,
    queryCache: Object,
  },
  mixins: [shareQueryMixin],
  components: {
    SearchDnd,
    SuggestionItem,
    SearchSuggestion,
    // SearchHistory
  },
  data() {
    return {
      visible: true,
      activated: false,
      debounce: 500,
      activeType: 'judgementSearch',
      searchOption: [
        { text: '判例', value: 'judgementSearch' },
        { text: '法律', value: 'lawSearch' },
        { text: '知识', value: 'ledgeSearch' },
        { text: '标准', value: 'criterionSearch' },
      ],
      inputValue: '',
      tancengshow: false,
      loading: false,
      highlightedIndex: -1,
      suggestionDisabled: false,
      suggestions: [],
      selpflish: false,
      hoverIndex: -1,
      slep: 1,
      inputHovering: false,
      dragTags: [],
      fixedTags: [],
      muster: [],
      shard: [],
      inputcontent: '',
      synonymsActionSheetVisible: false,
      synonymList: ['离婚', '净身出户', '离婚1', '净身出户1', '离婚2', '净身出户2', '离婚3', '净身出户3', '离婚4', '净身出户4', '离婚5', '净身出户5', '离婚6', '净身出户6', '离婚7', '净身出户7'],
    }
  },
  computed: {
    ...mapState('dnd', ['isDropDragging']),
    ...mapGetters('user', ['userId']),
    cloneItemList() {
      return locCondition[this.activeType]
    },
    // 显示拖拽组件
    showSearchDnd() {
      return !!(this.fixedTags.length || this.dragTags.length)
    },
    // 显示关键词候选组件
    showSuggestion() {
      const suggestions = this.suggestions
      const isValidData = Array.isArray(suggestions) && suggestions.length > 0
      return (this.showHistory || isValidData) && this.activated && this.inputValue
    },
    // 显示检索历史
    showHistory() {
      if (!this.showSearchDnd && !this.inputValue && this.userId && this.activated) {
        return true
      } else {
        return false
      }
    },
    /**
     * tag-btn 列表
     */
    queryTagsData() {
      const q = []
      this.dragTags.forEach((item) => {
        if (item.value.length === 1 && !item.label && !item.pattern && !item.key) {
          var obj = {
            key: item.value[0],
            synonyms: [],
          }
          if (item.search_word) {
            obj.synonyms = item.search_word[0].synonyms
          }
          q.push(obj)
          // q.push(item.value[0])
        }
      })
      return [...new Set(q)]
    },

    filterTagsData() {
      return this.fixedTags
    },
  },
  methods: {
    updateSynonym(val) {
      this.$emit('update:synonym', val)
    },
    // 获取检索历史数据
    getHistoryData() {
      const params = {
        user_id: this.userId.toString(),
        // user_id: '10245',
        page_size: 10,
      }
      this.$axios.post(`${this.$pydataBase}/api/nvi/judgementSearchHistory`, params).then(({ data }) => {
        if (data.code === 200) {
          const { muster, shard } = data
          this.muster = muster
          this.shard = shard
        }
      })
    },
    // 删除历史记录 调用删除操作已在子组件完成 这里把数据清空即可
    async handleDeleteHistory() {
      this.muster = []
      this.shard = []
    },
    // 请求搜索建议数据
    async querySearchAsync(queryString) {
      // 调用 callback 返回建议列表的数据
      const res = await this.getSuggestion(queryString)
      if (res.code === 200 && res.data.length) {
        res.data.map((item) => {
          if (item.values.length > 0) {
            this.suggestions.push(item)
          }
        })
        console.log(this.suggestions)
      } else {
        this.suggestions = []
      }
    },
    // 处理输入值改变
    onInputValueChange(value) {
      this.$emit('input', value)
      this.suggestionDisabled = false
      if (value.trim().length === 0) {
        this.inputValue = ''
        this.suggestions = []
      } else if (value && value.trim().length !== 0) {
        this.debouncedGetData()
      } else {
        console.log('handleChange --> ', value)
        this.suggestions = []
      }
    },
    // 清空搜索建议数据
    clearSuggestionData() {
      this.suggestions = []
    },
    // 处理聚焦事件
    handleFocus(event) {
      this.activated = true
      this.$emit('focus', event)
      if (!this.inputValue) {
        this.clearSuggestionData()
        if (!this.fixedTags.length) {
          this.handleHistory()
        }
      }
    },
    // 处理失焦事件
    handleBlur() {
      this.$emit('blur', event)
      this.activated = false
    },
    // 处理检索历史数据
    handleHistory() {
      if (this.userId) {
        this.getHistoryData()
      }
    },
    // 处理清空输入值
    handleClear() {
      this.activated = false
      this.$emit('clear')
    },
    handleInputSelect() {},
    // 空格键处理
    hanldeKeySpace(e) {
      if (this.inputValue.trim() !== '' && this.inputValue.length && this.inputValue.indexOf(' ') > -1) {
        this.pushKeyword(this.inputValue.trim())
        this.inputValue = ''
        this.suggestions = []
        this.$nextTick(() => {})
      }
    },
    // #enter键# 事件处理
    handleKeyEnter(e) {
      if (this.activated && this.inputValue) {
        this.search()
      }
    },
    // 选择搜索建议条目
    selectSuggestionItem(payload) {
      const { item, category } = payload
      if (this.active === 'lawSearch' || item.law_id) {
        this.$emit('toLawDetail', item)
        return
      }
      const { category: label } = category
      const { value, key } = item
      this.inputValue = ''
      const o = {
        key: key.replace(/_+/g, ''),
        label,
        value: [value],
        id: `${key}_${value}`,
        pattern: '',
      }
      this.dragTags = [...this.dragTags, o]
      this.activated = false
      this.suggestions = []
    },
    introFn() {
      this.pushKeyword('合同')
      this.$nextTick(() => {
        setTimeout(() => {
          const domarr = document.querySelectorAll('.selp1')
          for (const i of domarr) {
            i.style.zIndex = 2
          }
          console.log(domarr)
        }, 10)
      })
      this.tancengshow = true
    },
    // 检查固定标签是否已经存在
    checkFixedTagExsit(tag) {
      let e = false
      this.fixedTags.forEach((element) => {
        if (element.key === tag.key && element.value[0] === tag.value[0]) {
          e = true
        }
      })
      return e
    },
    // 请求检索建议数据
    async getSuggestion(kw) {
      const clickDick = {
        judgementSearch: 0,
        lawSearch: 1,
        ledgeSearch: 2,
      }
      const params = {
        word_char: kw,
        offset: 1,
        click: clickDick[this.activeType],
      }
      let api = '/api/nvi/judgement/typeWord'
      if (this.activeType === 'lawSearch') {
        api = '/api/nvi/law/typeWord'
      }
      this.loading = true
      const { data } = await this.$axios.post(`${this.$pydataBase}${api}`, Qs.stringify(params))
      this.loading = false
      return data
    },
    // 搜索按钮触发
    search() {
      if (this.inputValue.trim() !== '' && this.inputValue.length) {
        this.pushKeyword(this.inputValue.trim())
        this.inputValue = ''
        this.activated = false
      }
    },
    close(e) {
      this.activated = false
    },
    // 更新同义词
    updateTextTagSynonyms(payload) {
      console.log('更新同义词', payload)
      this.openSynonymsActionSheet()
    },
    openSynonymsActionSheet() {
      setTimeout(() => {
        if (!this.isDropDragging) {
          this.synonymsActionSheetVisible = true
        }
      }, 100)
    },
    closeSynonymsActionSheet() {
      this.synonymsActionSheetVisible = false
    },
    // 删除固定标签
    handleDeleteFixedTags(tag) {
      this.fixedTags = this.fixedTags.filter((item) => {
        return item.id !== tag.id
      })
    },
    // 更新标签
    handleUpdate(payload) {
      const { id } = payload
      const arr = this.dragTags.map((item) => {
        if (item.id === id) {
          return payload
        } else {
          return item
        }
      })
      this.dragTags = arr
    },
    // 融合标签
    handleCombine(payload) {
      const { source, target } = payload
      // eslint-disable-next-line camelcase
      const { value, id, search_word } = source
      let newword = []
      if (target.search_word) {
        // eslint-disable-next-line camelcase
        if (search_word) {
          // eslint-disable-next-line camelcase
          newword = [...target.search_word, ...search_word]
        } else {
          newword = [...target.search_word]
        }
      } else {
        // eslint-disable-next-line camelcase
        if (search_word) {
          // eslint-disable-next-line camelcase
          newword = [...search_word]
        }
      }
      console.log('融合标签', payload, newword)
      const newItem = {
        ...target,
        // eslint-disable-next-line camelcase
        search_word: newword,
        value: Array.from(new Set([...target.value, ...value])),
      }
      this.deleteById(id)
      this.handleUpdate(newItem)
    },
    // 根据id进行删除
    deleteById(id) {
      this.dragTags = this.dragTags.filter((item) => item.id !== id)
      if (!this.dragTags[0]) {
        this.inputcontent = ''
      } else {
        this.inputcontent = this.dragTags[0].value[0]
      }
    },
    /**
     * 分离标签
     *! 分离顺序 1.label 2.[value]拆分成单个 3.删除
     */
    handleDetach(payload) {
      console.log('handleDetach', payload)
      const { label, value, pattern, id } = payload
      if (label && label !== '') {
        console.log('删除 label')
        const item = {
          label: '',
          pattern,
          value,
          // color: true, // 记录标签是否蓝色
          id,
        }
        this.handleUpdate(item)
      } else if (label === '' && value.length > 1) {
        console.log('拆分多标签为单个')
        this.deleteById(id)
        value.forEach((element) => {
          const item = {
            id: this.genID(),
            label: '',
            pattern: '',
            value: [element],
          }
          this.dragTags = [...this.dragTags, item]
        })
      } else if (label === '' && !pattern && value.length === 1) {
        console.log('删除单个标签')
        this.deleteById(id)
      }
    },
    // 生成唯一id
    genID(length) {
      return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36)
    },
    // 清除标签
    cancel() {
      this.$emit('cancel', !this.dragTags.length)
    },
    calcLabelCode(name) {
      const arr = this.cloneItemList.filter((item) => item.name === name)
      if (arr.length) {
        return arr[0].value
      } else {
        return ''
      }
    },
    // 处理标签数据并触发检索事件
    handleQuery() {
      const withLabelArr = this.dragTags.filter((item) => {
        return item.label || item.pattern || item.key
      })
      const l = withLabelArr.map((item) => {
        const { label, pattern, value, key, interval } = item
        if (label) {
          return {
            key,
            label: label,
            pattern,
            interval: interval,
            value,
          }
        } else {
          if (value.length > 1) {
            if (pattern) {
              return {
                key: 'content',
                label: '全文',
                pattern,
                interval: interval,
                value,
              }
            } else {
              return {
                key,
                label: '',
                pattern,
                interval: interval,
                value,
              }
            }
          } else {
            return {
              key,
              label: '',
              pattern,
              interval: interval,
              value,
            }
          }
        }
      })
      // console.log('l---=====', l)
      l.forEach((item) => {
        if (!item.key) {
          item.key = 'content'
        }
      })
      const data = {
        query_condition: this.queryTagsData,
        loc_condition: l,
        filter_condition: this.filterTagsData,
      }

      // 如果登录则携带userId
      if (this.userId) {
        data.user_id = this.userId
      }
      const params = {
        type: this.activeType,
        data,
        tag: this.dragTags || [],
      }
      // 记录参数
      // console.log('mainsearch=====---', params)
      this.$bus.$emit('mainSearch', params)
    },
    // 检查单标签是否已经存在
    checkSingleTagExist(t) {
      return this.queryTagsData.indexOf(t) > -1
    },
    // 推送关键词
    pushKeyword(keyword) {
      // 如果存在
      const e = this.checkSingleTagExist(keyword)
      if (e) {
        return
      }
      const kw = {
        label: '',
        pattern: '',
        value: [keyword],
        color: true,
        id: this.genID(),
      }
      console.log('kw---===', kw)
      // console.log('this.dragtags', this.dragTags)
      this.dragTags = [...this.dragTags, kw]
      this.$bus.$emit('dragtagsData', JSON.parse(JSON.stringify(this.dragTags)))
    },
    // 将不带条件的标签数据 生成为 可以拖动的标签数据
    genDragTag(arr) {
      // console.log('gendragtag====----')
      const r = []
      arr.forEach((item) => {
        console.log(item)
        const e = this.checkSingleTagExist(item)
        if (!e) {
          console.log('!e', [item])
          r.push({
            id: this.genID(),
            label: '',
            pattern: '',
            value: [item],
          })
          console.log(r)
        }
      })
      console.log('gendragtag==', r)
      return r
    },
    // 格式化loc_conditions数据以用于拖拽组件
    formatLocConditions(arr) {
      const r = []
      arr.forEach((item) => {
        const { pattern, value, label, key } = item
        let tag = {}
        tag = {
          id: this.genID(),
          pattern,
          key,
          label,
          value,
        }
        r.push(tag)
      })
      return r
    },
    // 格式化fliter_conditions数据以用于拖拽组件
    formatFilterConditions(arr) {
      const r = []
      arr.forEach((item) => {
        const { key, value, label } = item
        const e = this.checkFixedTagExsit(item)
        if (!e) {
          r.push({
            key,
            value,
            label,
            id: `${key}_${value[0]}`,
          })
        }
      })
      return r
    },
    // 使用标签 清除之前的标签
    handleUseTag(tags) {
      // const t = this.genDragTag(tags)
      // let r = []
      // let newr = []
      // console.table('tags===-', tags)
      // tags.forEach(item => {
      //   console.table(item)
      //   const e = this.checkSingleTagExist(item)
      //   if (!e) {
      //     console.table('!e', [item])
      //     r.push({
      //       id: this.genID(),
      //       label: '',
      //       pattern: '',
      //       value: [item]
      //     })
      //     newr.push({
      //       id: this.genID(),
      //       label: '',
      //       pattern: '',
      //       value: [item]
      //     })
      //     console.table(r)
      //     console.log(r)
      //   }
      // })

      // let newr = JSON.parse(JSON.stringify(r))
      // console.table('handleusetag=====', newr)
      // console.table('handleusetag=====', r)

      this.fixedTags = []
      for (const i of tags) {
        // console.log('push key word', i)
        this.pushKeyword(i)
      }
      // this.dragTags = JSON.parse(JSON.stringify(r))
      this.activated = false
    },
    // 引用标签
    handleQuoteTag(payload) {
      const { filter_condition: f, loc_condition: l, query_condition: q } = payload
      if (f.length) {
        this.fixedTags = [...this.fixedTags, ...this.formatFilterConditions(f)]
      }
      if (l.length) {
        this.dragTags = [...this.dragTags, ...this.formatLocConditions(l)]
      }
      if (q.length) {
        const qTags = this.genDragTag(q)
        this.dragTags = [...this.dragTags, ...qTags]
      }
      this.activated = false
    },
    // 检索标签
    handleQueryTag(payload) {
      const { filter_condition: f, loc_condition: l, query_condition: q } = payload
      if (f.length) {
        this.fixedTags = this.formatFilterConditions(f)
      }
      if (l.length) {
        this.dragTags = this.formatLocConditions(l)
      } else {
        this.dragTags = []
      }
      if (q.length) {
        this.dragTags = [...this.dragTags, ...this.genDragTag(q)]
      }
      this.activated = false
      this.handleQuery()
    },
    // 推送filter conditions 支持多个
    handlePushSearchConditions(payload) {
      const { check, data: tags, query } = payload
      if (check) {
        tags.forEach((tag) => {
          const e = this.checkFixedTagExsit(tag)
          if (!e) {
            this.fixedTags = [...this.fixedTags, tag]
          }
        })
      } else {
        tags.forEach((tag) => {
          this.handleDeleteFixedTags(tags)
        })
      }
      if (query) {
        this.handleQuery()
      }
    },
    // 引导动画逻辑
    mainClickFn(ev) {
      if (this.tancengshow && !this.selpflish) {
        console.log('点击了', ev.target)
        // if (ev.target.className !== 'tanceng') {

        this.slep += 1
        if (this.slep === 2) {
          this.selpflish = true
          const domarr = document.querySelectorAll('.selp1')
          for (const i of domarr) {
            i.style.zIndex = 'auto'
          }
          const domarr2 = document.querySelectorAll('.selp2')
          for (const i of domarr2) {
            i.style.zIndex = 2
          }
          this.$nextTick(() => {
            // let domarr2 = document.querySelectorAll('.selp2')
            const domarr2div = document.querySelector('.selp2-btdiv')
            if (domarr2div) {
              domarr2div.style.display = 'block'
              domarr2div.style.zIndex = 3
              domarr2div.className += ' search-selp2-tuodong'
            }
            setTimeout(() => {
              // this.$bus.$emit('search-tuodong', true)
              domarr2div.className += ' search-selp2-tuodong2'
              setTimeout(() => {
                this.dragTags = [
                  {
                    color: true,
                    id: 'r0mx30w3ko00000000',
                    label: '标题',
                    pattern: '',
                    value: ['法律'],
                  },
                ]
                domarr2div.style.display = 'none'
                domarr2div.className = 'selp2-btdiv'
                this.$nextTick(() => {
                  const domarr2 = document.querySelectorAll('.selp2')
                  for (const i of domarr2) {
                    i.style.zIndex = 2
                  }
                  this.selpflish = false
                })
              }, 1000)
            }, 1000)
          })
          console.log('selp2---===-=', domarr2)
          this.draw()
        } else if (this.slep === 3) {
          this.selpflish = true
          this.dragTags = [
            {
              color: true,
              id: 'r0mx30w3ko00000000',
              label: '',
              pattern: '',
              value: ['法律'],
            },
            {
              color: true,
              id: 'r0mx30w3ko00000001',
              label: '',
              pattern: '',
              value: ['合同'],
            },
          ]
          const domarr = document.querySelectorAll('.selp2')
          for (const i of domarr) {
            i.style.zIndex = 'auto'
          }
          this.$nextTick(() => {
            const domarr2 = document.querySelectorAll('.selp3')
            for (const i of domarr2) {
              i.style.zIndex = 2
            }
            console.log('selp3---', domarr2)
            this.$bus.$emit('search-tuodong', true)
            if (domarr2[3]) {
              domarr2[3].className += ' search-selp3-tuodong'
            }
            setTimeout(() => {
              domarr2[3].className += ' search-selp3-tuodong2'
              setTimeout(() => {
                this.dragTags = [
                  {
                    color: true,
                    id: 'r0mx30w3ko00000000',
                    label: '',
                    pattern: '同句',
                    value: ['法律', '合同'],
                  },
                ]
                this.$bus.$emit('search-tuodong', false)
                this.$nextTick(() => {
                  const domarr2 = document.querySelectorAll('.selp3')
                  for (const i of domarr2) {
                    i.style.zIndex = 2
                  }
                  this.selpflish = false
                })
              }, 1000)
            }, 1000)
            console.log(domarr2)
          })
        } else if (this.slep === 4) {
          this.$bus.$emit('search-selp4-click', true)
        } else if (this.slep === 5) {
          this.drawe()
          this.$bus.$emit('search-selp4-click', false)
          this.$bus.$emit('search-selp5-click', true)
        } else if (this.slep > 5) {
          this.$bus.$emit('search-selp5-click', false)
          this.dragTags = []
          this.slep = 1
          localStorage.setItem('xsyddh', '1')
          this.tancengshow = false
        }
        // }
        ev.preventDefault()
      }
    },
    // 推送filter conditions 只支持单个
    handlePushSearchCondition(payload) {
      const { check, data: tag, query } = payload
      if (check) {
        const e = this.checkFixedTagExsit(tag)
        if (!e) {
          this.fixedTags = [...this.fixedTags, tag]
        }
      } else {
        this.handleDeleteFixedTags(tag)
      }
      if (query) {
        this.handleQuery()
      }
    },
    // #拖拽检索组件# 触发检索 检索全部
    emitQuery(data) {
      // 如果输入框有值则转为标签不执行检索
      if (this.inputValue) {
        this.pushKeyword(this.inputValue.trim())
        this.inputValue = ''
        return
      }
      this.$nextTick(() => {
        this.debouncedQuery()
        this.$emit('emitQuery', { type: this.activeType, data })
      })
    },
    // 去掉灰色
    emitQueryOld(data) {
      // this.fixedTags = []
      let num = 0
      // 判断是否清除
      for (const i of this.fixedTags) {
        if (i.color === true) {
          num += 1
        }
      }
      for (const j of data) {
        if (j.color === true) {
          num += 1
        }
      }
      for (let i = data.length - 1; i >= 0; i--) {
        if (data[i].color === false && num !== 0) {
          // 删除当前
          data.splice(data.indexOf(data[i]), 1)
        } else {
          // 变为灰色
          data[i].color = false
        }
      }
      for (let i = this.fixedTags.length - 1; i >= 0; i--) {
        if (this.fixedTags[i].color === false && num !== 0) {
          // 删除当前
          this.fixedTags.splice(this.fixedTags.indexOf(this.fixedTags[i]), 1)
        } else {
          // 变为灰色
          this.fixedTags[i].color = false
        }
      }
      this.emitQuery(data)
      console.log('emit query old')
    },
    // 检索类型切换时
    onSearchTypeChange(value) {
      console.log(value)
      // console.log('onSearchTypeChange', this.activeType, value)
      this.$bus.$emit('checksearchwrap')
      this.reduceTagsDataV3(this.activeType, value, this.dragTags)
      this.activeType = value
    },
    /* 处理标签数据
    * 单个的关键词保留
    * 关键词相互组合的也保留（同句同段或者不包含）
    ! 上方标签和关键词标签组合的去除
    */
    reduceTagsData() {
      this.dragTags = this.dragTags.filter((item) => {
        return !item.key
      })
      // 清除左侧树产生的标签
      this.fixedTags = []
    },
    cloneItemClick(res) {
      console.log('收到', res)
      this.dragTags.forEach((item) => {
        console.log(item)
        if (item.label === '') {
          // item.label = res[0]
          this.$set(item, 'label', res.value[0])
          this.$set(item, 'key', res.key)
        }
      })
      console.log(this.dragTags)
    },
    // 将tag转为字符串的形式以-分隔
    tagToStr(item) {
      return `${item.key}-${item.label}-${item.pattern}-${item.value.sort().toString()}`
    },
    // 2020-01-22 使用新版切换逻辑
    reduceTagsDataV2(from, to, dragTags) {
      if (from === to) {
        return
      }
      const f = this.queryCache && this.queryCache[from]
      const t = this.queryCache && this.queryCache[to]
      // 将tag转为字符串的形式 忽略pattern字段
      const genItemStr = (item) => `${item.key}${item.label}${item.value.sort().toString()}`

      if (dragTags && dragTags.length) {
        const t1 = dragTags.filter((item) => {
          return !item.key || item.key === 'bt'
        })
        // 将过滤后的标签 key设置为content
        const t2 = dragTags
          .filter((item) => {
            return item.key && item.key !== 'bt'
          })
          .map((elem) => {
            const item = { ...elem }
            item.key = 'content'
            item.label = ''
            return item
          })
        const newWrap = [...t1]
        // 去重合并
        t2.forEach((item) => {
          const itemStr = genItemStr(item)
          const strArr = newWrap.map((item) => genItemStr(item))
          if (strArr.indexOf(itemStr) === -1) {
            newWrap.push(item)
          }
        })
        const tags = newWrap
        if (!t) {
          this.dragTags = tags
        } else {
          if (f && t) {
            const { query_condition: qc, loc_condition: lc } = f.data
            const { query_condition: qct, loc_condition: lct } = t.data
            let newQc = []
            if (qc && qc.length) {
              newQc = [...new Set([...qc, ...qct])]
            }
            const newLc = [...lct]

            if (lc && lc.length) {
              lc.forEach((item) => {
                const lctStrArr = newLc.map((item) => genItemStr(item))
                const itemStr = genItemStr(item)
                if (lctStrArr.indexOf(itemStr) === -1) {
                  newLc.push(item)
                }
              })
            }
            const qTags = newQc.map((item) => {
              return { label: '', pattern: '', value: [item], id: this.genID() }
            })
            const newTags = [...newLc, ...qTags]

            this.dragTags = newTags
          }
        }
      }
    },
    draw() {
      var canvas = document.getElementById('slep2-canvas')
      var context = canvas.getContext('2d')
      // 绘制起始点、控制点、终点
      context.beginPath()
      context.moveTo(20, 30)

      context.quadraticCurveTo(30, 20, 110, 110)
      context.strokeStyle = '#fff'

      context.stroke()
    },
    drawe() {
      const canvas = document.getElementById('slep5-canvas')
      const context = canvas.getContext('2d')
      context.lineWidth = 5
      context.strokeStyle = '#fff'
      context.arc(70, 70, 100, 0, 1.5 * Math.PI, true)
      context.stroke()
    },
    // 2020-02-15 更新处理规则 去掉key的组合
    reduceTagsDataV3(from, to, dragTags) {
      if (from !== to) {
        this.dragTags = this.dragTags.map((tag) => {
          return {
            ...tag,
            key: '',
            label: '',
          }
        })
        // 去掉筛选标签
        this.fixedTags = []
      }
    },
    // 注入测试数据以便于开发
    injectDemoData() {
      this.dragTags = [
        { label: '', pattern: '', value: ['离婚'], id: 'efgcv168gqo0000000' },
        { label: '', pattern: '', value: ['感情'], id: 'bnjye3tj5ls0000000' },
      ]
    },
    // 初始化标签数据
    handleInitTags(payload) {
      const { filter_condition: f, query_condition: q, loc_condition: l } = payload
      if (q && q.length) {
        this.dragTags = [...this.dragTags, ...this.genDragTag(q)]
      }
      if (f && f.length) {
        const arr = f.map((elem) => {
          return {
            ...elem,
            id: this.genID(),
          }
        })
        this.dragTags = [...this.dragTags, ...arr]
      }
      if (l && l.length) {
        this.dragTags = [...this.dragTags, ...l]
      }
    },
    changeSearchType(type) {
      this.activeType = type
    },
    // 派发子组件searchDnd检索
    dispatchQueryUseDndRef() {
      this.$refs.searchDnd.query()
    },
    // 点击帮助
    help() {
      const ua = navigator.userAgent
      if (ua === 'fb-flutter-Webview') {
        try {
          //    JSON.stringify({ takePhoneNumber: phone })
          window.toHelpVideoView.postMessage(
            JSON.stringify({
              kind: 'helpdashuju',
              secKind: 'ydguanjianci',
            })
          )
        } catch (error) {
          console.log(error, 'back.postMessage')
        }
      } else if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toHelpVideoView('helpdashuju', 'ydguanjianci')
        return false
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.toHelpVideoView.postMessage(
          JSON.stringify({
            kind: 'helpdashuju',
            secKind: 'ydguanjianci',
          })
        )
      } else {
        this.$notify('非原生端，暂不支持')
      }
    },
  },
  created() {
    this.$bus.$on('initTags', (payload) => {
      this.handleInitTags(payload)
    })
  },
  mounted() {
    this.debouncedGetData = debounce(this.debounce, () => {
      if (this.inputValue.trim().length !== 0) {
        if (this.activeType === 'judgementSearch' || this.activeType === 'lawSearch') {
          this.querySearchAsync(this.inputValue)
        }
      }
    })

    this.debouncedQuery = debounce(500, () => {
      this.handleQuery()
    })
    this.$bus.$on('makeQueryByPayload', (payload) => {
      this.handleConditionToQuery(payload)
    })
    this.$bus.$on('pushSearchCondition', (param) => {
      const { data } = param
      if (data instanceof Array) {
        this.handlePushSearchConditions(param)
      } else {
        this.handlePushSearchCondition(param)
      }
    })
    this.$bus.$on('clone-item-click', (res) => {
      this.cloneItemClick(res)
    })
    this.$bus.$on('checksearchwrap', (res) => {
      this.onInputValueChange(this.inputValue)
    })
    // 标签文字点击 触发更新同义词
    this.$bus.$on('updateSynonyms', (payload) => {
      this.updateTextTagSynonyms(payload)
    })
    // console.log('当前页面加载完成')
    // console.log(navigator)
    // document.cookie = 'yindao'
  },
  watch: {
    // active(nv, ov) {
    //   if (nv) {
    //     this.activeType = nv
    //     this.onSearchTypeChange(nv)
    //   }
    // }
    // contentType: {
    //   handler: function(nv) {
    //     if (nv) {
    //       console.log('contentType change', nv)
    //       this.changeSearchType(nv)
    //       console.log('activeType is', this.activeType)
    //     }
    //   },
    //   immediate: true
    // }
  },
}
</script>

<style lang="stylus">
.main-search-comp
  position relative
  width 100%
  user-select none
  & .search-area
    user-select none
    position relative
    background #fff
    & .search-wrap
      display flex
      & .search-dropdown
        width 60px
      & .search-input
        flex 1
  & .search-dnd-comp
    padding 10px 5px 0 5px
    user-select none
    display flex
    flex-direction column
    height calc(100vh - 60px)
    & .flex-area
      // flex 1
    & .type-selector
      width 90px
      & .el-input__inner
        border-radius 4px 0 0 4px
    & .type-input
      flex 1
    & .search-suggestion
      z-index 2
      position absolute
      width 100%
      margin-top 5px
      & .suggestion-loading
        min-height 40px
        display flex
        justify-content center
        align-items center
      & em
        color red
        font-style normal
      &.el-autocomplete-suggestion
        li
          &:hover
            background-color transparent
        li.group-item-li
          &:hover
            background-color #F5F7FA
.synonym-list
  display flex
  height 200px
  padding 10px
  font-size 13px
  flex-wrap wrap
  overflow-y scroll
  & .item
    margin 4px 5px
</style>
<style scoped>
.tanceng {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
}
.slep1-hull {
  position: fixed;
  z-index: 2;
  color: #fff;
  top: 14%;
  /* border-bottom: 3px dashed #fff; */
  left: 12%;
  font-size: xx-large;
}
.slep1-left {
  /* border-right: 3px dashed #fff; */
  height: 324%;
  top: -130%;
  width: 0px;
  font-size: xx-large;
  border-left: 3px #fff dashed;
  /* background: #fff; */
  position: absolute;
  right: -10%;
}
.slep1-bottom {
  height: 340%;
  width: 101%;
  border-top: 4px dashed #fff;
  position: absolute;
  right: -8%;
  top: 175%;
  border-left: 4px dashed #fff;
}
.slep1-sanjiao-top {
  width: 0px;
  position: absolute;
  height: 0px;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  right: -12%;
  top: -168%;
}
.slep1-sanjiao-bottom {
  width: 0px;
  position: absolute;
  height: 0px;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  /* right: 78%; */
  transform: rotate(180deg);
  left: 5%;
  /* top: -79%; */
  bottom: -450%;
}
#slep2-canvas {
  width: 200px;
  height: 200px;
  /* border: 1px solid #fff; */
}
#slep5-canvas {
  width: 150px;
  height: 200px;
  transform: rotate(28deg);
}
.slep2-quxian {
  position: absolute;
  top: -156%;
  left: -15%;
}
.slep2-text {
  padding-left: 40px;
}
.slep3-text {
  padding-top: 46%;
}
.slep2-sanjiao-top {
  width: 0px;
  position: absolute;
  height: 0px;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  top: 1%;
  left: -9%;
  transform: rotate(-34deg);
}
.slep2-sanjiao-bottom {
  width: 0px;
  position: absolute;
  height: 0px;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  transform: rotate(154deg);
  bottom: -470%;
  left: 28%;
}
.slep5-sanjiao-bottom {
  width: 0px;
  position: absolute;
  height: 0px;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  transform: rotate(215deg);
  bottom: 44%;
  left: 53%;
}
</style>
<style>
.search-selp3-tuodong {
  position: absolute;
  transition: all 1s;
  top: 3%;
  left: 38%;
}
.search-selp3-tuodong2 {
  position: absolute;
  transition: all 1s;
  top: -22%;
  left: -9%;
}
.search-selp2-tuodong {
  position: absolute;
  transition: all 1s;
  top: 3%;
  left: 2%;
}
.search-selp2-tuodong2 {
  position: absolute;
  transition: all 1s;
  top: 29%;
  left: 19%;
}
</style>
