<template>
  <div class="search-dnd-wrap" @touchstart="searchdndClickFn">
    <drag-mirror />
    <div class="search-dnd-comp">
      <div v-if="topLabels.length" ref="cloneItemWrap" class="search-dnd-comp__banner">
        <div
          v-for="(item, index) in topLabels"
          :key="index"
          :class="[
            'search-dnd-comp__banner-list',
            searchType === 'judgementSearch' ? 'search-dnd-color' : '',
            searchType === 'lawSearch' ? 'search-law-color' : '',
            searchType === 'ledgeSearch' ? 'search-ledge-color' : '',
          ]"
        >
          <clone-item
            v-for="c in item"
            :key="c.value"
            :data="c"
            :searchType="searchType"
            @onTouchstart="onTouchstart"
            @onTouchmove="onTouchmove"
            @onTouchend="onTouchend"
            @onTouchcancel="onTouchcancel"
          />
        </div>
        <div class="selp2-btdiv">标题</div>
      </div>
      <div ref="flexArea" class="flex-area">
        <div class="query-tag__wrap">
          <div v-for="t in fixedTags" :key="t.key + t.value" class="fixed-tag query-item">
            <div class="sec"></div>
            <div class="combined">
              <div
                class="tag-btn"
                :style="{
                  background: t.color ? '#409eff' : '#E8E8E8',
                  borderColor: t.color ? '#409eff' : '#E8E8E8',
                }"
              >
                <span
                  :style="{
                    color: t.color ? '#ffc369' : '#666666',
                  }"
                  >{{ fTagFmt2(t)[0] }}:</span
                >
                <span
                  :style="{
                    color: t.color ? '#FFFFFF' : '#999999',
                  }"
                  >{{ fTagFmt2(t)[1] }}</span
                >
              </div>
              <div class="detach" @click="deleteFixed(t)">
                <i class="van-icon van-icon-cross"></i>
              </div>
            </div>
            <div class="sec"></div>
          </div>
          <query-item
            v-for="(q, index) in dragTags"
            :id="q.id"
            :ref="q.id"
            :key="q.id"
            :itemData="q"
            :itemindex="index"
            @update:synonym="updateSynonym"
            @update="handleUpdate"
            @updateLabel="handleUpdateLabel"
            @combine="handleCombine"
            @detach="handleDetach"
            @onTouchstart="onTouchstart"
            @onTouchmove="onTouchmove"
            @onTouchend="onTouchend"
            @onTouchcancel="onTouchcancel"
          />
        </div>
      </div>
      <div>
        <div :class="['actions', milieu !== 'ios' ? 'actions-ios' : '']">
          <van-button plain @click="clear">取消</van-button>
          <!-- <van-button
            type="info"
            class="full-btn"
            @click="queryOld"
            plain
            v-show="btnShowOld"
            id="actionsBtnOld"
            >在结果中检索</van-button
          > -->
          <van-button type="info" class="full-btn" plain @click="queryOld">检索</van-button><van-checkbox shape="square" :value="checked" :change="onChange" @click="checkedc">分词检索</van-checkbox>
        </div>
        <div v-if="milieu === 'ios'" class="actions-iosbottom"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { filTagFmt } from '@/common/searchConst'
import QueryItem from './components/queryItem'
import CloneItem from './components/cloneItem'
import DragMirror from './components/dragMirror'

export default {
  name: 'search-dnd-comp',
  emit: ['update:synonym'],
  props: {
    searchType: {
      type: String,
      default: 'judgementSearch',
    },
    fixedTags: Array,
    keywordArr: Array,
    dragTags: Array,
    cloneItemList: Array,
    loadingData: Boolean,
  },
  components: {
    QueryItem,
    CloneItem,
    DragMirror,
  },
  data() {
    return {
      isDragging: false,
      isCombined: false,
      myshowarrstate: true,
      timer: null,
      // 动画展示次数
      animationTimes: 3,
      // 每次移动的像素
      stepPx: 1,
      milieu: '',
      animationEnum: {
        single: 1000,
        double: 1001,
      },
      showSingleAnimation: true,
      showDoubleAnimation: true,
      selp2show: false,
      btnShowOld: false,
      checked: false,
    }
  },
  computed: {
    ...mapState('dnd', ['dragMirrorPropStart', 'dragMirrorPropMove']),
    ...mapState('user', ['userInfo']),
    // 顶部组合标签数据
    topLabels() {
      const arr = []
      this.cloneItemList.forEach((list) => {
        const l = list
          .filter((item) => item.label)
          .map((item) => {
            return {
              name: item.label,
              value: item.key,
            }
          })
        arr.push(l)
      })
      return arr
    },
    canQuery() {
      if ((this.dragTags.length || this.fixedTags.length) && !this.loadingData) {
        return true
      } else {
        return false
      }
    },
    fTagFmt() {
      return filTagFmt
    },
    dpr() {
      return document.documentElement.getAttribute('data-dpr') || 1
    },
    ua() {
      let ua = navigator.userAgent
      if (ua !== this.$ua.Android && ua !== this.$ua.iOS) {
        ua = 'web'
      }
      return ua
    },
  },
  updated() {
    let numA = 0
    let numB = 0
    for (const i of this.dragTags) {
      if (i.color === true) {
        numA += 1
      } else if (i.color === false) {
        numB += 1
      }
    }
    for (const i of this.fixedTags) {
      if (i.color === true) {
        numA += 1
      } else if (i.color === false) {
        numB += 1
      }
    }
    if (numA > 0 && numB > 0) {
      this.btnShowOld = true
    } else {
      this.btnShowOld = false
    }
  },
  methods: {
    ...mapMutations('dnd', [
      'SET_DROP_DRAG',
      'SET_DRAG_SOURCE_DATA',
      'SET_POP_VISIBLE',
      'SET_DRAG_MIRROR_PROP_START',
      'SET_DRAG_MIRROR_PROP_MOVE',
      'SET_DRAG_MIRROR_PROP_END',
      'SET_ANIMATION_HINT_VISIBLE',
      'CLEAR_DRAG_STATE',
    ]),
    updateSynonym(val) {
      this.$emit('update:synonym', val)
    },
    fixedTagNameFmt(t) {
      return t.category ? `${t.category}:${t.value}` : t.value
    },
    fTagFmt2(t) {
      const value = this.fTagFmt(t)
      return value.split(':')
    },
    // 更新数据
    handleUpdate(payload) {
      this.$emit('update', payload)
    },
    // 融合标签
    handleCombine(payload) {
      console.log('search-dnd', payload)
      this.$emit('combine', payload)
    },
    deleteById(id) {
      this.$emit('delete', id)
    },
    /**
     * 分离标签
     *! 分离顺序 1.label 2.[value]拆分成单个 3.删除
     */
    handleDetach(payload) {
      payload.value.forEach((item, index) => {
        if (item instanceof Array) {
          item = item.filter((item, index) => {
            return index % 2 === 0
          })
          payload.value = item
        }
      })
      // console.log('还原数据', payload)
      this.$emit('detach', payload)
    },
    handleUpdateLabel(payload) {
      this.$emit('update', payload)
    },
    clear() {
      this.$emit('clear')
    },
    searchdndClickFn(e) {
      // console.log(e.target)
      const classname = e.target.className
      if (classname.indexOf('tag-btn') === -1 && classname.indexOf('van-icon') === -1) {
        this.$bus.$emit('closetagtext')
      }
      if (classname.indexOf('van-check') === -1 && classname.indexOf('vancheck') === -1 && classname.indexOf('van-icon-success') === -1) {
        // setTimeout(() => {
        this.$bus.$emit('closevshow')
        // }, 400)
        // e.preventDefault()
      }
    },
    // cloneItemClick(c) {
    //   console.log('点击了', c)
    //   console.log(this.dragTags)
    // },
    // 点击新搜索
    ...mapMutations('user', ['setUserInfo', 'setToken']),
    async query() {
      try {
        if (this.userInfo.memberStatus !== 1) {
          console.log('dnd')
          this.$axios
            .get(`${this.$base}/management/users/${this.userInfo.id}`)
            .then((res) => {
              if (res.data && res.data.data) {
                this.userInfo.memberStatus = res.data.data.memberStatus
                this.setUserInfo(this.userInfo)
                // this.setToken(res.data.data.token)
                if (res.data.data.memberStatus !== 1) {
                  this.$axios
                    .get(`${this.$base}/management/user/residue/analyze`)
                    .then((res) => {
                      if (res.data.code !== 200) {
                        return this.$notify(res.data.message)
                      }
                    })
                    .catch(() => {
                      return this.$notify('请充值会员或购买天数后使用')
                    })
                }
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }

        this.$emit('query', this.dragTags)
      } catch (err) {
        console.log(err)
      }
    },
    // 点击在结果中检索
    queryOld() {
      this.$emit('queryOld', this.dragTags)
    },
    // vant文档提供的方法点击无反应
    onChange(event) {
      this.setData({
        checked: event.detail,
      })
    },
    checkedc(event) {
      // console.log('点击了', event)
      this.checked = !this.checked
    },
    deleteFixed(tag) {
      this.$emit('deleteMe', tag)
    },
    // @query-item [touch event]
    onTouchstart(data) {
      this.isTouchMove = false
      this.CLEAR_DRAG_STATE()
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.SET_DRAG_MIRROR_PROP_START(data)
    },
    onTouchmove(data) {
      // console.log('onTouchmove', data)
      this.isTouchMove = true
      this.preventCtnScroll()
      this.SET_DRAG_MIRROR_PROP_MOVE(data)
    },
    onTouchend(data) {
      this.SET_DRAG_MIRROR_PROP_END(data)
      this.allowCtnScroll()
      // 如果不是拖动事件则视为点击事件 触发关键词替换
      if (!this.isTouchMove) {
        // TODO 完善同义词替换功能
        // this.$bus.$emit('updateSynonyms', '离婚')
      }
      this.$bus.$emit('dragDrop', data)
      this.isTouchMove = false
    },
    onTouchcancel(data) {
      this.SET_DRAG_MIRROR_PROP_END()
      this.allowCtnScroll()
    },
    // 计算容器尺寸
    calcWrapSize() {
      const wrap = document.querySelector('div.search-dnd-wrap')
      return wrap.getBoundingClientRect()
    },
    preventEvent(e) {
      e.preventDefault()
    },
    // 防止容器滚动
    preventCtnScroll() {
      document.body.addEventListener('touchmove', this.preventEvent, {
        passive: false,
      })
    },
    // 允许容器滚动
    allowCtnScroll() {
      document.body.removeEventListener('touchmove', this.preventEvent)
    },
    // 动画id定义为 1001-单标签动画 1002-双标签动画
    checkAnimationStatus(ua = 'web', animationId = 1000) {
      this.$axios
        .get(`${this.$base}/document/animation/select?type=${ua}&animationId=${animationId}`)
        .then((res) => {
          // console.log('checkAnimationStatus', res.data)
          const { code, data } = res.data
          if (Number(code) === 200) {
            if (data && data.animationNum >= 1) {
              if (animationId === 1000) {
                this.showSingleAnimation = false
              } else {
                this.showDoubleAnimation = false
              }
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // 更新动画状态
    updateAnimationStatus(ua = 'web', animationId = 1000) {
      const data = {
        animationId,
        type: ua,
      }
      this.$axios
        .post(`${this.$base}/document/animation/save`, data)
        .then((res) => {
          // console.log('updateAnimationStatus', res.data)
          this.checkAnimationStatus(ua, animationId)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    deteceMilieu() {
      const ua = window.navigator.userAgent
      // let app = window.navigator.appVersion
      // alert('浏览器版本: ' + app + '\n' + '用户代理: ' + ua)
      // console.log('ua', ua)
      if (ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        // ios端
        // console.log('ios端')
        return 'ios'
      } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
        // android端
        return 'android'
      }
      if (ua.match(/MicroMessenger/i) === 'MicroMessenger') {
        // 微信浏览器
        return 'vx'
      }
    },
    // query item touch event  --->
    animateSingleStart() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      const wrapSize = this.calcWrapSize()
      const fisrtTagGroupElem = this.$refs.cloneItemWrap.children[0]
      if (!fisrtTagGroupElem) {
        return
      }
      const fisrtTagElem = fisrtTagGroupElem.childNodes[0]
      if (fisrtTagElem) {
        const size = fisrtTagElem.getBoundingClientRect()
        const d = {
          type: 'clone',
          wrapSize,
          size,
          pos: {
            clientX: size.x,
            clientY: size.y,
          },
          text: fisrtTagElem.innerText,
        }
        this.SET_DRAG_MIRROR_PROP_START(d)
        this.SET_ANIMATION_HINT_VISIBLE(true)
        this.animateSingleMove(d)
      }
    },
    animateSingleMove(d) {
      if (!this.dragMirrorPropMove) {
        this.SET_DRAG_MIRROR_PROP_MOVE(d)
      }
      let i = 0
      const id = this.dragTags[0].id
      const target = document.getElementById(id)
      const targetSize = target.getBoundingClientRect()
      const distance = targetSize.y - d.pos.clientY + 10
      this.timer = setInterval(() => {
        if (this.dragMirrorPropMove && this.dragMirrorPropStart) {
          if (i >= this.animationTimes) {
            clearInterval(this.timer)
            i = 0
            this.SET_ANIMATION_HINT_VISIBLE(false)
            this.SET_DRAG_MIRROR_PROP_END()
            this.showSingleAnimation = false
          } else {
            const posM = this.dragMirrorPropMove.pos
            const { pos } = this.dragMirrorPropStart
            if (posM && pos && posM.clientY - pos.clientY < distance) {
              const y = posM.clientY + this.stepPx * this.dpr
              const move = {
                ...d,
                pos: {
                  clientX: pos.clientX,
                  clientY: y,
                },
              }
              this.SET_DRAG_MIRROR_PROP_MOVE(move)
            } else {
              i++
              this.SET_DRAG_MIRROR_PROP_MOVE(d)
            }
          }
        } else {
          clearInterval(this.timer)
          i = 0
          this.SET_DRAG_MIRROR_PROP_END()
        }
      }, 17)

      setTimeout(() => {
        this.updateAnimationStatus(this.ua, this.animationEnum.single)
      }, 4000)
    },
    animateBetweenTwoTags() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.animateBetweenTwoTagsStart()
    },
    animateBetweenTwoTagsStart() {
      const id = this.dragTags[1].id
      const ref = this.$refs[id] && this.$refs[id][0]
      if (ref) {
        const tagBtn = ref.$refs.tagBtn
        const size = tagBtn.getBoundingClientRect()
        const d = {
          type: 'drag',
          wrapSize: this.calcWrapSize(),
          size,
          pos: {
            clientX: size.x,
            clientY: size.y,
          },
          text: ref.tagText,
        }
        this.SET_ANIMATION_HINT_VISIBLE(true)
        this.SET_DRAG_MIRROR_PROP_START(d)
        this.SET_DROP_DRAG(true)
        ref.isMeDragging = true
        this.animateBetweenTwoTagsMove(d)
      }
    },
    animateBetweenTwoTagsMove(d) {
      if (!this.dragMirrorPropMove) {
        this.SET_DRAG_MIRROR_PROP_MOVE(d)
      }
      let i = 0
      const id = this.dragTags[0].id
      const ref = this.$refs[id][0].$refs.tagBtn
      const targetSize = ref.getBoundingClientRect()
      const distance = d.pos.clientX - targetSize.x - targetSize.width / 2 + 10
      this.timer = setInterval(() => {
        if (this.dragMirrorPropMove && this.dragMirrorPropStart) {
          if (i >= this.animationTimes) {
            clearInterval(this.timer)
            i = 0
            this.SET_DROP_DRAG(false)
            this.SET_ANIMATION_HINT_VISIBLE(false)
            this.SET_DRAG_MIRROR_PROP_END()
            this.showDoubleAnimation = false
          } else {
            const posM = this.dragMirrorPropMove.pos
            const { pos } = this.dragMirrorPropStart
            if (posM && pos && Math.abs(pos.clientX - posM.clientX) < distance) {
              const move = {
                ...d,
                pos: {
                  clientX: posM.clientX - this.stepPx * this.dpr,
                  clientY: posM.clientY - 2 * parseFloat(targetSize.height / targetSize.width),
                },
              }
              this.SET_DRAG_MIRROR_PROP_MOVE(move)
            } else {
              i++
              this.SET_DRAG_MIRROR_PROP_MOVE(d)
            }
          }
        } else {
          clearInterval(this.timer)
          i = 0
          this.SET_DROP_DRAG(false)
          this.SET_DRAG_MIRROR_PROP_END()
        }
      }, 17)

      setTimeout(() => {
        this.updateAnimationStatus(this.ua, this.animationEnum.double)
      }, 4000)
    },
    // 检查是否要显示拖拽提示动画
    checkShouldShowAnimation() {
      this.checkAnimationStatus(this.ua, this.animationEnum.single)
      this.checkAnimationStatus(this.ua, this.animationEnum.double)
    },
  },
  created() {
    this.checkShouldShowAnimation()
    if (this.deteceMilieu() === 'ios') {
      this.milieu = 'ios'
    }
  },
  mounted() {},
  watch: {
    dragTags: {
      handler(nv) {
        if (nv) {
          // console.log('drag tags 改变', nv)
          const len = nv.length
          const duration = 400
          if (len === 0) {
            this.SET_DRAG_MIRROR_PROP_END()
          } else if (len === 1 && this.showSingleAnimation) {
            this.checkAnimationStatus()
            if (this.showSingleAnimation) {
              setTimeout(() => {
                this.animateSingleStart()
              }, duration)
            }
          } else if (len === 2 && this.showDoubleAnimation) {
            if (this.showDoubleAnimation) {
              setTimeout(() => {
                this.animateBetweenTwoTags()
              }, duration)
            }
          }
          // 在结果中检索状态 蓝灰都有 显示
          let numA = 0
          let numB = 0
          for (const i of nv) {
            if (i.color === true) {
              numA += 1
            } else if (i.color === false) {
              numB += 1
            }
          }
          for (const i of this.fixedTags) {
            if (i.color === true) {
              numA += 1
            } else if (i.color === false) {
              numB += 1
            }
          }
          if (numA > 0 && numB > 0) {
            this.btnShowOld = true
          } else {
            this.btnShowOld = false
          }
        }
      },
      deep: true,
    },
    checked(ov) {
      this.$bus.$emit('mohu-checked', ov)
    },
  },
}
</script>

<style lang="stylus">
.search-dnd-wrap
  position relative
  width 100%
  user-select none
  cursor pointer
  & .search-dnd-comp
    position relative
    width 100%
    margin 0px auto
    & .search-dnd-comp__banner
      & .search-dnd-comp__banner-list
        display flex
        justify-content flex-start
        margin-top 5px
        flex-wrap wrap
        & .clone-item
          margin-bottom 2px
          background #efefef
        &:nth-child(1)
          & .clone-item
            background #ffffff
    & .flex-area
      position relative
      & .query-tag__wrap
        margin 5px 0
        display flex
        align-items center
        min-height 100px
        flex-direction row
        flex-wrap wrap
        justify-content flex-start
        & .query-item
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
          &:nth-last-child(1)
            margin-right 0
          & .tag-btn
            padding 0 0px
    .actions
      display flex
      width 96%
      bottom 20px
      & button
        margin 0 4px
      & .full-btn
        flex 1
</style>
<style scoped>
.actions > .van-radio-group > .van-radio {
  height: 100%;
  /* border: 1px solid #f0f1f4; */
}
.actions > #actionsBtnOld {
  flex: 2;
}
.van-checkbox > ::v-deep.van-checkbox__label {
  /* width: 140px; */
  font-size: 0.373333rem;
}
.actions-ios {
  margin-bottom: 65px;
}
.actions-iosbottom {
  width: 100%;
  height: 65px;
}
::v-deep.search-dnd-comp > .search-dnd-comp__banner > .search-dnd-color .clone-item:nth-child(-n + 8) {
  background: #f2f2f2;
}
::v-deep.search-dnd-comp > .search-dnd-comp__banner > .search-law-color:nth-child(1) > .clone-item {
  background: #f2f2f2;
}
::v-deep.selp2-btdiv {
  display: none;
}
::v-deep.search-dnd-comp > .search-dnd-comp__banner > .search-law-color:not(:nth-child(1)) > .clone-item {
  background: #fff;
  touch-action: none;
  pointer-events: auto;
  user-select: none;
}

::v-deep.search-dnd-comp > .search-dnd-comp__banner > .search-ledge-color > .clone-item {
  background: #f2f2f2 !important;
}
</style>
<style>
input,
textarea {
  user-select: auto; /*webkit浏览器*/
  margin: 0px;
  padding: 0px;
  outline: none;
}
.selp2-btdiv {
  height: 32px;
  width: 35px;
  line-height: 32px;
  text-align: center;
  color: #666;
  background: #f2f2f2;
  user-select: none;
  border: 1px solid #ccc;
  cursor: grab;
}
</style>
