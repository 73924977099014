<template>
  <div v-if="isShowTip" class="version_tip">本手机系统版本较老，请升级ios版本</div>
</template>

<script>
export default {
  name: 'version-tip',
  data() {
    return {
      isShowTip: false,
    }
  },

  methods: {
    // 手机端判断各个平台浏览器及操作系统平台
    checkPlatform() {
      var ua = navigator.userAgent.toLowerCase()
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        const test = /os\s([\w_]+)/
        const match = test.exec(ua)
        const version = match[1].split('_')[0]
        this.isShowTip = parseInt(version) < 13
      }
      // if (/android/i.test(navigator.userAgent)) {
      //   const test = /android\s([\w.]+)/
      //   var match = test.exec(ua)
      //   var version = match[1].split('.')[0]
      //   return version < 10
      // }
    },
  },
  mounted() {
    const ua = window.navigator.userAgent
    if (ua === 'fb-iOS-Webview') {
      const clientType = this.$store.state.user.version
      if (clientType) {
        const { version } = JSON.parese(clientType)
        this.isShowTip = parseInt(version) < 13
      }
    }
    if (ua === 'fb-flutter-Webview') {
      const clientType = this.$store.state.user.version
      if (clientType) {
        const { version } = JSON.parese(clientType)
        this.isShowTip = parseInt(version) < 13
      }
    } else {
      this.checkPlatform()
    }
  },
}
</script>

<style lang="stylus" scoped>
.version_tip
  width 100vw
  height 40px
  line-height 40px
  background orange
  text-align center
  align-items center
  color #fff
</style>
