<template>
  <div class="recommendTag">
    <div class="" style="text-align: center">
      使用精确搜索未匹配到相应数据。请<span
        @click="$emit('selectClick')"
        style="color: #409eff"
        >调整关键词或逻辑关系</span
      >。
    </div>
    <div v-if="!loading && fenciData.length" class="fenci">
      <p style="margin-bottom: 4px">律呗推荐</p>
      <div class="fenci-list">
        <div
          v-for="(item, index) in fenciData.filter((_, index) => index < 9)"
          :key="index + 'fenci'"
          class="fenci-item"
        >
          <div
            v-for="(itemb, indexb) in item.value"
            :key="indexb + 'fencib'"
            type="info"
            @click="useRecommendTag(itemb)"
            size="large"
            class="fenci-list-button"
          >
            <div class="">
              <span v-show="item.label">{{ item.label }}:</span>
              <p
                class="leading-tight text-left"
                style="white-space: normal; height: auto"
              >
                {{ itemb.case_name || itemb.title }}
                <span v-if="itemb.name_chinese" style="margin-left: 6px">{{
                  itemb.name_chinese
                }}</span>
              </p>
              <p v-if="itemb.case_id">
                {{ itemb.case_id }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { genID } from '@/common/searchConst'
export default {
  name: 'recommend-tag',
  emits: ['goLawDetil', 'selectClick', 'goJudgementDetil'],
  props: {
    // 关键词列表
    keywords: {
      type: Array,
      default: () => [],
    },
    // 检索类型
    contentType: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      loading: false,
      fenciData: [],
      isFirst: true,
    }
  },
  computed: {},
  methods: {
    // 组件初始化的时候处理
    async handleInitComp() {
      // 获取推荐数据
      const kw = this.keywords
      const len = kw.length
      // console.log(this.keywords)
      if (len > 0) {
        this.loading = true
        const data = await this.getTagByFenci(kw.map((r) => r.trim()))
        this.loading = false
        this.fenciData = []
        if (data.code === 200 && data.data) {
          data.data.data.forEach((ele) => {
            this.fenciData.push({
              id: genID(),
              label: '',
              pattern: '',
              interval: null,
              value: [{ ...ele }],
            })
          })
        } else {
          this.fenciData = []
        }
      }
    },
    // 获取推荐的标签
    async getTagByFenci(keywords) {
      const params = { keywords }
      if (this.contentType === 'judgementSearch') {
        params.index_type = 'precedents'
      } else if (this.contentType === 'lawSearch') {
        params.index_type = 'laws_new'
      }
      const { data } = await this.$axios.post(`${this.$pydataBase}/search/fenci`, params)
      console.log(data)
      return data || {}
    },
    useRecommendTag(item) {
      const payload = {}
      if (this.contentType === 'lawSearch') {
        payload.law_id = item.law_id
        payload.catlog = item.name_chinese
        this.$emit('goLawDetil', payload)
      } else if (this.contentType === 'judgementSearch') {
        payload.docid = item.doc
        this.$emit('goJudgementDetil', payload)
      }
      // console.log('子goDetil', payload)
    },
    // 使用所有推荐标签进行新的检索
    useAllTagMakeNewQuery() {
      window.sessionStorage.setItem('clearTag', 'true')
      this.$bus.$emit('search-key', {
        clear: true,
        click: true,
      })
    },
  },
  async created() {
    // console.log('111keywords', this.keywords)
    // this.handleInitComp()
  },
  watch: {
    // keywords(nv) {
    //   console.log('子组件', nv)
    //   if (nv && nv.length) {
    //     this.handleInitComp()
    //   }
    // },
    keywords: {
      async handler(nv) {
        if (nv && nv.length) {
          await this.handleInitComp()
        }
      },
      immediate: true,
    },
  },
}
</script>
<style scoped lang="stylus">
.fenci
  padding 10px
  .fenci-list-button
    background-color: #ccc
    padding: 10px
    border-radius: 4px
    margin-top: 8px
</style>
