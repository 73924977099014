<template>
  <div
    :class="{ 'pattern-item': true, active }"
    @drop="onDropCorner"
    @dragover="onOverCorner"
    @dragleave="onLeaveCorner"
  >
    {{ label }}
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'pattern-item',
  props: {
    label: String,
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations('dnd', ['SET_POP_VISIBLE']),
    onOverCorner(e) {
      e.preventDefault()
      e.target.classList.add('drag-over-exact')
      const payload = { label: this.label, event: e }
      this.$emit('xdragover', payload)
    },
    onLeaveCorner(e) {
      if (e.target.classList.contains('drag-over-exact')) {
        e.target.classList.remove('drag-over-exact')
      }
      const payload = { label: this.label, event: e }
      this.$emit('xdragleave', payload)
    },
    onDropCorner(e) {
      if (e.target.classList.contains('drag-over-exact')) {
        e.target.classList.remove('drag-over-exact')
      }
      const p = this.getDragTransferData(e)
      // 只处理标签融合的情况
      if (p && p.type === 'combine' && !p.data.pattern) {
        const payload = { pattern: this.label, event: e, source: p.data }
        this.$emit('xdragdrop', payload)
      }
      setTimeout(() => {
        this.SET_POP_VISIBLE(true)
      }, 500)
    },
    getDragTransferData(e) {
      const data = e.dataTransfer.getData('text/plain')
      if (data) {
        return JSON.parse(e.dataTransfer.getData('text/plain'))
      } else {
        return null
      }
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="stylus" scoped>
.pattern-item
  height 49px
  text-align center
  line-height 49px
  flex 1
  border 1px solid #cccccc
  font-size 13px
  user-select none
  &.active
    background rgba(103, 194, 58, 0.4)
  &.drag-over-exact
    background rgba(103, 194, 58, 0.4)
    border 1px solid #67C23A
</style>
