<template>
  <div class="content_search" id="searchpage" ref="search">
    <NoticeBar text="智拾法律数据库（律呗版）" left-icon="volume-o" v-if="isZH && !isCloseNotify" mode="closeable" @close="closeNoticeBar" />
    <iosVersionTip />
    <van-popup v-model="mainSearchVisible" position="right" :style="{ height: '100%', width: '100%' }" class="mainsearch-hull" :lazy-render="false">
      <!-- <div v-show="mainSearchVisible" class="mainsearch-hull"> -->
      <main-search ref="mainSearch" :keywordArr="keywordsArr[active]" :queryCache="queryPayload" @update:synonym="updateSynonym" @toLawDetail="openLawDetail" @cancel="onSearchCancel" />
      <!-- </div> -->
    </van-popup>
    <van-popup v-model="filterVisible" position="right" :style="{ height: '100%' }" :lazy-render="false">
      <div class="filter-box">
        <div class="filter" v-for="item in filterList" :key="item.value" :style="item.value === sort ? 'color:#1989FA' : ''" @click.stop="setSort(item.value)">
          {{ item.text }}
        </div>
      </div>
    </van-popup>
    <van-tabs v-model="active" :title-active-color="color" class="search-nav-tab" :swipeable="swipeableShow">
      <van-tab v-for="item in searchClassifyShow" :key="item.key" :name="item.key" :title="item.name">
        <div class="mock-input-wrap">
          <div class="mock-input" @click.stop="onMockInputFocus">
            <div class="icon1" v-show="keywordsArr[active].length" @mousedown.stop="" @click.stop="ShowFilter">
              <i class="van-icon van-icon-filter-o"></i>
            </div>
            <div class="mock-input__key-word">
              <span v-for="item in calcFilterTag(active)" :key="item.key" class="keywords_fixed"> {{ item.label ? item.label + ':' : '' }}{{ item.value.join('、') }} </span>
              <span v-for="item in keywordsArr[active]" :key="item" class="keyword-span hl-ignore" v-html="item"> </span>
            </div>

            <div class="icon" @click.stop="openFilter">
              <!-- <div class="icon" @click.stop="ShowFilter"> -->
              <van-icon name="search" v-if="active === 'ledgeSearch'" />
              <i class="iconfont icon-paixu2" v-else />
              <!-- <van-icon name="wap-nav" /> -->
            </div>
          </div>
        </div>
        <div class="tab-content-wrap" id="list-page-highlight-area">
          <div v-if="isNetError" class="net-error">
            <span>检测到网络波动，</span>
            <span class="text-btn" @click="reload">点击刷新</span>
          </div>
          <search-list
            v-else-if="item.key === active"
            ref="searchList"
            :loading="loading"
            :listData="currentList"
            :loadingFull="loadingFull"
            :loadingMore="loadingMore"
            :conditions="conditions"
            :contentType="active"
            :sort="sort"
            :keywordsArr="keywordsArr"
            :isNext="isNext"
            @setSort="setSort"
            @onMockInputFocus="onMockInputFocus"
            @next="next"
            @collect="handleCollect"
            @toDetail="toDetail"
            @toLawDetail="toLawDetail"
          />
        </div>
      </van-tab>
    </van-tabs>

    <div class="nav" id="nav" v-if="detailshow.flag">
      <percentDetail
        :id="detailshow.case_id"
        :words="detailshow.words"
        :titleName="detailshow.name"
        :toCatloag="detailshow.toCatloag"
        @changeflag="changeshow"
        @highlight="handleHighlight"
      ></percentDetail>
    </div>
    <div v-if="showReport" class="nav collect-report">
      <searchReport @close="closeReport" from="search" />
    </div>

    <treeSelect v-model="TreeSelect" v-if="Object.keys(TreeSelect.data).length > 0" :keywords="save_keyword" @search="pushFilterCondition" @delData="delData"></treeSelect>

    <van-popup v-model="lawDetailPopupVisible" get-container="#outter" position="right" :style="{ height: '100%' }">
      <law-detail :entry="lawDetailProp" :miniuserInfo="miniuserInfo" @back="closeLawDetailPopup" />
    </van-popup>
    <div class="app-download-tip" v-if="isPublicRouteDetail">
      <download-app />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import MainSearch from '@/components/main-search'
import SearchList from './tabs/searchList'
// import lawDetail from './lawDetail/index'
import percentDetail from '../../precedentDetail'

import treeSelect from '../compoments/treeSelect'
import taggleBottomMixin from '../taggleBottomMixin.js'
import Intro from './tabs/introConfig'

import LawDetail from '@/pages/BigData/pages/law'
import iosVersionTip from '../compoments/iosVersionTip'

import { baseURL } from '@api-config'
import { NoticeBar } from 'vant'
export default {
  name: 'search-page',
  mixins: [taggleBottomMixin],
  components: {
    searchReport: () => import('@/pages/transform/searchReport'),
    iosVersionTip,
    // lawDetail,
    LawDetail,
    percentDetail,
    treeSelect,
    MainSearch,
    SearchList,
    NoticeBar,
  },
  data() {
    return {
      isNext: true,
      loading: true,
      // 网络错误
      isNetError: false,
      filterVisible: false, // 筛选弹框
      filterList: [
        {
          text: '推荐排序',
          value: 0,
        },
        {
          text: '常规排序',
          value: 1,
        },
        {
          text: '裁判时间',
          value: 2,
        },
        {
          text: '法院层级',
          value: 3,
        },
      ],
      judgementFilter: [
        {
          text: '推荐排序',
          value: 0,
        },
        {
          text: '常规排序',
          value: 1,
        },
        {
          text: '裁判时间',
          value: 2,
        },
        {
          text: '法院层级',
          value: 3,
        },
      ],
      lawFilter: [
        {
          text: '推荐排序',
          value: 0,
        },
        {
          text: '常规排序',
          value: 1,
        },
        {
          text: '实施时间',
          value: 4,
        },
        {
          text: '效力级别',
          value: 6,
        },
      ],
      criterionFilter: [
        {
          text: '推荐排序',
          value: 0,
        },
        {
          text: '常规排序',
          value: 1,
        },
        {
          text: '裁判日期倒序',
          value: 2,
        },
        {
          text: '裁判日期正序',
          value: 3,
        },
        {
          text: '法院层级倒序',
          value: 4,
        },
        {
          text: '法院层级正序',
          value: 5,
        },
      ],
      // 侧边栏展示数据
      conditions: {
        judgementSearch: [],
        lawSearch: [],
        ledgeSearch: [],
        criterionSearch: [],
      },
      // 搜索条件所需参数
      datalist: {
        judgementSearch: [],
        lawSearch: [],
        criterionSearch: [],
      },
      // 页面展示数据
      screenList: {
        judgementSearch: [],
        lawSearch: [],
        criterionSearch: [],
      },
      keywordsArr: {
        judgementSearch: [],
        lawSearch: [],
        ledgeSearch: [],
        criterionSearch: [],
      },
      // 检索词
      searchText: '',
      // 检索组件显示开关
      mainSearchVisible: false,
      // 检索结果的列表数据
      listData: {
        judgementSearch: [],
        lawSearch: [],
        ledgeSearch: [],
        criterionSearch: [],
      },
      // 页面内容类型
      contentType: 'judgementSearch',
      loadingFull: false,
      loadingMore: false,
      // 当前查询的携带的所有数据
      queryPayload: {
        judgementSearch: null,
        lawSearch: null,
        ledgeSearch: null,
        criterionSearch: null,
      },
      // 页码
      offset: {
        judgementSearch: 1,
        lawSearch: 1,
        ledgeSearch: 1,
        criterionSearch: 1,
      },
      showReport: false,
      // 排序方式
      sort: 0,
      hideIpt: false,
      words: [],
      TreeSelect: {
        flag: false,
        data: {},
        title: '',
      },
      swipeableShow: true,
      preventSearch: true,
      detailshow: {
        show: false,
        case_id: null,
        title: null,
        flag: false,
        showIpt: false,
        catlog: null,
        name: null,
        section: null,
      },
      save_keyword: [],
      hideControl: false,
      keywords: '',
      color: '#409EFF',
      active: 'judgementSearch',
      searchClassifyShow: [
        { name: '判例', index: '0', key: 'judgementSearch' },
        { name: '法律', index: '1', key: 'lawSearch' },
        { name: '知识', index: '2', key: 'ledgeSearch' },
        { name: '标准', index: '3', key: 'criterionSearch' },
      ],
      mustshow: {
        0: true,
        1: true,
        2: true,
      },
      Filterparas: {
        0: {},
        1: {},
      },
      filterstr: {
        start: 0,
        end: 0,
      },
      filter_condition: null,
      searchPatter: 0,
      // 法律详情页弹框
      lawDetailPopupVisible: false,
      lawDetailProp: {},
      synonymsList: [],
      miniuserInfo: {},
    }
  },
  computed: {
    ...mapState('search', ['searchVideoFlag']),
    ...mapState('user', ['userInfo']),
    ...mapState('dnd', ['synonymList']),
    ...mapGetters('user', ['userId', 'isValid']),
    isPublicRouteDetail() {
      return this.$route.path.startsWith('/searchkeepalive/')
      // return true
    },
    isZH() {
      return JSON.parse(sessionStorage.getItem('userInfo'))?.thirdPartySource === 'ZH'
    },
    isCloseNotify() {
      return localStorage.getItem('noticeBar')
    },
    currentQueryCondition() {
      return this.queryPayload[this.active] && this.queryPayload[this.active].data
    },
    currentQueryTags() {
      return this.queryPayload[this.active] && this.queryPayload[this.active].tag ? this.queryPayload[this.active].tag : []
    },
    currentList() {
      return this.listData[this.active]
    },
    // 调用接口需要的参数集合
    queryParams() {
      let p = {
        offset: this.offset[this.active] || 1,
        sort: this.sort,
      }
      if (this.currentQueryCondition) {
        p = { ...p, ...this.currentQueryCondition }
      }
      if (this.filter_condition && this.active !== 'ledgeSearch') {
        p.filter_condition = this.filter_condition
      }
      if (this.userId) {
        p.user_id = this.userId.toString()
      }
      if (this.linkedCaseId) {
        p.case_id = this.linkedCaseId
      }
      return p
    },
    highlightKeywords() {
      if (this.currentQueryCondition) {
        const { filter_condition: f, loc_condition: l, query_condition: q } = this.currentQueryCondition
        let arr = []
        if (f && f.length) {
          f.forEach((elem) => {
            const { value } = elem
            if (value && value.length) {
              let p = []
              value.forEach((item) => {
                if (item.indexOf('|') !== -1) {
                  p = [...p, ...item.split('|')]
                } else {
                  p.push(item)
                }
              })
              arr = [...arr, ...p]
            }
          })
        }
        if (l && l.length) {
          l.forEach((elem) => {
            const { value } = elem
            if (value && value.length) {
              arr = [...arr, ...value]
            }
          })
        }
        if (q && q.length) {
          arr = [...arr, ...q]
        }
        return [...new Set(arr)]
      } else {
        return null
      }
    },
  },
  methods: {
    ...mapMutations('search', ['SET_SEARCHVIDEOFLAG']),
    ...mapMutations('searchReport', ['SET_CLEAR_CHECK', 'SET_SEARCH_COLLECTION_ID']),
    ...mapActions('searchReport', [
      // 'initStampReport',
      'getStampReport',
      'setStampReport',
    ]),
    ...mapMutations('nonmemberLimit', ['SET_VALID']),
    ...mapMutations('user', ['setIsLogin', 'setToken', 'setUserInfo', 'setUserinfo']),
    closeNoticeBar() {
      localStorage.setItem('noticeBar', '1')
    },
    // 第三方登录
    async thirdPartyLogin() {
      // 切割url
      const decodedString = decodeURIComponent(location.search.slice(1)).replaceAll(' ', '+')

      const params = {}
      decodedString.split('&').forEach((param) => {
        const [key, value] = param.split('=')
        params[key] = value
      })
      const { phone, sign, thirdPartySource } = params
      if (phone && sign && thirdPartySource) {
        const body = {
          phone: phone + '=',
          sign: sign + '=',
          thirdPartySource,
        }
        this.$axios
          .post(`${baseURL}/farbun/user/thirdPartyLogin`, body, {
            headers: {
              'Terminal-Type': 'ANDROID',
            },
          })
          .then(async ({ data: { code, message, data } }) => {
            console.log(data, 'data')
            if (code === 200) {
              this.isLoading = false
              this.setIsLogin(true)
              this.setToken(data.token)
              //  登录成功提示
              this.$notify({
                message: '登录成功',
                duration: 1000,
                background: '#1989fa',
              })
              sessionStorage.setItem('thirdParty', 1)

              const res = await this.$axios.get(`${baseURL}/management/user/${data.user.id}/info`)
              let userInfo = data.user
              if (res.data.code === 200) {
                userInfo = {
                  ...userInfo,
                  ...res.data.data,
                }
                userInfo.memberStatusList.map((i) => {
                  if (i.memberStatus && i.memberType === 'BIGDATA') {
                    data.user.memberStatus = 1
                    data.user.restNum = 999
                    data.user.isValid = true
                    userInfo.restNum = 999
                    userInfo.isValid = true
                    userInfo.memberStatus = 1
                  }
                })
              }
              this.setUserInfo(data.user)
              this.setUserinfo(data.user)
              // 将用户信息存储到 sessionStorage
              const ss = window.sessionStorage
              ss.setItem('token', data.token)
              ss.setItem('userInfo', JSON.stringify(userInfo))

              setTimeout(() => {
                this.$router.push('/search')
              }, 500)
            } else {
              this.$notify({
                message,
                duration: 1000,
                background: '#f44a53',
              })
            }
          })
      }
    },
    updateSynonym(val) {
      const i = this.synonymsList.findIndex((item) => item.text === val.text)
      if (i === -1) {
        this.synonymsList.push(val)
      } else {
        this.synonymsList.splice(i, 1, val)
      }
    },
    dealWithQuery() {
      const { s } = this.$route.query
      if (s) {
        const con = JSON.parse(decodeURI(s))
        const { type, data } = con
        const validType = this.searchClassifyShow.find((item) => item.key === type)
        if (validType) {
          this.active = type
          this.$nextTick(() => {
            this.$bus.$emit('makeQueryByPayload', data)
          })
        }
      }
    },

    handleCollect() {
      this.SET_CLEAR_CHECK(false)
      this.showReport = true
    },
    closeReport() {
      this.showReport = false
      if (this.$refs.searchList[0]) {
        this.$refs.searchList[0].initReportData()
      }
    },
    colseFilter() {
      console.log('colsefilter')
      this.queryData(this.active)
    },
    // 打开右侧
    openFilter() {
      if (this.active === 'ledgeSearch') {
        return
      }
      this.filterVisible = true
    },
    setSort(sort) {
      this.sort = sort
      this.filterVisible = false
      console.log('setsort')
      this.colseFilter()
    },
    lookFn(res) {
      console.log('res====--', res)
    },
    // 推送筛选数据
    pushFilterCondition(payload, query = true) {
      console.log('推送数据', payload)
      const param = {
        check: true,
        query: query,
        data: payload,
      }
      this.$bus.$emit('pushSearchCondition', param)
    },
    // 点击筛选按钮中的确定  添加列表
    addkeywords(filterCondition) {
      filterCondition.forEach((item) => {
        // 调用接口需要的参数
        this.datalist[this.active].push(item)
        // 搜索框中显示内容
        this.screenList[this.active].push(item.value[0])
      })
      // 数组去重方法
      this.screenList[this.active] = Array.from(new Set(this.screenList[this.active]))
      console.log(this.screenList[this.active])

      // this.screenList[this.active] = this.unique(this.screenList[this.active])
      // 数组对象去重方法
      this.datalist[this.active] = this.arrObj(this.datalist[this.active])
      // 存入筛选条件中
      this.filter_condition = this.datalist[this.active] || []
    },
    // 数组对象去重方法
    arrObj(data) {
      const obj = {}
      const pone = data.reduce((cur, next) => {
        // eslint-disable-next-line no-unused-expressions
        obj[next.id] ? '' : (obj[next.id] = true && cur.push(next))
        return cur
      }, []) // 设置cur默认类型为数组，并且初始值为空的数组
      return pone
    },
    introFn() {
      // if (!localStorage.getItem('isShowHelp')) {
      // 配置
      Intro.setOptions({
        steps: [
          {
            element: '#step1', // 目标元素
            intro: '班次管理，配置上下班时间及各种人性化设置。', // 提示文本
            position: 'top', // 提示位置
          },
          {
            element: '#step2',
            intro: '考勤组管理，设置考勤方式、考勤时间等考勤规则。邀请人功能在这里。',
            position: 'top',
          },
          {
            element: '#step3',
            intro: '考勤统计，随时可查看团队每日/月出勤情况。支持修改打卡结果。',
            position: 'top',
          },
          {
            element: '#step4',
            intro: '核销统计，可查看修改打卡结果的历史记录。',
            position: 'top',
          },
        ],
      })
      this.$nextTick(() => {
        // 开始
        Intro.start()
      })
      // 退出引导回调函数
      Intro.onexit(function () {
        localStorage.setItem('isShowHelp', 1)
      })
      // }
    },
    // 数组去重方法
    unique(arr) {
      if (!Array.isArray(arr)) {
        console.log('type error!')
        return
      }
      var array = []
      for (var i = 0; i < arr.length; i++) {
        if (array.indexOf(arr[i]) === -1) {
          array.push(arr[i])
        }
      }
      return array
    },
    // 点击筛选按钮中的取消  清空列表
    delData(filterCondition) {
      this.screenList[this.active] = []
      this.filter_condition = filterCondition
    },
    // 切换至指定类型tab
    switchToTab(searchType) {
      this.active = searchType
    },
    // 获取参数并返回关键词标签 tags数组一般通过queryPayload.tag拿到
    calcKeyword(tags) {
      console.log('calc key word', tags)
      const arr = []
      this.keywordsArr[this.active] = tags
      tags.forEach((item) => {
        let keywords1 = ''
        if (item.label !== '') {
          keywords1 += item.label + ':'
        }
        if (item.value.length > 1 && item.pattern !== '') {
          if (item.pattern === '同句') {
            keywords1 += '['
            item.value.forEach((val) => {
              keywords1 += val + '、'
            })
            keywords1 = keywords1.substr(0, keywords1.length - 1)
            keywords1 += ']'
          }
          if (item.pattern === '同段') {
            keywords1 += '{'
            item.value.forEach((val) => {
              keywords1 += val + '、'
            })
            keywords1 = keywords1.substr(0, keywords1.length - 1)
            keywords1 += '}'
          }
          if (item.pattern === '或者') {
            item.value.forEach((val) => {
              keywords1 += val + '|'
            })
            keywords1 = keywords1.substr(0, keywords1.length - 1)
          }
          if (item.pattern === '不包含') {
            item.value.forEach((val) => {
              keywords1 += val + '-'
            })
            keywords1 = keywords1.substr(0, keywords1.length - 1)
          }
        } else if (item.pattern === '' && item.value.length > 0) {
          item.value.forEach((val) => {
            keywords1 += val + ' '
          })
        } else if (item.value.length > 0) {
          item.value.forEach((val) => {
            keywords1 += val + ' '
          })
          keywords1 = keywords1.replace(/,/g, '')
        }
        console.log('keywords1===', keywords1)
        arr.push(keywords1)
      })
      return arr
    },
    show(index, flag) {
      this.mustshow[index] = flag
    },
    // 判例筛选列表
    showFilter(params, title, name) {
      console.log('showfilter----')
      if (!params) {
        params = {}
      }
      this.Filterparas[name] = {
        title: title,
        data: params,
      }
    },
    run(len) {
      if (len === 0) {
        this.hideControl = false
      } else {
        this.hideControl = true
      }
    },
    // 侧边栏
    ShowFilter() {
      const treeList = this.conditions[this.active]
      if (!treeList || !treeList.length) {
      }
      const title = []
      treeList.forEach((item) => {
        title.push(item.title)
      })
      this.TreeSelect.flag = true
      this.TreeSelect.data = treeList
      this.TreeSelect.title = title
    },
    changeflag() {
      this.preventSearch = true
      this.detailshow.show = false
    },
    changeshow() {
      this.preventSearch = true
      this.detailshow.flag = false
    },
    // 模拟输入框聚焦时
    onMockInputFocus() {
      const searchType = this.active
      this.$refs.mainSearch.onSearchTypeChange(searchType)
      this.openMainSearchPopup()
    },
    // 显示检索组件
    openMainSearchPopup(t) {
      if (t && this.searchClassifyShow.find((item) => item.key === t)) {
        this.$refs.mainSearch.onSearchTypeChange(t)
      }
      this.filterVisible = false
      this.mainSearchVisible = true
    },
    // 隐藏检索组件
    closeMainSearchPopup() {
      this.mainSearchVisible = false
    },
    /** #检索组件# 取消按钮点击触发
     ** 有标签时，点取消，关闭弹框
     ** 无标签时，点取消，重新使用空的条件搜索
     */
    onSearchCancel(isTagEmpty) {
      if (isTagEmpty) {
        this.resetConditionAndQueryData(this.active)
        this.closeMainSearchPopup()
      } else {
        this.closeMainSearchPopup()
      }
    },
    // 重置检索条件和状态并重新发起检索
    resetConditionAndQueryData(type) {
      // 是第一页且标签为空有数据
      const isFirstPageAndWithNoConditionData = this.offset[type] === 1 && this.listData[type].length && !this.keywordsArr[type].length
      if (!isFirstPageAndWithNoConditionData) {
        this.queryPayload[type] = {}
        this.keywordsArr[type] = []
        this.offset[type] = 1
        console.log('resetconditionandquerydata')
        this.queryData(type)
      }
    },
    // 处理eventBus发送的检索事件
    /* mainSearch组件触发的检索事件
    ! 传过来的数据和通过eventBus发送的是不一致的
    @mainSearch 传的数据是dragTags数组
    @eventBus 传递的.data是检索接口需要的参数 .tag是dragTags数组
    */
    async handleEmitQuery(payload) {
      const newpayload = JSON.parse(JSON.stringify(payload))
      const { type, tag } = newpayload
      this.queryPayload[type] = newpayload
      this.queryParams.search_word = []
      // if (tag.length > 0) {
      //   tag.forEach(item => {
      //     if (item.value.length === 1 && !item.label && !item.pattern) {
      //       var obj = {
      //         key: item.value[0],
      //         synonyms: []
      //       }
      //       if (item.more) {
      //         obj.synonyms = item.search_word[0].synonyms
      //       }
      //       newpayload.data.query_condition.push(obj)
      //     }
      //   })
      // }
      // 这里会出现undefined
      // console.log('赋值 searchpatter', tag.checked)
      // this.searchPatter = tag.checked ? tag.checked : 0
      this.switchToTab(type)

      // 记录检索的标签
      if (tag && tag.length) {
        console.log('handle emit query----', newpayload)
        this.keywordsArr[type] = this.calcKeyword(tag)
      } else {
        this.keywordsArr[type] = []
      }

      // 重置页码
      this.offset[type] = 1
      console.log('重置页码调用')
      this.queryData(type)
      this.closeMainSearchPopup()
    },
    reload() {
      this.offset[this.active] = 1
      this.queryData(this.active, false)
      this.isNetError = false
    },
    // 重置列表页数据
    resetListData() {
      Object.keys(this.listData).forEach((key) => {
        this.listData[key] = []
      })
    },
    // 检索前处理
    queryData(type = 'judgementSearch', loadMore = false, param) {
      if (this.queryParams.query_condition) {
        this.queryParams.query_condition.forEach((item) => {
          this.synonymsList.forEach((val) => {
            if (item.key === val.text) {
              item.synonyms = val.synonyms
            }
          })
        })
      }
      const params = param || this.queryParams

      if (this.searchPatter === 1) {
        params.search_pattern = 1
      } else {
        params.search_pattern = 0
      }

      if ((type === 'judgementSearch' || type === 'lawSearch' || type === 'criterionSearch' || type === 'ledgeSearch') && this.currentQueryCondition) {
        this.handleQueryTree(type, params)
      }
      this.$bus.$emit('searchParams', params)
      this.handleQuerySearch(type, params)
    },
    // 处理检索请求
    handleQuerySearch(type = 'judgementSearch', params, loadMore = false) {
      console.log('params??????', params)
      if (loadMore) {
        this.loadingMore = true
      } else {
        this.loadingFull = true
      }
      params = this.paramsHandle(params)
      this.active = type
      const v1num = type === 'judgementSearch' || type === 'lawSearch'
      if (!loadMore) {
        this.loading = false
      }
      // this.listData[type] = []
      this.$http
        .post(`${this.$pydataBase}/api/nvi/${type}${v1num ? '/v1' : ''}`, params)
        .then((res) => {
          this.loading = true
          this.loadingFull = false
          this.loadingMore = false
          if (res && res.data && res.data.code === 200) {
            const { data } = res.data
            if (data && data.data) {
              this.count = data.total_nums || 0
              if (loadMore) {
                this.listData[type] = [...this.listData[type], ...data.data]
              } else {
                this.listData[type] = data.data
                this.offset[type] = 1
              }
              if (this.listData[type].length >= data.total_nums) {
                this.isNext = false
              } else {
                this.isNext = true
              }
              if (this.$refs.searchList) {
                this.$nextTick(() => {
                  const len = this.$refs.searchList.length - 1
                  if (len > -1) {
                    this.$refs.searchList[len].initReportData()
                  }
                })
              }
            } else {
              this.listData[type] = []
            }
          } else {
            this.listData[type] = []
          }
          this.handleHighlight()
        })
        .catch((err) => {
          this.loading = true
          this.loadingFull = false
          this.loadingMore = false
          this.isNetError = true
          console.log(err)
        })
    },
    // 请求判例左侧树数据
    async handleQueryTree(type, param, myself = true) {
      let tree = ''
      if (type === 'judgementSearch') {
        tree = 'judgementTreeSearch'
      } else if (type === 'criterionSearch') {
        tree = 'criterionTreeSearch'
      } else if (type === 'ledgeSearch') {
        tree = 'ledgeTreeSearch'
      } else {
        tree = 'lawTreeSearch'
      }
      if (type === 'judgementSearch' && myself) {
        this.conditions[type] = []
        await this.handleQueryTree(type, { ...param, tree: '其他' }, false)
        await this.handleQueryTree(type, { ...param, tree: '案由' }, false)
        await this.handleQueryTree(type, { ...param, tree: '法院地域' }, false)
        return
      }
      try {
        const treeRes = await this.$http.post(`${this.$pydataBase}/api/nvi/${tree}`, param)
        if (treeRes && treeRes.data && treeRes.data.code === 200) {
          const { data } = treeRes.data
          if (data.conditions && data.conditions.length) {
            // this.conditions = []
            setTimeout(() => {
              if (!myself) {
                this.conditions[type].push(...data.conditions)
              } else {
                this.conditions[type] = data.conditions || []
              }
              this.$bus.$emit('lefttreedata', this.conditions[type])
            }, 20)
          } else {
            this.conditions = []
            return this.$notify('无筛选项目')
          }
        }
      } catch (err) {
        if (err.response) {
          const code = err.response.status && parseInt(err.response.status)
          if (code === 500) {
            this.$notify('无数据更新')
          }
        } else {
          this.$notify({
            message: '无数据更新',
            background: '#999',
          })
        }
      }
    },
    paramsHandle(res) {
      if (!res.loc_condition) {
        return res
      }
      const cond = [...res.loc_condition]

      cond.forEach((item, index) => {
        item.value.forEach((itemb, indexb) => {
          if (itemb instanceof Array) {
            const arr = []
            itemb.forEach((itemc, indexc) => {
              if (indexc % 2 === 0) {
                arr.push(itemc)
              }
            })
            // itemb = itemb.join('')
            // itemb = itemb.replace(/<span>|<\/span>/g, '')
            // cond[index].value[indexb]
            cond[index].value = arr
          }
        })
      })
      res.loc_condition = cond
      let valuearr = []
      res.loc_condition.forEach((item, index) => {
        item.value.forEach((itema, indexa) => {
          let num = 0
          if (item.synonyms && item.synonyms.length) {
            item.synonyms.forEach((itemb, indexb) => {
              if (itemb.key === itema) {
                num = 1
                valuearr.push({
                  key: itema,
                  synonyms: itemb.synonyms,
                })
              }
            })
            delete res.loc_condition[index].synonyms
          }
          if (!num) {
            let v = itema
            if (typeof itema === 'object') {
              v = itema.key
            }
            valuearr.push({ key: v, synonyms: [] })
          }
        })
        res.loc_condition[index].value = valuearr
        valuearr = []
      })
      return res
    },
    // 下一页
    next() {
      this.offset[this.active] += 1
      this.handleQuerySearch(this.active, this.queryParams, true)
    },
    // 获取默认页面数据
    getDefaultListData() {
      this.queryData()
    },
    // 顶部激活tab改变触发 old-旧值 new-新值
    onTabChange(nv, ov) {
      const lastType = ov
      const type = nv
      this.$refs.mainSearch.onSearchTypeChange(type)
      const ls = this.keywordsArr[lastType].sort().toString()
      const cs = this.keywordsArr[type].sort().toString()
      this.keywordsArr[lastType] = []
      if (ls && cs && ls === cs) {
        // tag相同且不为空则不执行任何操作
      } else if (ls === '' && cs === '') {
        // 如果当前页没有数据且页面为第一页则请求数据
        if (!this.listData[type].length && this.offset[type] === 1) {
          this.queryData(type)
        }
      } else {
        // 调用mainSearch ref执行检索
        setTimeout(() => {
          this.$refs.mainSearch.handleQuery()
        }, 10)
      }
      this.handleHighlight()
    },
    // 通过搜索建议打开法条详情页
    openLawDetail(payload) {
      this.mainSearchVisible = false
      this.toLawDetail(payload)
    },
    // 打开法律详情弹窗
    openLawDetailPopup() {
      this.lawDetailPopupVisible = true
    },
    // 关闭法律详情弹窗
    closeLawDetailPopup() {
      this.lawDetailPopupVisible = false
      this.lawDetailProp = {}
    },
    /**
     * 显示 法条详情
     */
    toLawDetail(item, catlog) {
      const { law_id: id, hit_datas: hit } = item
      const lawDetailProp = { id }
      // this.preventSearch = false
      // this.detailshow.case_id = id
      // 通过设置title为 第n条 以实现打开后跳转
      if (catlog && typeof catlog === 'string') {
        lawDetailProp.catalog = catlog
      } else if (hit && hit.catlog && hit.catlog.length) {
        lawDetailProp.catalog = hit.catlog[0]
      }
      this.lawDetailProp = lawDetailProp

      // this.detailshow.show = true
      this.openLawDetailPopup()
    },
    // TODO 完善打开详情页代码
    toDetail(payload, source) {
      if (JSON.parse(sessionStorage.getItem('userInfo') || '{}').thirdPartySource === 'ZH') {
        // 智和调用方法
        window.parent.location.href = payload
      }
      if (typeof payload === 'object') {
        this.preventSearch = false
        this.detailshow.title = payload.catlog
        this.detailshow.case_id = payload.docid
        this.detailshow.toCatloag = payload.toCatloag
        this.detailshow.flag = true
        // this.$router.push({
        //   name: 'searchPrecedentDetail',
        //   query: {
        //     docid: payload.docid,
        //     back: true
        //   }
        // })
        console.log(this.detailshow, 'detailshow')
      } else if (source === undefined) {
        this.preventSearch = false
        this.detailshow.case_id = payload
        this.detailshow.show = true
      } else {
        console.log(source)
        if (source === 'third' && this.$route.query.isMiniApp) {
          this.$notify({
            message: '请前往律呗APP端查看',
            duration: 1000,
            type: 'warning',
          })
        } else {
          this.postToNative(payload, source)
        }
      }
    },

    // 调用原生端方法
    postToNative(url, source) {
      setTimeout(() => {
        const ua = navigator.userAgent
        if (ua === 'fb-flutter-Webview') {
          window.showNews.postMessage(url)
        } else if (ua === this.$ua.a) {
          // eslint-disable-next-line no-undef
          // 安卓
          AND2JS.showNews(url)
        } else if (ua === this.$ua.i) {
          // ios
          window.webkit.messageHandlers.showNews.postMessage(url)
        } else {
          this.zhishiSrc = url
        }
      })
    },
    // 计算FilterTag
    calcFilterTag(type) {
      if (this.queryPayload[type] && this.queryPayload[type].data && this.queryPayload[type].data.filter_condition.length) {
        return this.queryPayload[type].data.filter_condition
      }
    },
    handleScroll() {
      if (this.$refs.search) {
        if (this.$refs.search.getBoundingClientRect().top > -255) {
          this.$bus.$emit('searchlist-show', false)
        } else {
          this.$bus.$emit('searchlist-show', true)
        }
      }
    },
    handleHighlight() {
      this.$nextTick(() => {
        try {
          const context = document.querySelector('.van-tabs__content')
          const contextDetail = document.querySelector('#nav')
          const hl = new window.Mark(context)
          hl.unmark()
          const value = []
          if (this.highlightKeywords && this.highlightKeywords.length) {
            this.highlightKeywords.map((item) => {
              if (typeof item === 'string') {
                value.push(item)
              } else {
                value.push(item.key)
              }
            })
            hl.mark(value, {
              exclude: ['.hl-ignore', '.titcasie', '.titcasie-label', '.keywords_fixed', '.extra-info>span', '.attrs>.attr'],
            })
          }
          // const mark = markInstance => {
          //   const kw = value
          //   if (kw && kw.length) {
          //     markInstance.mark(kw, {
          //       exclude: ['.hl-ignore']
          //     })
          //   }
          // }
          if (contextDetail) {
            // console.log('handleHighlight----contextDetail')
            const m = new window.Mark(contextDetail)
            m.mark(value)
          }
          // console.log('handleHighlight结束')
        } catch {
          console.log('mark key word 错误')
        }
      })
    },
  },
  mounted() {
    console.log('mounted')
    this.thirdPartyLogin()
    let id = this.userInfo.id
    if (!id) {
      id = this.$route.query.id
    }
    if (id) {
      this.$axios
        .get(`${this.$base}/management/users/${id}`)
        .then(async (res) => {
          console.log(res)
          console.log('rrr')
          this.miniuserInfo = res.data.data
          console.log(res.data.data, 'miniuserInfo')
          const searchCollectionId = res.data?.data?.searchCollectionId
          if (!searchCollectionId) return
          this.SET_SEARCH_COLLECTION_ID(searchCollectionId)
          await this.getStampReport({
            userId: id,
            parentId: searchCollectionId,
          })
        })
        .catch((err) => {
          Promise.reject(err)
        })
    }

    // 监听检索事件
    this.$bus.$on('mainSearch', (payload) => {
      this.handleEmitQuery(payload)
    })
    if (Object.keys(this.$route.query).length > 0) {
      const { lawid, docid, s, catlog = '' } = this.$route.query
      if (lawid) {
        this.detailshow.case_id = lawid
        this.detailshow.catlog = catlog
        this.detailshow.show = true
      } else if (docid) {
        this.detailshow.case_id = docid
        this.detailshow.flag = true
      } else if (s) {
        this.dealWithQuery()
      }
    }
    window.addEventListener('scroll', this.handleScroll, true)
    this.$bus.$on('swipeable', (res) => {
      this.swipeableShow = res
    })
    this.$bus.$on('judClickEmit', (res, query) => {
      console.log('发送 search')
      this.pushFilterCondition(res, query)
    })
    var that = this
    this.$bus.$on('openDetail', ({ id, title, type }) => {
      that.preventSearch = true
      that.detailshow.flag = false
      that.detailshow.show = false
      setTimeout(() => {
        if (type === 'doc') {
          that.preventSearch = false
          that.detailshow.title = ''
          that.detailshow.case_id = id
          that.detailshow.flag = true
        } else if (type === 'law') {
          this.lawDetailProp = { id, catalog: title }
          this.openLawDetailPopup()
        } else {
          that.preventSearch = false
          that.detailshow.case_id = id
          that.detailshow.title = title
          that.detailshow.show = true
        }
      }, 100)
    })
  },
  destroyed() {},
  created() {
    console.log('KafuuChino-版本测试')
    // 判断是否是从uniapp小程序进入的该页面，如果是则将uniapp传来的userId和token存入浏览器本地
    if (this.$route.query.id) {
      this.$axios.get(`${this.$base}/management/users/${this.$route.query.id}`).then((res) => {
        if (res.data && res.data.data) {
          // this.userInfo = res.data.data
          this.setUserInfo(res.data.data)
          this.setToken(this.$route.query.token)
          window.sessionStorage.setItem('userInfo', JSON.stringify(res.data.data))
        }
      })
      // 小程序进来跳转至小程序传来的详情
      const { docId, isMiniApp, token } = this.$route.query
      if (docId && isMiniApp && token) {
        this.toDetail({ caseAttr: '普通案例', catlog: '', docid: docId })
      }
    }

    this.getDefaultListData()
    this.$bus.$on('mohu-checked', (res) => {
      this.searchPatter = res ? 1 : 0
    })
  },
  errorCaptured(err, vm, info) {
    this.showBottom()
    console.error(err)
    return false
  },
  watch: {
    filter_condition(val) {
      if (this.active !== 'ledgeSearch') {
        this.offset[this.active] = 1
        // this.queryData(this.active)
      }
    },
    active(nv, ov) {
      console.log('active', nv)
      this.sort = 0
      if (nv === 'judgementSearch') {
        this.filterList = this.judgementFilter
      } else if (nv === 'lawSearch') {
        this.filterList = this.lawFilter
      } else if (nv === 'criterionSearch') {
        this.filterList = this.criterionFilter
      }
      this.onTabChange(nv, ov)
    },
  },
}
</script>
<style lang="stylus">
@import '~styles/variables.styl'

.content_search
  width 100%
  height 100%
  // user-select none
  & .filter-box
    width 150px
    height 100%
    background #FFF
    & .filter
      width 100%
      text-align center
      font-size 16px
      margin-top 10px
  & mark
    color red
    font-weight bold
  // -webkit-overflow-scrolling auto !important
  & .van-tabs__line
    background-color #409EFF
  & .search-nav-tab
    & .van-tabs__content
      margin-top 0px
      min-height 90vh
  & .tab-content-wrap
    user-select none
    & .net-error
      display flex
      height 88px
      align-items center
      justify-content center
      font-size 15px
      & .text-btn
          color $button-primary-background-color
  & .module-ctn
    border 1px solid skyblue
    height 100%
    width 100%
  & .mock-input-wrap
    margin 5px 0
    display flex
    justify-content center
    user-select none
    background #ffffff
    & .mock-input
      height 40px
      width calc(100% - 20px)
      display flex
      border 1px solid #ccc
      border-radius 4px
      & .mock-input__key-word
        flex 1
        height 40px
        border-right 1px solid #ccc
        overflow hidden
        & .keyword-span, .keywords_fixed
          height 30px
          line-height 40px
          padding 8px 6px
          background #409EFF
          margin-top 5px
          font-size 12px
          word-break keep-all
          // overflow hidden
          // text-overflow ellipsis
          white-space nowrap
          margin-left 3px
          // border 1px solid #ccc
          border-radius 4px
          color #fff
        & .keywords_fixed
          background #78b8f9
      & .icon1
        height auto
        font-size 20px
        width 40px
        color #000
        display flex
        justify-content center
        align-items center
      & .icon
        width 40px
        height 40px
        padding 0
        background #1989fa
        color #ffffff
        display flex
        justify-content center
        align-items center
        border-radius 0 4px 4px 0
        & i
          font-size 32px
  & .nav
    width 100%
    height 100%
    position absolute
    left 0px
    top 0px
    background-color #ffffff
    z-index 2000
    overflow-x hidden
  & .search
    width 100%
    height 50px
    position fixed
    bottom 0px
    border-top 0.6px solid #cccccc
    background #ffffff
  & .app-download-tip
    position fixed
    bottom 0
    left 0
    width 100%
    z-index 1001
</style>
<style scoped>
::v-deep .van-cell-group.van-hairline--top-bottom::after {
  border: none;
}

.collect-report {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: #fff;
  top: 0;
  z-index: 1000000001 !important;
  position: fixed;
}
</style>
