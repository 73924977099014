<template>
  <div
    :class="{
      'query-item': true,
      holder: isMeDragging,
      selp1: true,
      selp2: true,
      selp3: true,
    }"
    :style="queryItemStyle"
    ref="queryItem"
  >
    <div
      :class="{
        sec: true,
        selp3: true,
        hidden: shouldHideCorner,
        hiddenb: searchshow && itemindex === 0,
      }"
    >
      <pattern-item label="同句" :active="checkActive('同句')" @xdragover="onDragover" @xdragleave="onDragleave" @xdragdrop="onDragdrop" />
      <pattern-item label="同段" :active="checkActive('同段')" @xdragover="onDragover" @xdragleave="onDragleave" @xdragdrop="onDragdrop" :style="upblue" />
    </div>
    <div class="combined">
      <div
        class="tag-btn"
        :class="{ old: !itemColor }"
        :style="{
          background: itemColor === true ? '#1989fa' : '#E8E8E8',
          borderColor: itemColor === true ? '#1989fa' : '#E8E8E8',
        }"
        ref="tagBtn"
        @touchstart="onTouchstart"
        @touchmove="onTouchmove"
        @touchend="onTouchend"
        @touchcancel="onTouchcancel"
      >
        <label-item :item="itemData" />
        <text-wrap :item="itemData" @update:synonym="updateSynonym" />
      </div>
      <div class="detach" @click="detach">
        <van-icon name="cross" v-show="detachIconVisible" />
        <!-- <i v-show="detachIconVisible"
           class="el-icon-close"></i> -->
      </div>
    </div>
    <div
      :class="{
        sec: true,
        selp3: true,
        hidden: shouldHideCorner,
        hiddenb: searchshow && itemindex === 0,
      }"
    >
      <pattern-item label="或者" :active="checkActive('或者')" @xdragover="onDragover" @xdragleave="onDragleave" @xdragdrop="onDragdrop" />
      <pattern-item label="不包含" :active="checkActive('不包含')" @xdragover="onDragover" @xdragleave="onDragleave" @xdragdrop="onDragdrop" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import PatternItem from './patternItem'
import TextWrap from './textWrap'
import LabelItem from './labelItem'

export default {
  name: 'query-item',
  props: {
    itemData: Object,
    itemindex: Number,
  },
  emit: ['update:synonym'],
  components: {
    PatternItem,
    LabelItem,
    TextWrap,
  },
  data() {
    return {
      isMeDragging: false,
      loading: false,
      synonymsList: [],
      splithover: false,
      tagvdshow: false,
      tagbtndiv: true,
      searchshow: false,
      split: '',
      // 动画时改变背景颜色
      upblue: {},
    }
  },
  computed: {
    ...mapState('dnd', ['isCloneDragging', 'isDropDragging', 'dragSourceData', 'popVisible']),
    valueArr() {
      return this.itemData && this.itemData.value
    },
    label() {
      return this.itemData && this.itemData.label
    },
    pattern() {
      return this.itemData && this.itemData.pattern
    },
    isMultiTag() {
      return Array.isArray(this.valueArr) && this.valueArr.length > 1
    },
    // 标签样式 主要功能是根据标签字数设置宽度
    queryItemStyle() {
      const maxLen = 23
      const pageWidth = 375
      const fontWidth = 14
      const maxItemWidth = 365
      const maxItemWidthVW = (maxItemWidth / pageWidth) * 100
      const label = this.label || ''
      // 冒号宽度
      const colonSW = 13
      const labelSW = 13
      if (this.isMultiTag) {
        // 标签数量
        const tagCount = this.valueArr.length
        // 连接符数量
        const connCount = tagCount - 1
        const connWidth = 44
        const tagStr = this.valueArr.reduce((total, num) => total + num)
        const tagW = tagStr.length * fontWidth
        const labelW = label.length * labelSW + colonSW
        const connW = connCount * connWidth
        const itemW = tagW + connW + 40 + labelW
        if (itemW >= maxItemWidth) {
          return {
            width: `${maxItemWidthVW}vw`,
            marginRight: '0px',
          }
        }
        return {
          width: `${(itemW / pageWidth) * 100}vw`,
          marginRight: '0px',
        }
      } else {
        const tag = this.valueArr[0]
        const tagLen = tag.length
        const labelLen = label.length
        if (tagLen <= 5 && !label) return { width: `${31}vw` }
        const textLen = tagLen >= maxLen ? maxLen : tagLen
        const textW = textLen * fontWidth
        const labelW = labelLen * labelSW
        const paddingW = 44
        const itemW = textW + labelW + colonSW + paddingW
        const vw = (itemW / pageWidth) * 100
        const s = {
          width: `${vw}vw`,
        }
        if (textLen >= maxLen) {
          s.width = `${maxItemWidthVW}vw`
          s.marginRight = '0px'
        }
        return s
      }
    },
    itemColor() {
      return this.itemData.color
    },
    connector() {
      if (this.label && this.valueArr.length) {
        return ':'
      } else {
        return ''
      }
    },
    canDrag() {
      if (this.isCloneDragging) {
        return false
      } else {
        return true
      }
    },
    renderCorner() {
      if (this.isMeDragging) {
        return false
      } else if (this.isDropDragging) {
        if (this.itemData.pattern) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    shouldHideCorner() {
      if (this.isCloneDragging) {
        return true
      } else if (this.isDropDragging) {
        if (this.matchCombine) {
          if (this.matchEmptyLabelAndPattern) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else if (this.isMeDragging) {
        return true
      } else {
        return true
      }
    },
    // TODO 根据规则格式化字符串
    tagText() {
      const { pattern, value } = this.itemData
      // const first = value[0]
      // const rest = value.slice(1)
      let valueStr = `${value.join('、')}`
      if (pattern === '同句') {
        valueStr = `${value.join('&emsp;&&emsp;')}`
      } else if (pattern === '同段') {
        valueStr = `${value.join('&emsp;~&emsp;')}`
      } else if (pattern === '或者') {
        valueStr = `${value.join('&emsp;|&emsp;')}`
      } else if (pattern === '不包含') {
        // valueStr = `${first} - ${rest.join('、')}`
        valueStr = `${value.join('&emsp;-&emsp;')}`
      }
      return `${valueStr}`
    },
    // 禁止融合
    forbidden() {
      if (this.isDropDragging && this.dragSourceData) {
        if (this.matchCombine) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    /** 拖动状态 */
    detachIconVisible() {
      if (this.isCloneDragging || this.isDropDragging) {
        return false
      } else {
        return true
      }
    },
    // 拖拽元素的 label 和 pattern 都是空字符串 ''
    isSourceEmptyLabelAndPattern() {
      if (!this.dragSourceData) {
        return false
      } else {
        const { data } = this.dragSourceData
        return data.label === '' && data.pattern === ''
      }
    },
    matchEmptyLabelAndPattern() {
      const isMeEmpty = this.itemData.label === '' && this.itemData.pattern === ''
      return this.isSourceEmptyLabelAndPattern && isMeEmpty
    },
    matchCombine() {
      if (!this.dragSourceData) {
        return false
      } else {
        const { data } = this.dragSourceData
        const isSameLabelAndPattern = this.itemData.label === data.label && this.itemData.pattern === data.pattern
        if (isSameLabelAndPattern || this.isSourceEmptyLabelAndPattern) {
          return true
        } else {
          return false
        }
      }
    },
    popverClass() {
      if (this.isDropDragging || this.isCloneDragging) {
        return 'hidden'
      } else {
        return ''
      }
    },
    popoverVisible() {
      return this.popVisible
    },
    fmt() {
      const len = this.valueArr.length
      const pattern = this.pattern
      const fmt = {
        before: '',
        after: '',
        first: '',
        split: '、',
      }
      if (len) {
        if (pattern === '同句') {
          // fmt.before = '['
          // fmt.after = ']'
          fmt.split = '&'
        } else if (pattern === '同段') {
          // fmt.before = '{'
          // fmt.after = '}'
          fmt.split = '~'
        } else if (pattern === '或者') {
          fmt.split = '|'
        } else if (pattern === '不包含') {
          fmt.first = '-'
          fmt.split = '-'
        } else {
          fmt.split = '|'
        }
      }
      return fmt
    },
    wrapElem() {
      const wrap = document.querySelector('div.search-dnd-wrap')
      return wrap
    },
  },
  methods: {
    ...mapMutations('dnd', ['SET_DROP_DRAG', 'SET_DRAG_SOURCE_DATA', 'SET_POP_VISIBLE', 'SET_DRAG_MIRROR_PROP_START', 'SET_DRAG_MIRROR_PROP_MOVE', 'SET_DRAG_MIRROR_PROP_END', 'CLEAR_DRAG_STATE']),
    getSynonyms() {
      const newvalue = []
      this.itemData.value.forEach((item) => {
        if (item instanceof Array) {
          item.forEach((itema, indexa) => {
            if (indexa % 2 === 0) {
              newvalue.push(itema)
            }
          })
        } else {
          newvalue.push(item)
        }
      })
      this.loading = true
      this.$axios
        .post(`${this.$pydataBase}/api/synonyms`, {
          search_word: newvalue,
        })
        .then((res) => {
          this.loading = false
          const { data } = res
          if (data.code === 200 && data.result.length) {
            this.synonymsList = data.result
          }
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    updateSynonym(val) {
      this.$emit('update:synonym', val)
    },
    useSynonyms(payload) {
      const { from, to } = payload
      const value = this.itemData.value.map((item) => {
        if (item === from) {
          return to
        } else {
          return item
        }
      })
      const item = { ...this.itemData, value }
      this.$emit('update', item)
    },
    lookFN(aa, bb, i1) {
      // console.log('aa--------+', aa)
      // const num = this.splitSelectFn(bb, i1, this.itemData.value)
      // console.log('num------=', num)
    },
    checkActive(label) {
      // console.log('checkactive===-----', label)
      if (this.itemData.label === label) {
        return true
      } else {
        return false
      }
    },
    onDragover() {},
    onDragleave() {},
    onDragdrop(payload) {
      const { pattern, source } = payload
      if (pattern === '或者' || pattern === '不包含') {
        if (source.value > 1) {
          return
        }
      }
      if (this.itemData.id !== source.id && !this.itemData.pattern) {
        const combine = {
          source,
          target: { ...this.itemData, pattern },
        }
        this.$emit('combine', combine)
      }
    },
    setsynonyms(res) {
      this.getSynonyms()
    },
    tagiptFn(id, txt, input, md, numaaa) {
      // console.log('tagiptFN', id, txt, input)
      // 更具id查找
      // console.log('tagiptFn fn', this.itemData, txt)
      // console.log(this.itemData)
      const value = this.itemData.value
      this.itemData.interval = input === '>' ? '0' : input
      let num = false
      // console.log('处理之前', value)
      for (let i = 0; i < value.length; i++) {
        if (value[i] === txt) {
          if (value[i + 1] instanceof Array) {
            // console.log('+1为数组')
            value[i + 1].unshift('<span>' + input + '</span>')
            value[i + 1].unshift(value[i])
            value.splice(i, 1)
          } else {
            // console.log('+1不为数组')
            if (value[i + 1] !== '>') {
              value[i] = [value[i], '<span>' + input + '</span>', value[i + 1]]
              num = i
              // console.log('相同 修改item')
              break
            }
          }
        } else if (value[i] instanceof Array) {
          // console.log('497*****')
          for (let j = 0; j < value[i].length; j++) {
            if (value[i][j] === txt) {
              if (value[i][j + 1] && value[i][j + 1] !== '>' && value[i][j + 1] !== '&' && value[i][j + 1].indexOf('<span') === -1) {
                // console.log('512****')
                value[i].push(input)
                value[i].push(value[i + 1])
                num = i
              } else if (value[i][j + 1] === '>' || value[i][j + 1] === '&') {
                // console.log('505*****')
                num = i
                value[i][j + 1] = '<span>' + input + '</span>'
              } else if (value[i][j + 1] && value[i][j + 1].indexOf('<span') !== -1) {
                console.log('442****')
                value[i][j + 1] = '<span>' + input + '</span>'
              } else if (!value[i][j + 1] && value[i + 1]) {
                value[i][j + 1] = '<span>' + input + '</span>'
                if (value[i + 1] instanceof Array) {
                } else {
                  num = i
                  value[i][j + 2] = value[i + 1]
                }
              } else {
                console.log('520*****', value[i], j)
              }
            }
          }
        }
      }
      console.table('value去除之前', value, num)
      if (num || num === 0) {
        value.splice(num + 1, 1)
        console.log('tabvalue', value)
      } else {
        // console.log(num)
      }
      console.table('去除多余的', value)
      this.itemData.value = value
      // this.$set(this.itemData, 'value', value)
      setTimeout(() => {
        this.$forceUpdate()
      }, 10)
      // console.log('数据转换完成', this.itemData)
    },
    splitSelectFn(i, i1, value) {
      // console.log('splitSelectFn', value, value[i][i1])
      const nval = value[i][i1 + 1]
      if (
        value[i][i1 + 1] &&
        // value[i][i1 + 1] !== '>' &&
        // typeof Number(nval) === 'number'
        nval.indexOf('<span>') !== -1
      ) {
        // console.log('下个词存在 判断符号', value[i][i1 + 1])
        // 计算和下一个关键词的符号
        // console.log(
        // typeof Number(value[i][i1 + 1]) === 'number',
        // value[i][i1 + 1]
        // )
        // if (typeof Number(value[i][i1 + 1]) === 'number') {
        return `${value[i][i1 + 1]}`
        // return ''
        // }
      } else if (value[i][i1 + 1] && value[i][i1 + 1] === '>') {
        // console.log('返回 >')
        return '>'
      } else if (!value[i][i1 + 1] && value[i + 1]) {
        // console.log('判断与下一个词的符号', value, value[i + 1])
        // console.log(this.itemData)
        if (this.itemData.pattern === '同句') {
          return '&'
        }
      }
      // console.log('未判断成功')
      return ''
    },
    tagTextFn(index, value) {
      const len = this.valueArr.length
      // console.log('判断分隔符', len, value)
      if (len) {
        if (len === index + 1) {
          return ''
        } else if (this.itemData.pattern === '不包含' && index === 0) {
          console.log('分隔符', this.fmt.first)
          return this.fmt.first
        } else {
          console.log('分隔符2', this.fmt.split)
          return this.fmt.split
        }
      } else {
        // console.log('分隔符3', this.valueArr)
        return ''
      }
      // console.log('tagtextfn', num, num === 0)
      // if (index < this.itemData.value.length) {
      //   if (num === 0) {
      //     return this.itemData.value[index]
      //   }
      //   // if (num === 2) {
      //   //   return this.itemData.value[2]
      //   // }
      //   if (num === 1 && this.itemData.value[index + 1]) {
      //     const { pattern } = this.itemData
      //     // const first = value[0]
      //     // const rest = value.slice(1)
      //     if (pattern === '同句') {
      //       return '&'
      //     } else if (pattern === '同段') {
      //       return '~'
      //     } else if (pattern === '或者') {
      //       return '|'
      //     } else if (pattern === '不包含') {
      //       return '-'
      //     }
      //   }
      // }
      // return ''
    },
    tagSplitClick(index) {
      // console.log(index)
      // console.log('点击分隔符==========', this.itemData, index)
      for (let i = 0; i < this.itemData.value.length; i++) {
        if (this.itemData.value[i] instanceof Array) {
          for (let j = 0; j < this.itemData.value[i].length; j++) {
            if (this.itemData.value[i][j] === index) {
              // console.log('修改数组', this.itemData.value)
              const la = this.itemData.value[i][j]
              this.itemData.value[i][j] = this.itemData.value[i][j + 2]
              this.itemData.value[i][j + 2] = la
              // console.log('修改完成', this.itemData.value)
              break
            } else {
              // console.log(this.itemData.value[i][j])
            }
          }
        } else {
          if (this.itemData.value[i] === index) {
            // console.log('修改数组', this.itemData.value)
            const la = this.itemData.value[i]
            this.itemData.value[i] = this.itemData.value[i + 2]
            this.itemData.value[i + 2] = la
            // console.log('修改完成', this.itemData.value)
            break
          }
        }
      }
      // const la = this.itemData.value[index - 1]
      // this.itemData.value[index - 1] = this.itemData.value[index + 1]
      // this.itemData.value[index + 1] = la
      // this.$set(this.itemData, 'value', this.itemData.value)
      setTimeout(() => {
        this.$forceUpdate()
      }, 10)
    },
    handleDragover(e) {
      // console.log('hadledragover 移动')
      e.preventDefault()
      const payload = this.dragSourceData
      if (payload) {
        const { type, data } = payload
        if (type === 'clone') {
          if (e.target) {
            this.addTargetOverClass(this.$refs.tagBtn, 'success')
          }
        } else if (type === 'combine') {
          // 判断不是自身再进行处理
          if (data.id !== this.itemData.id) {
            if (this.matchEmptyLabelAndPattern) {
              this.addTargetOverClass(this.$refs.tagBtn, 'danger')
            } else if (this.matchCombine) {
              this.addTargetOverClass(this.$refs.tagBtn, 'success')
            } else {
              this.addTargetOverClass(this.$refs.tagBtn, 'danger')
            }
          }
        }
      }
    },
    splitreFn(value) {
      return value.replace(/<[^>]+>/g, '')
    },
    handleDragoverLabel(e) {
      e.preventDefault()
      const payload = this.dragSourceData
      if (payload) {
        const { type, data } = payload
        if (type === 'clone') {
          if (e.srcElement.parentNode.className === 'tag-btn') {
            this.addTargetOverClass(this.$refs.tagBtn, 'success')
          }
        } else if (type === 'combine') {
          // 判断不是自身再进行处理
          if (data.id !== this.itemData.id) {
            if (this.matchEmptyLabelAndPattern) {
              if (e.srcElement.parentNode.className === 'tag-btn') {
                this.addTargetOverClass(this.$refs.tagBtn, 'danger')
              }
            } else if (this.matchCombine) {
              if (e.srcElement.parentNode.className === 'tag-btn') {
                this.addTargetOverClass(this.$refs.tagBtn, 'success')
              }
            } else {
              if (e.srcElement.parentNode.className === 'tag-btn') {
                this.addTargetOverClass(this.$refs.tagBtn, 'danger')
              }
            }
          }
        }
      }
      console.log('handleDragoverLabel')
    },
    tagbtndivfn(reg) {
      // this.tagbtndiv = !this.tagbtndiv
      // this.$nextTick(() => {
      //   this.$refs.md.focus()
      // })
    },
    tagbtnshowFn(va) {
      let numst = 0
      const num = this.split.replace(/<[^>]+>/g, '')
      if (num.indexOf('<span') !== -1) {
        numst = 2
      } else {
        numst = 1
      }
      if (numst === va) {
        return true
      } else {
        return false
      }
    },
    handleDragStart(e) {
      setTimeout(() => {
        if (this.tagvdshow) {
          return
        }
        console.log('handle drag start 开始', this.tagvdshow)
        this.isMeDragging = true
        this.SET_DROP_DRAG(true)
        this.SET_POP_VISIBLE(false)
        const payload = {
          type: 'combine',
          data: this.itemData,
        }
        this.SET_DRAG_SOURCE_DATA(payload)
        e.dataTransfer.setData('text/plain', JSON.stringify(payload))
      }, 500)
      // console.log('drag start', e)
    },
    handleDragging(e) {
      console.log('handle dragging 移动中')
      this.isMeDragging = true
      this.SET_DROP_DRAG(true)
      // console.log('dragging', e)
    },
    handleDragLeave(e) {
      console.log('handle drag leave')
      this.removeTargetOverClass(this.$refs.tagBtn)
    },
    handleDragdrop(e) {
      console.log('handle dragdrop  移动')
      this.removeTargetOverClass(this.$refs.tagBtn)
      const payload = this.getDragTransferData(e)
      const { type, data } = payload
      console.log(payload)
      if (type === 'clone') {
        const item = { ...this.itemData, label: data.name, key: data.value }
        console.log('update', item)
        this.$emit('update', item)
      } else if (type === 'combine') {
        const { id } = data
        if (id === this.itemData.id) {
          console.log('drop myself')
        } else {
          if (this.matchEmptyLabelAndPattern) {
            this.$message.warning('请选择融合条件')
          } else if (this.matchCombine) {
            this.$emit('combine', {
              source: payload.data,
              target: this.itemData,
            })
          } else {
            this.$message.error('这两个标签无法融合')
          }
        }
      }
    },
    handleDragEnd(e) {
      console.log('handle drag end  结束移动')
      if (e.target) {
        this.removeTargetOverClass(this.$refs.tagBtn)
      }
      this.isMeDragging = false
      this.SET_DROP_DRAG(false)
      this.SET_DRAG_SOURCE_DATA(null)
      this.makePopVisible()
      console.log('drag end', e)
    },
    makePopVisible() {
      setTimeout(() => {
        this.SET_POP_VISIBLE(true)
      }, 500)
    },
    // 拆分标签 无法拆分时删除标签
    detach() {
      /*
      const value = [...this.itemData.value]
      const newarr = []
      for (let i = 0; i < value.length; i++) {
        if (value[i] instanceof Array) {
          value[i].forEach((item, index) => {
            if (
              item !== '>' &&
              item.indexOf('<span') === -1 &&
              index % 2 === 0
            ) {
              newarr.push(item)
            }
          })
        } else {
          value[i] = value[i].replace(/<[^>]+>/g, '')
          value[i] = value[i].replace(/>|&/g, ',').split(',')
          console.log('正则替换', value[i])
          for (let j of value[i]) {
            newarr.push(j)
          }
        }
      }
      */
      // this.itemData.value = newarr
      // console.log('还原数据==', this.itemData.value)
      this.$emit('detach', this.itemData)
    },
    // 添加目标className
    addTargetOverClass(target, type = 'success') {
      let className = 'drag-over-exact'
      if (type) {
        className = `drag-over-exact-${type}`
      }
      target.classList.add(className)
    },
    // 删除目标className
    removeTargetOverClass(target) {
      if (target) {
        const classList = target.classList
        if (classList) {
          const arr = ['drag-over-exact-success', 'drag-over-exact-warning', 'drag-over-exact-danger', 'drag-over-exact-info']
          arr.forEach((item) => {
            if (classList.contains(item)) {
              classList.remove(item)
            }
          })
        }
      }
    },
    // 获取拖动传递数据
    getDragTransferData(e) {
      const data = e.dataTransfer.getData('text/plain')
      console.log('drag-over', data)
      if (data) {
        return JSON.parse(e.dataTransfer.getData('text/plain'))
      } else {
        return null
      }
    },
    calcSyn(t) {
      const a = this.synonymsList.filter((item) => item.label === t)
      // console.log('calc syn', this.synonymsList, t)
      if (a.length) {
        return a[0].data
      } else {
        return []
      }
    },
    calcSplit(index) {
      const len = this.valueArr.length
      if (len) {
        if (len === index + 1) {
          return ''
        } else if (this.itemData.pattern === '不包含' && index === 0) {
          return this.fmt.first
        } else {
          return this.fmt.split
        }
      } else {
        return ''
      }
    },
    // touch 事件处理
    onTouchstart(e) {
      if (this.tagvdshow) {
        return
      }
      // console.log('on touch start', e)
      if (e.target.className === 'van-overlay') {
        this.$bus.$emit('tagtextclosedlg')
      }
      const dom = e.target
      if (dom.className.indexOf('tagsplit') !== -1) {
        console.log('classname包含tagsplit')
        return
      }
      const data = this.calcTouchData(e)
      this.SET_DROP_DRAG(true)
      const payload = {
        type: 'combine',
        data: this.itemData,
      }
      this.isMeDragging = true
      this.SET_DRAG_SOURCE_DATA(payload)
      this.$emit('onTouchstart', data)
    },
    onTouchmove(e) {
      // console.log('on touch move')
      const data = this.calcTouchData(e)
      this.$emit('onTouchmove', data)
    },
    onTouchend(e) {
      console.log('on touch end')
      const data = this.calcTouchData(e)
      // console.log('data:', data)
      this.isMeDragging = false
      this.SET_DROP_DRAG(false)
      this.$emit('onTouchend', data)
      // console.log('on Touch end')
    },
    onTouchcancel(e) {
      // console.log('on touch cancel')
      this.isMeDragging = false
      this.SET_DROP_DRAG(false)
      this.$emit('onTouchcancel', null)
    },
    t1show(res) {
      console.log('......', res, this.itemData.value)
      if (res.indexOf('<span>' === -1) && res !== '>' && res !== '&') {
        console.log('res', res)
        return true
      } else {
        return false
      }
    },
    tagtextClick() {
      this.splithover = !this.splithover
    },
    openvshow() {
      this.tagvdshow = true
    },
    tagTextShowFn(res) {
      // console.log('tagtextshowfn', res)
      // console.log('判断tagtextshowfn', res, !(res instanceof Array))
      if (res instanceof Array) {
        return false
      } else if (res.indexOf('<span') !== -1) {
        return false
      } else {
        return true
      }
    },
    tagTopClick(id, txt, fuhao) {
      // this.input = ''
      this.itemData.interval = fuhao === '>' ? 0 : ''
      const value = this.itemData.value
      let num = false
      if (fuhao === '&') {
        for (let i = 0; i < value.length; i++) {
          if (value[i] instanceof Array) {
            for (let j = 0; j < value[i].length; j++) {
              if (value[i][j] === txt) {
                if (value[i][j + 1] !== '&' && value[i][j + 1].indexOf('<span') === -1) {
                  // [1,>,2,>,3,>,4,>,6,>,7,>,8,],[5]
                  // 分割符号
                  if (j === 0) {
                  }
                  const arra = []
                  const arrb = []
                  for (let k = 0; k < value[i].length; k++) {
                    if (k < j + 1) {
                      arra.push(value[i][k])
                    } else if (k > j + 1) {
                      arrb.push(value[i][k])
                    }
                  }
                  let zk = 0
                  for (const i of arra) {
                    if (i === '>') {
                      zk = 1
                    }
                    if (i.indexOf('<span') !== -1) {
                      zk = 1
                    }
                  }
                  if (zk === 1) {
                    // console.log('切换顺序')
                    value.splice(i, 1, arra)
                  } else {
                    value.splice(i, 1, ...arra)
                  }
                  zk = 0
                  for (const i of arrb) {
                    if (i === '>') {
                      zk = 1
                    }
                    if (!isNaN(i)) {
                      zk = 1
                    }
                  }
                  if (zk === 1) {
                    value.splice(i + 1, 0, arrb)
                  } else {
                    value.splice(i + 1, 0, ...arrb)
                  }
                  // console.log('数组还原完成', value)
                }
              }
            }
          }
        }
      } else {
        // console.log('处理之前', value)
        for (let i = 0; i < value.length; i++) {
          if (value[i] === txt) {
            if (value[i + 1] !== '>') {
              if (value[i + 1] instanceof Array) {
                value[i + 1].unshift('>')
                value[i + 1].unshift(value[i])
                value.splice(i, 1)
                // num = i
              } else {
                value[i] = [value[i], fuhao, value[i + 1]]
                num = i
                break
              }
            }
          } else if (value[i] instanceof Array) {
            for (let j = 0; j < value[i].length; j++) {
              if (value[i][j] === txt) {
                if (value[i][j + 1] && value[i][j + 1] !== '>' && value[i][j + 1] !== '&' && value[i][j + 1].indexOf('<span') === -1) {
                  value[i].push(fuhao)
                  if (value[i + 1] instanceof Array && value[i] instanceof Array) {
                    value[i + 1].forEach((item) => {
                      value[i].push(item)
                    })
                  } else {
                    value[i].push(value[i + 1])
                  }
                  // console.log('压入', value[i + 1])
                  num = i
                } else {
                  value[i][j + 1] = fuhao
                  // console.log('else********', value[i])
                }
              }
            }
          }
        }
        // console.table('value去除之前', value, num)
        if (num || num === 0) {
          value.splice(num + 1, 1)
          // const tabvalue = value.splice(num + 1, 1)
          // console.log('tabvalue', tabvalue)
        } else {
          // console.log(num)
        }
      }
      // console.table('去除多余的', value)
      // this.itemData.value = value
      this.$set(this.itemData, 'value', value)
      setTimeout(() => {
        this.$forceUpdate()
      }, 10)
    },
    // 计算拖动中的数据
    calcTouchData(e) {
      const touch = e.changedTouches[0] || e.touches[0]
      const d = {
        type: 'drag',
        pos: touch,
        wrapSize: this.wrapElem && this.wrapElem.getBoundingClientRect(),
        size: this.$refs.tagBtn && this.$refs.tagBtn.getBoundingClientRect(),
        text: this.tagText,
        item: this.itemData,
      }
      return d
    },
    /** 处理拖拽结束时 计算位置并处理数据
     *! 需要区分拖拽的类型 clone 或 drag
     ** 根据类型和拖拽结束时的位置进行相应的处理
     */
    handleDragDrop(payload) {
      console.log('处理拖拽结束事件')
      // 源和目标不同才进行处理
      if (this.dragSourceData && this.dragSourceData.data.id !== this.itemData.id) {
        if (this.$refs.tagBtn && this.$refs.queryItem) {
          let rect = null
          const { pos, type } = payload
          const tRect = this.$refs.tagBtn.getBoundingClientRect()
          const qRect = this.$refs.queryItem.getBoundingClientRect()
          if (type === 'clone') {
            rect = tRect
          } else {
            rect = qRect
          }
          const { x, y, width, height } = rect
          const { clientX, clientY } = pos
          if (clientX >= x && clientX <= x + width && clientY >= y && clientY <= y + height) {
            console.log('在范围内', this.dragSourceData)
            if (type === 'clone') {
              console.log('type === clone')
              const { name, value } = this.dragSourceData.data
              // console.log('this.dragsourcedata', this.dragSourceData)
              const d = {
                ...this.itemData,
                key: value,
                label: name,
              }
              // console.log('d======', d)
              this.$emit('updateLabel', d)
            } else {
              console.log('type !== clone', type)
              // TODO 实现融合计算
              const { x, y, width, height } = qRect
              const { height: height_ } = tRect
              const inLeft = clientX >= x && clientX <= x + width / 2
              const inRight = clientX > x + width / 2 && clientX <= x + width
              const inTop = clientY >= y && clientY <= y + (height - height_) / 2
              const inBottom = clientY >= y + height_ + (height - height_) / 2 && clientY <= y + height
              console.log(inLeft, inRight, inTop, inBottom)
              let pattern = ''
              if (inLeft && inTop) {
                pattern = '同句'
                console.log('left top', pattern)
              } else if (inLeft && inBottom) {
                pattern = '或者'
                console.log('left bottom', pattern)
              } else if (inRight && inTop) {
                pattern = '同段'
                console.log('right top', pattern)
              } else if (inRight && inBottom) {
                pattern = '不包含'
                console.log('right bottom', pattern)
              } else if ((inLeft && !inTop && !inRight && !inBottom) || (!inLeft && !inTop && inRight && !inBottom)) {
                // 拖拽到了标签上
                // console.log('拖到了标签上')
                console.log(this.itemData)
                if (this.itemData.value.length > 1 || this.itemData.label) {
                  this.dragSourceData.data.pattern = '组合'
                  pattern = this.itemData.pattern
                } else if (this.itemData.value[0].length > 1) {
                  // console.log('this.item', this.itemData)
                  pattern = this.itemData.pattern
                  // console.log(
                  //   'this.dragSourceData.data',
                  //   this.dragSourceData.data
                  // )
                }
              }
              if (pattern) {
                const payload = {
                  source: this.dragSourceData.data,
                  target: {
                    ...this.itemData,
                    pattern,
                  },
                }
                // console.log('emit combine', payload)
                this.$emit('combine', payload)
              }
            }
          }
        }
      }
      // this.clearStoreState()
    },
    clearStoreState() {
      this.$nextTick(() => {
        this.CLEAR_DRAG_STATE(null)
      })
    },
    // 计算节点尺寸数据
    calcNodeSize() {
      const wrap = document.querySelector('div.search-dnd-wrap')
      if (!wrap) {
        return
      }
      this.wrapSize = wrap.getBoundingClientRect()
      this.itemSize = this.$refs.tagBtn && this.$refs.tagBtn.getBoundingClientRect()
    },
  },
  created() {
    // this.getSynonyms()
  },
  mounted() {
    this.$bus.$on('dragDrop', (payload) => {
      this.handleDragDrop(payload)
    })
    this.$bus.$on('tagvdshow', (res) => {
      this.tagvdshow = res
    })
    this.$bus.$on('search-tuodong', (res) => {
      console.log('search-toudong', this.itemindex, res)
      if (res) {
        console.log('search-toudong true')
        this.searchshow = res
      } else {
        console.log('search-toudong false')
        this.searchshow = res
      }
    })
  },
  watch: {},
}
</script>

<style lang="stylus">
.query-item
  box-sizing border-box
  margin-right 6px
  display flex
  flex-direction column
  &.full
    width 355px
    margin-right 0
  &.fixed-tag
    margin 5px 5px 5px 0
    & .combined
      display flex
      & .tag-btn
        user-select none
        background #78b8f9
        border 1px solid #78b8f9
  &.holder
    color #cccccc
    & .sec
      opacity 0
  &.danger
    color #cccccc
    & .sec
      opacity 0
    & .combined
      & .tag-btn
        background rgba(144, 147, 153, 0.4)
        border 1px solid #909399
        color #999
  & .sec
    display flex
    width calc(100% - 20px)
    justify-content space-evenly
    &.hidden
      opacity 0
  & .combined
    display flex
    justify-content center
    border none
    height 40px
    line-height 40px
    flex 1
    & .tag-btn
      flex 1
      display flex
      text-align center
      border-radius 0
      color #ffffff
      cursor grab
      overflow hidden
      &.old
        color #999999
      & .label-text
        color #ffc369
      &.drag-over-exact-success
        border 1px solid #67C23A
        background rgba(103, 194, 58, 0.4)
      &.drag-over-exact-warning
        background rgba(230, 162, 60, 0.4)
        border 1px solid #E6A23C
      &.drag-over-exact-danger
        background rgba(245, 108, 108, 0.4)
        border 1px solid #F56C6C
      &.drag-over-exact-info
        background rgba(144, 147, 153, 0.4)
        border 1px solid #909399
    & .detach
      display flex
      justify-content center
      align-items center
      width 20px
      cursor pointer
      color #777
      & i
        font-size 20px
</style>
