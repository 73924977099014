<template>
  <div class="text-wrap-comp" :style="wrapStyle">
    <template v-if="item.value && item.value.length">
      <div class="tag-text__wrap">
        <template v-for="(text, i) in item.value">
          <text-item :text="text" @textClick="tagTextClick" @update:synonym="updateSynonym" :key="text + i"> </text-item>
          <div class="conn" v-if="calcConnector(i)" @click.stop="onConnectorClick(i)" :key="'conn' + i">
            {{ calcConnector(i) }}
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import TextItem from './textItem'

export default {
  name: 'text-wrap',
  props: {
    item: Object,
  },
  emit: ['update:synonym'],
  components: {
    TextItem,
  },
  computed: {
    wrapStyle() {
      return {}
    },
    isMulti() {
      return this.item && this.item.value && this.item.value.length > 1
    },
  },
  methods: {
    updateSynonym(val) {
      this.$emit('update:synonym', val)
    },
    //
    tagTextClick(text) {
      this.$bus.$emit('tagTextClick', { id: this.item.id, text })
      // console.log('text %s is clicked', text)
    },
    // 连接器是否显示
    calcConnector(index) {
      const conns = [
        { name: '同句', char: '&' },
        { name: '同段', char: '~' },
        { name: '或者', char: '|' },
        { name: '不包含', char: '-' },
      ]
      if (this.isMulti && index + 1 < this.item.value.length) {
        const conn = conns.find((item) => item.name === this.item.pattern)
        return conn && conn.char
      }
      return null
    },
    // 连接器点击事件
    onConnectorClick(index) {
      console.log('onConnectorClick index: ', index)
    },
  },
  mounted() {},
  created() {},
}
</script>

<style lang="stylus" scoped>
.text-wrap-comp
  display flex
  flex 1
  justify-content space-between
  overflow hidden
  & .tag-text__wrap
    display flex
    flex 1
    overflow hidden
    & .conn
      width 40px
</style>
