var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShowTip
    ? _c("div", { staticClass: "version_tip" }, [
        _vm._v("本手机系统版本较老，请升级ios版本"),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }