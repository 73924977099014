var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-wrap-comp", style: _vm.wrapStyle },
    [
      _vm.item.value && _vm.item.value.length
        ? [
            _c(
              "div",
              { staticClass: "tag-text__wrap" },
              [
                _vm._l(_vm.item.value, function (text, i) {
                  return [
                    _c("text-item", {
                      key: text + i,
                      attrs: { text: text },
                      on: {
                        textClick: _vm.tagTextClick,
                        "update:synonym": _vm.updateSynonym,
                      },
                    }),
                    _vm.calcConnector(i)
                      ? _c(
                          "div",
                          {
                            key: "conn" + i,
                            staticClass: "conn",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.onConnectorClick(i)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.calcConnector(i)) + " ")]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }