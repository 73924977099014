var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "judgement-item",
      on: {
        click: function ($event) {
          return _vm.toDetail(
            _vm.tabsData.law_id,
            _vm.tabsData.case_attr,
            "",
            $event
          )
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "title",
          style: { color: _vm.itemClickst ? "#ccc" : "#333" },
        },
        [
          _vm.tabsData.timeliness !== "现行有效" &&
          _vm.tabsData.timeliness !== ""
            ? _c("span", { staticClass: "CanNot" }, [
                _vm._v(" " + _vm._s(_vm.tabsData.timeliness) + " "),
              ])
            : _vm._e(),
          _c("span", {
            staticClass: "text",
            style: { color: _vm.itemClickst ? "#ccc" : "#333" },
            domProps: { innerHTML: _vm._s(_vm.tabsData.title) },
          }),
          _c(
            "div",
            {
              staticClass: "title-share-report",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleSelectLawItem(_vm.item)
                },
              },
            },
            [
              _c(
                "van-checkbox",
                {
                  staticClass: "vancheck",
                  attrs: { shape: "square" },
                  model: {
                    value: _vm.isSelected,
                    callback: function ($$v) {
                      _vm.isSelected = $$v
                    },
                    expression: "isSelected",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isSelected,
                          expression: "!isSelected",
                        },
                      ],
                      staticClass: "check-num",
                    },
                    [_vm._v(" " + _vm._s(_vm.itemindex + 1) + " ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "extra-info",
          style: { color: _vm.itemClickst ? "#ccc" : "#333" },
        },
        [
          _vm.hasAttr
            ? _c("span", { staticClass: "attrs" }, [
                _vm.item.publish_machinery
                  ? _c("span", { staticClass: "attr" }, [
                      _vm._v(_vm._s(_vm.item.publish_machinery)),
                    ])
                  : _vm._e(),
                _vm.item.pub_date
                  ? _c("span", { staticClass: "attr" }, [
                      _vm._v(_vm._s(_vm.item.pub_date)),
                    ])
                  : _vm._e(),
                _vm.item.exc_date
                  ? _c("span", { staticClass: "attr" }, [
                      _vm._v(_vm._s(_vm.item.exc_date)),
                    ])
                  : _vm._e(),
                _vm.item.potency_level
                  ? _c("span", { staticClass: "attr" }, [
                      _vm._v(_vm._s(_vm.item.potency_level)),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _vm.tabList.length > 0
        ? _c(
            "div",
            {
              ref: "lawcontentDom",
              staticClass: "vantabshull",
              on: {
                touchstart: _vm.titbtndown,
                touchend: _vm.titbtnup,
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c(
                "van-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                _vm._l(_vm.tabList, function (itb, index) {
                  return _c(
                    "van-tab",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tabList.length > 0,
                          expression: "tabList.length > 0",
                        },
                      ],
                      key: index + "card",
                      attrs: { title: itb.name, name: itb.name },
                      scopedSlots: _vm._u(
                        [
                          itb.f
                            ? {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "van-tag",
                                      { attrs: { round: "", type: "primary" } },
                                      [_vm._v(_vm._s(_vm.parseTabName(itb)))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : {
                                key: "title",
                                fn: function () {
                                  return [_vm._v(" " + _vm._s(itb.name) + " ")]
                                },
                                proxy: true,
                              },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm.isAddition(itb.name)
                        ? _c(
                            "div",
                            {
                              ref: `tabsA${index}`,
                              refInFor: true,
                              staticClass: "tabsA-div",
                              attrs: { id: `tab-${_vm.activeName}` },
                            },
                            [
                              _c("law-addition", {
                                attrs: { name: itb.name, list: itb.value },
                                on: { toLaw: _vm.handleToLaw },
                              }),
                            ],
                            1
                          )
                        : itb.name === "常用法条" ||
                          itb.name === "相关法律" ||
                          itb.name === "引用判例"
                        ? _c(
                            "div",
                            {
                              ref: `tabsA${index}`,
                              refInFor: true,
                              staticClass: "tabsA-div",
                              attrs: { id: `tab-${_vm.activeName}` },
                            },
                            _vm._l(itb.value, function (item1, index1) {
                              return _c(
                                "div",
                                {
                                  key: index1 + "cardsp",
                                  class:
                                    itb.name === "引用法条" ? "tabsAspan" : "",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toDetail(
                                        _vm.tabsData.law_id,
                                        "",
                                        "",
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  itb.catlog && itb.catlog[index1]
                                    ? _c(
                                        "span",
                                        { staticClass: "tabsA-div-titsp" },
                                        [_vm._v(_vm._s(itb.catlog[index1]))]
                                      )
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item1.title) + " "),
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#1f459e" } },
                                    [
                                      _vm._v(
                                        " 被" +
                                          _vm._s(item1.num) +
                                          "篇判例引用 "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              ref: `tabsA${index}`,
                              refInFor: true,
                              staticClass: "tabsA-div",
                              attrs: { id: `tab-${_vm.activeName}` },
                            },
                            _vm._l(itb.value, function (item1, index1) {
                              return _c(
                                "div",
                                {
                                  key: index1 + "cardsp",
                                  class:
                                    itb.name === "引用法条" ? "tabsAspan" : "",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toDetail(
                                        _vm.tabsData.law_id,
                                        "",
                                        itb.catlog[index1],
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  itb.catlog && itb.catlog[index1]
                                    ? _c(
                                        "span",
                                        { staticClass: "tabsA-div-titsp" },
                                        [_vm._v(_vm._s(itb.catlog[index1]))]
                                      )
                                    : _vm._e(),
                                  _c("span", {
                                    domProps: { innerHTML: _vm._s(item1) },
                                  }),
                                ]
                              )
                            }),
                            0
                          ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabList.length > 0,
              expression: "tabList.length > 0",
            },
          ],
          ref: "sanjiaoDOM",
          staticClass: "san-jiao-hull",
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.sanjiaoShow,
                expression: "sanjiaoShow",
              },
            ],
            ref: "sanJiao",
            staticClass: "san-jiao",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.sanJiaoClick.apply(null, arguments)
              },
            },
          }),
          _c("div", { ref: "sanjiaoDOMB", staticClass: "san-jiao-cen" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }