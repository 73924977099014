var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "judgement-item",
      on: {
        click: function ($event) {
          return _vm.toDetail(_vm.item.doc_id, _vm.item.case_attr, "", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          {
            staticClass: "text",
            style: { color: _vm.itemClickst ? "#ccc" : "#333" },
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.item.case_attr !== "普通案例" &&
                      _vm.item.case_attr !== null,
                    expression:
                      "item.case_attr !== '普通案例' && item.case_attr !== null",
                  },
                ],
                staticClass: "tipsty",
              },
              [_vm._v(" " + _vm._s(_vm.item.case_attr) + " ")]
            ),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.item.case_name) } }),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "title-shear",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.selectJudgeItem.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "van-checkbox",
              {
                staticClass: "vancheck",
                attrs: { shape: "square" },
                model: {
                  value: _vm.titleShearstate,
                  callback: function ($$v) {
                    _vm.titleShearstate = $$v
                  },
                  expression: "titleShearstate",
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.titleShearstate,
                        expression: "!titleShearstate",
                      },
                    ],
                    staticClass: "van-check-num",
                  },
                  [_vm._v(" " + _vm._s(_vm.itemindex + 1) + " ")]
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "extra-info",
          style: { color: _vm.itemClickst ? "#999" : "#333" },
        },
        [
          _vm.item.court_name
            ? _c(
                "span",
                { style: { color: _vm.itemClickst ? "#ccc" : "#999" } },
                [_vm._v(_vm._s(_vm.item.court_name))]
              )
            : _vm._e(),
          _vm.item.case_id
            ? _c(
                "span",
                { style: { color: _vm.itemClickst ? "#ccc" : "#999" } },
                [_vm._v(_vm._s(_vm.item.case_id))]
              )
            : _vm._e(),
          _vm.item.pub_date
            ? _c(
                "span",
                { style: { color: _vm.itemClickst ? "#ccc" : "#999" } },
                [_vm._v(_vm._s(_vm.item.pub_date))]
              )
            : _vm._e(),
        ]
      ),
      _vm.tabList.length > 0
        ? _c(
            "div",
            {
              ref: "zkcontentDom",
              staticClass: "vantabshull",
              on: {
                touchstart: _vm.titbtndown,
                touchend: _vm.titbtnup,
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _c(
                "van-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                _vm._l(_vm.tabList, function (itb, index) {
                  return _c(
                    "van-tab",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tabList.length > 0,
                          expression: "tabList.length > 0",
                        },
                      ],
                      key: index + "card",
                      attrs: { title: itb.name, name: itb.name },
                      scopedSlots: _vm._u(
                        [
                          itb.f
                            ? {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "van-tag",
                                      { attrs: { round: "", type: "primary" } },
                                      [_vm._v(_vm._s(_vm.parseTabName(itb)))]
                                    ),
                                  ]
                                },
                                proxy: true,
                              }
                            : {
                                key: "title",
                                fn: function () {
                                  return [_vm._v(" " + _vm._s(itb.name) + " ")]
                                },
                                proxy: true,
                              },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      itb.name !== "引用法条"
                        ? _c(
                            "div",
                            {
                              ref: "tabsA",
                              refInFor: true,
                              staticClass: "tabsA-div",
                              attrs: { id: `tab-${itb.name}` },
                            },
                            _vm._l(itb.value, function (item1, index1) {
                              return _c(
                                "span",
                                {
                                  key: index1 + "cardsp",
                                  staticClass: "full-law-text",
                                  class:
                                    itb.name === "引用法条" ? "tabsAspan" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toJudgementDetail(itb)
                                    },
                                  },
                                },
                                [
                                  typeof item1 === "object"
                                    ? [_c("p", [_vm._v(_vm._s(item1.title))])]
                                    : _c("span", {
                                        domProps: { innerHTML: _vm._s(item1) },
                                      }),
                                ],
                                2
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      itb.name === "引用法条"
                        ? _c(
                            "div",
                            {
                              ref: "tabsA",
                              refInFor: true,
                              staticClass: "tabsA-div",
                              attrs: { id: `tab-${itb.name}` },
                            },
                            _vm._l(itb.value, function (item1, index1) {
                              return _c(
                                "span",
                                {
                                  key: index1 + "cardsp",
                                  staticClass: "full-law-text",
                                  class:
                                    itb.name === "引用法条" ? "tabsAspan" : "",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toJudgementDetail(itb)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item1.full_law) + " ")]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabList.length > 0,
              expression: "tabList.length > 0",
            },
          ],
          ref: "sanjiaoDOM",
          staticClass: "san-jiao-hull",
        },
        [
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.sanjiaoShow,
                expression: "sanjiaoShow",
              },
            ],
            ref: "sanJiao",
            staticClass: "san-jiao",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.sanJiaoClick.apply(null, arguments)
              },
            },
          }),
          _c("div", { ref: "sanjiaoDOMB", staticClass: "san-jiao-cen" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }