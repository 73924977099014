// loc_condition格式化
export const locTagFmt = obj => {
  const { label, pattern, value } = obj
  const first = value[0]
  const rest = value.slice(1)
  let valueStr = `${value.join('、')}`
  if (pattern === '同句') {
    valueStr = `[${value.join('、')}]`
  } else if (pattern === '同段') {
    valueStr = `{${value.join('、')}}`
  } else if (pattern === '或者') {
    valueStr = `${value.join('|')}`
  } else if (pattern === '不包含') {
    valueStr = `${first}-${rest.join('、')}`
  }
  if (label && label !== 'content' && label !== 'qw') {
    return `${label} : ${valueStr}`
  } else {
    return `${valueStr}`
  }
}

// filter_condition格式化
export const filTagFmt = tag => {
  const { value, label } = tag
  const valueStr = `${value.join('、')}`
  return label ? `${label}:${valueStr}` : valueStr
}

// 生成唯一id
export const genID = length => {
  return Number(
    Math.random()
      .toString()
      .substr(3, length) + Date.now()
  ).toString(36)
}

// 判例检索locCondition数据
export const locConditionForJudgement = [
  [
    { key: 'bt', label: '标题' },
    { key: 'body', label: '主体' },
    { key: 'summary', label: '案由' },
    { key: 'case_id', label: '案号' },
    { key: 'judge', label: '法官' },
    { key: 'court', label: '法院' },
    { key: 'lawyer', label: '律师' },
    { key: 'office', label: '律所' },
    { key: 'ygsc', label: '原告诉称' },
    { key: 'bgbc', label: '被告辩称' },
    { key: 'zyjd', label: '争议焦点' },
    // { key: 'zjzz', label: '证据质证' },
    { key: 'byrw', label: '本院认为' },
    { key: 'cpjg', label: '裁判结果' },
    { key: 'law', label: '法律条文' },
    { key: 'content', label: '' },
    { key: 'qw', label: '' }
  ]
]

// 法律检索locCondition数据
export const locConditionForLaw = [
  [
    { key: 'bt', label: '标题' },
    { key: 'fbbm', label: '发布部门' },
    { key: 'fbnf', label: '发布年份' },
    { key: 'ztfl', label: '主体分类' }
  ]
  // [
  //   { key: 'fl', label: '法律' },
  //   { key: 'sfjs', label: '司法解释' },
  //   { key: 'xzfg', label: '行政法规' },
  //   { key: 'bmgz', label: '部门规章' },
  //   { key: 'jsfg', label: '军事法规' }
  // ],
  // [
  //   { key: 'ttgz', label: '团体规章' },
  //   { key: 'hygd', label: '行业规定' },
  //   { key: 'dffg', label: '地方法规' },
  //   { key: 'dfgf', label: '地方规范' }
  // ]
]

// 知识检索locCondition数据
export const locConditionForLedge = [
  [
    { key: 'bt', label: '标题' },
    { key: 'cpgz', label: '裁判规则' },
    { key: 'fbnf', label: '发布年份' }
  ]
  // [
  //   { key: 'gzh', label: '公众号' },
  //   { key: 'wb', label: '微博' },
  //   { key: 'sh', label: '搜狐' },
  //   { key: 'tt', label: '头条' }
  // ]
]

// 标准检索locConditionForCriterion数据
export const locConditionForCriterion = [
  [
    { key: 'bt', label: '标题' },
    { key: 'gkbm', label: '归口部门' },
    { key: 'fbsj', label: '发布日期' },
    { key: 'sssj', label: '生效日期' }
  ]
]

// locCondition数据
export const locCondition = {
  judgementSearch: locConditionForJudgement,
  lawSearch: locConditionForLaw,
  ledgeSearch: locConditionForLedge,
  criterionSearch: locConditionForCriterion
}

// 将locCondition拍平成对象
const dict = {}
export const locConditionArr = [
  locConditionForJudgement,
  locConditionForLaw,
  locConditionForLedge,
  locConditionForCriterion
]
locConditionArr.forEach(con => {
  con.forEach(list => {
    list.forEach(item => {
      dict[item.key] = item.label
    })
  })
})

export const labelDict = dict
