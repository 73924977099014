var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-search-comp",
      on: {
        touchstart: function ($event) {
          $event.stopPropagation()
          return _vm.mainClickFn.apply(null, arguments)
        },
      },
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.tancengshow,
            expression: "tancengshow",
          },
        ],
        staticClass: "tanceng",
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tancengshow,
              expression: "tancengshow",
            },
          ],
          staticClass: "slep1-hull",
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slep === 1,
                  expression: "slep === 1",
                },
              ],
            },
            [
              _vm._m(0),
              _c("div", { staticClass: "slep1-left" }),
              _c("div", { staticClass: "slep1-bottom" }),
              _c("div", { staticClass: "slep1-sanjiao-top" }),
              _c("div", { staticClass: "slep1-sanjiao-bottom" }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slep === 2,
                  expression: "slep === 2",
                },
              ],
            },
            [
              _vm._m(1),
              _vm._m(2),
              _c("div", { staticClass: "slep2-sanjiao-top" }),
              _c("div", { staticClass: "slep2-sanjiao-bottom" }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slep === 3,
                  expression: "slep === 3",
                },
              ],
            },
            [_vm._m(3)]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slep === 4,
                  expression: "slep === 4",
                },
              ],
            },
            [_vm._m(4)]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.slep === 5,
                  expression: "slep === 5",
                },
              ],
            },
            [
              _vm._m(5),
              _vm._m(6),
              _c("div", { staticClass: "slep5-sanjiao-bottom" }),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "search-area selp1" }, [
        _c(
          "div",
          { staticClass: "search-wrap" },
          [
            _c(
              "div",
              { staticClass: "search-dropdown" },
              [
                _c(
                  "van-dropdown-menu",
                  [
                    _c("van-dropdown-item", {
                      attrs: {
                        value: _vm.activeType,
                        options: _vm.searchOption,
                      },
                      on: { change: _vm.onSearchTypeChange },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "search-input", attrs: { id: "step1" } },
              [
                _c(
                  "van-search",
                  {
                    ref: "inputRef",
                    attrs: {
                      placeholder: "请输入搜索关键词",
                      "show-action": "",
                      shape: "round",
                    },
                    on: {
                      focus: _vm.handleFocus,
                      blur: _vm.handleBlur,
                      input: _vm.onInputValueChange,
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.hanldeKeySpace.apply(null, arguments)
                      },
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleKeyEnter.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.inputValue,
                      callback: function ($$v) {
                        _vm.inputValue = $$v
                      },
                      expression: "inputValue",
                    },
                  },
                  [
                    _c("div", { attrs: { slot: "action" }, slot: "action" }, [
                      _c("span", { on: { click: _vm.search } }, [
                        _vm._v("添加"),
                      ]),
                      _c("i", {
                        staticClass: "iconfont icon-bangzhu",
                        on: { click: _vm.help },
                      }),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm.muster.length > 0
              ? _c("suggestion-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showHistory,
                      expression: "showHistory",
                    },
                  ],
                  attrs: {
                    searchType: _vm.activeType,
                    muster: _vm.muster,
                    shard: _vm.shard,
                  },
                  on: {
                    use: _vm.handleUseTag,
                    quote: _vm.handleQuoteTag,
                    query: _vm.handleQueryTag,
                    delete: _vm.handleDeleteHistory,
                  },
                })
              : _vm._e(),
            _c("search-suggestion", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showSuggestion,
                  expression: "showSuggestion",
                },
              ],
              attrs: { suggestions: _vm.suggestions },
              on: { selectSuggestionItem: _vm.selectSuggestionItem },
            }),
          ],
          1
        ),
      ]),
      _c("search-dnd", {
        ref: "searchDnd",
        attrs: {
          searchType: _vm.activeType,
          loadingData: _vm.loadingData,
          keywordArr: _vm.keywordArr,
          fixedTags: _vm.fixedTags,
          dragTags: _vm.dragTags,
          cloneItemList: _vm.cloneItemList,
        },
        on: {
          "update:synonym": _vm.updateSynonym,
          deleteMe: _vm.handleDeleteFixedTags,
          update: _vm.handleUpdate,
          combine: _vm.handleCombine,
          delete: _vm.deleteById,
          detach: _vm.handleDetach,
          clear: _vm.cancel,
          query: _vm.emitQueryOld,
          queryOld: _vm.emitQuery,
        },
      }),
      _c(
        "van-action-sheet",
        {
          attrs: { description: "使用同义词替换", "close-on-click-action": "" },
          model: {
            value: _vm.synonymsActionSheetVisible,
            callback: function ($$v) {
              _vm.synonymsActionSheetVisible = $$v
            },
            expression: "synonymsActionSheetVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "synonym-list" },
            _vm._l(_vm.synonymList, function (s) {
              return _c(
                "div",
                { key: s, staticClass: "item" },
                [
                  _c("van-button", { attrs: { type: "info" } }, [
                    _vm._v(_vm._s(s)),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slep1-text" }, [
      _c("span", [_vm._v("点击添加或键盘空格、回车、确定按钮")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slep2-text" }, [
      _c("span", [_vm._v("拖拽生成新标签")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slep2-quxian" }, [
      _c("canvas", { attrs: { id: "slep2-canvas" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slep3-text" }, [
      _c("span", [_vm._v("拖拽生成新标签")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slep4-text" }, [
      _c("span", [_vm._v('单击 "&" 可设置顺序和间隔字数')]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slep4-text" }, [
      _c("span", [_vm._v("双击显示同义词")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "slep5-quxian" }, [
      _c("canvas", { attrs: { id: "slep5-canvas" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }