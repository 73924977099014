<template>
  <div class="text-label" v-if="item && item.label">
    <span
      :style="{
        color: item.color ? '#ffc369' : '#666666'
      }"
      class="label-text"
      >{{ item.label }}</span
    >
    <span
      class="label-connector"
      :style="{
        color: item.color ? '#ffc369' : '#666666'
      }"
    >
      {{ item.label && item.value.length ? ':' : '' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'label-item',
  props: {
    item: Object
  }
}
</script>

<style lang='stylus' scoped>
.text-label
  font-size 13px
</style>
