var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "pattern-item": true, active: _vm.active },
      on: {
        drop: _vm.onDropCorner,
        dragover: _vm.onOverCorner,
        dragleave: _vm.onLeaveCorner,
      },
    },
    [_vm._v(" " + _vm._s(_vm.label) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }