var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "queryItem",
      class: {
        "query-item": true,
        holder: _vm.isMeDragging,
        selp1: true,
        selp2: true,
        selp3: true,
      },
      style: _vm.queryItemStyle,
    },
    [
      _c(
        "div",
        {
          class: {
            sec: true,
            selp3: true,
            hidden: _vm.shouldHideCorner,
            hiddenb: _vm.searchshow && _vm.itemindex === 0,
          },
        },
        [
          _c("pattern-item", {
            attrs: { label: "同句", active: _vm.checkActive("同句") },
            on: {
              xdragover: _vm.onDragover,
              xdragleave: _vm.onDragleave,
              xdragdrop: _vm.onDragdrop,
            },
          }),
          _c("pattern-item", {
            style: _vm.upblue,
            attrs: { label: "同段", active: _vm.checkActive("同段") },
            on: {
              xdragover: _vm.onDragover,
              xdragleave: _vm.onDragleave,
              xdragdrop: _vm.onDragdrop,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "combined" }, [
        _c(
          "div",
          {
            ref: "tagBtn",
            staticClass: "tag-btn",
            class: { old: !_vm.itemColor },
            style: {
              background: _vm.itemColor === true ? "#1989fa" : "#E8E8E8",
              borderColor: _vm.itemColor === true ? "#1989fa" : "#E8E8E8",
            },
            on: {
              touchstart: _vm.onTouchstart,
              touchmove: _vm.onTouchmove,
              touchend: _vm.onTouchend,
              touchcancel: _vm.onTouchcancel,
            },
          },
          [
            _c("label-item", { attrs: { item: _vm.itemData } }),
            _c("text-wrap", {
              attrs: { item: _vm.itemData },
              on: { "update:synonym": _vm.updateSynonym },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "detach", on: { click: _vm.detach } },
          [
            _c("van-icon", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.detachIconVisible,
                  expression: "detachIconVisible",
                },
              ],
              attrs: { name: "cross" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          class: {
            sec: true,
            selp3: true,
            hidden: _vm.shouldHideCorner,
            hiddenb: _vm.searchshow && _vm.itemindex === 0,
          },
        },
        [
          _c("pattern-item", {
            attrs: { label: "或者", active: _vm.checkActive("或者") },
            on: {
              xdragover: _vm.onDragover,
              xdragleave: _vm.onDragleave,
              xdragdrop: _vm.onDragdrop,
            },
          }),
          _c("pattern-item", {
            attrs: { label: "不包含", active: _vm.checkActive("不包含") },
            on: {
              xdragover: _vm.onDragover,
              xdragleave: _vm.onDragleave,
              xdragdrop: _vm.onDragdrop,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }