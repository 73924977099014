<template>
  <div class="container">
    <van-popover v-model="showPopover" :overlay="synonymList.length === 0 ? false : true" trigger="click" close-on-click-outside>
      <div class="syn-popover">
        <van-checkbox-group v-model="result">
          <div v-for="(item, index) in synonymList" :key="index" class="check-option">
            <van-checkbox shape="square" :name="item" icon-size="24px">{{ item }}</van-checkbox>
          </div>
        </van-checkbox-group>
        <!-- <div class="page-nav">
          <van-button size="mini" type="primary">主要按钮</van-button>
          <van-button size="mini" type="primary">主要按钮</van-button>
        </div>
        <div class="actions flex-center">
          <input type="text" />
        </div> -->
      </div>
      <template #reference>
        <div class="text-item">
          <div class="text-item__kw">
            <span v-if="result.length" class="syn-badge">{{ result.length }}</span>
            {{ text }}
          </div>
        </div>
      </template>
    </van-popover>
  </div>
</template>

<script>
// 同义词接口延迟
// const SYNO_DELAY = 100
// 单页数据数量
// const PAGE_SIZE = 10
// 缓存2021年8月1日18:02:38
const CACHE_TIME = 5 * 1000 * 60
export default {
  props: {
    text: String,
  },
  emits: ['update:synonym'],
  name: 'text-item',
  data() {
    return {
      showPopover: false,
      result: [],
      // 上次请求接口的时间戳
      lastQueryTimestamp: null,
      // 同义词列表
      synonymList: [],
      // 启用同义词
      synonymEnabled: false,
    }
  },
  computed: {
    // 有同义词
    enableSyn({ synonymList, synonymEnabled }) {
      return isFilledArray(synonymList) && synonymEnabled
    },
  },
  methods: {
    // 获取同义词数据
    getSynonyms() {
      this.$axios
        .post(`${this.$pydataBase}/api/synonyms`, {
          search_word: [this.text],
        })
        .then((res) => {
          const { data } = res
          if (data.code === 200 && data.result.length) {
            this.synonymList = data.result[0].data
            // this.synonymList = (data.result[0] && data.result[0].data && [...(this.associated[this.text] || []), ...data.result[0].data]) || []
            // setTimeout(() => {
            //   this.synonymEnabled = true
            // }, 21)
          }
          //  else {
          //   this.synonymList = [...(this.associated[this.text] || [])]
          // }
          this.lastQueryTimestamp = Date.now()
        })
        .catch((err) => {
          Promise.reject(err)
        })
    },
    // 获取同义词数据 未请求过接口或超过指定时间才执行
    handleSynonymData() {
      const timeDiff = this.lastQueryTimestamp ? Date.now() - this.lastQueryTimestamp : Date.now()
      if (timeDiff > CACHE_TIME) {
        this.getSynonyms()
      }
    },
    /**
     * @description 准备同义词数据
     */
    // prepareSynonym() {
    //   const timer = setTimeout(() => {
    //     this.handleSynonymData()
    //   }, SYNO_DELAY)
    //   this.$onHookUnmounted('hook:beforeDestroy', () => {
    //     timer && clearTimeout(timer)
    //   })
    // },
    emitClick() {
      this.$emit('textClick', this.text)
    },
    emitUpdateSynonym(val) {
      this.$emit('update:synonym', val)
    },
  },
  watch: {
    result: {
      handler(val) {
        this.emitUpdateSynonym({ text: this.text, synonyms: val })
      },
    },
  },
  mounted() {},
  created() {
    this.handleSynonymData()
  },
}
</script>

<style lang="stylus" scoped>
// 单行选择样式
.container
  width 100%
  height 100%
 &.van-checkbox-group
    // margin-left 60px
    padding-bottom 10px
  &.van-checkbox
    padding 10px 10px 0
  &.text-item
    // flex 1
    overflow hidden
    display flex
    // flex auto
    & .text-item__kw
      position relative
      text-overflow ellipsis
      white-space nowrap
      overflow hidden
      flex auto
      user-select none
      font-size 14px
      padding 0 19px
      & .syn-badge
        position absolute
        right 4px
        width 16px
        height 16px
        border-radius 50%
        color #fff
        background-color #f56c6c
        text-align center
        line-height 16px
        font-size 12px

// .container
//   width 100%
// .syn-popover
//     width 160px
//     padding 5px 10px
//     overflow hidden
//     & .check-option
//       float left
//       &:nth-child(odd)
//         margin-right 4px
//       :deep(.el-checkbox)
//         height 20px
//         &.text-item
//           // flex 1
//           overflow hidden
//           display flex
//           // flex auto
//           & .text-item__kw
//             text-overflow ellipsis
//             white-space nowrap
//             overflow hidden
//             flex auto
//             user-select none
//             font-size 14px
</style>
