<template>
  <div class="suggestion-item-comp">
    <div class="suggestion-item-comp_title">
      <div>历史记录</div>
      <div @touchend.stop="deleteAll" class="delete-all-btn">全部删除</div>
    </div>
    <div v-for="(t, i) in muster" :key="i" class="text-tag-list">
      <div class="text-tag-content" @touchstart.stop="useTag(t)">
        {{ fmtText(t) }}
      </div>
      <div class="delete" @touchend.stop="deleteTagDirectly(t)">
        <i class="iconfont icon-clear-2"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'suggestion-item',
  components: {},
  props: {
    searchType: {
      type: String,
      default: _ => 'judgementSearch'
    },
    muster: {
      type: Array,
      default: () => []
    },
    shard: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      musterCopy: [],
      musternum: 0
    }
  },
  computed: {
    ...mapGetters('user', ['userId']),
    // 删除接口地址
    deleteApiUrl() {
      // 暂时只判断 法律和判例 其他类型等后续接口支持
      if (this.searchType === 'lawSearch') {
        return `${this.$pydataBase}/api/nvi/lawSearchDeleteHistory`
      }
      return `${this.$pydataBase}/api/nvi/judgementSearchDeleteHistory`
    }
  },
  methods: {
    useTag(t) {
      const list = []
      t.query_condition.map(item => {
        list.push(item.key)
      })
      this.$emit('use', list)
    },
    emitQuery(payload) {
      this.$emit('query', payload)
    },
    emitQuote(payload) {
      this.$emit('quote', payload)
    },
    fmtText(t) {
      if (t.query_condition.length) {
        let str = ''
        t.query_condition.map(item => {
          str += item.key + '、'
        })
        str = str.substr(0, str.length - 1)
        str += ` (${t.num})`
        return str
      } else if (t.filter_condition.length) {
        return `${t.filter_condition.join('、')} (${t.num})`
      } else if (t.loc_condition.length) {
        return `${t.loc_condition.join('、')} (${t.num})`
      }
    },
    // 直接删除检索历史
    async deleteTagDirectly(t) {
      const response = await this.$axios.post(
        `${this.deleteApiUrl}?flag=0&user_id=${this.userId}&token=${t.token}`
      )
      const { code } = response.data
      if (code === 200) {
        this.$notify({
          type: 'success',
          message: '删除检索历史成功',
          duration: 800
        })
        this.$emit('delete')
      } else {
        this.$notify({
          type: 'danger',
          message: '删除检索历史失败',
          duration: 800
        })
      }
    },
    deleteAll() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '删除不可恢复！确定删除吗？'
        })
        .then(async () => {
          // on confirm
          const response = await this.$axios.post(
            `${this.deleteApiUrl}?flag=1&user_id=${this.userId}`
          )
          const { code } = response.data
          if (code === 200) {
            this.$toast.success('删除成功')
            this.$emit('delete')
          } else {
            this.$toast.danger('删除失败')
          }
        })
        .catch(() => {
          console.log('取消删除')
        })
    }
  },
  created() {}
}
</script>

<style lang="stylus" scoped>
.suggestion-item-comp
  position absolute
  width 100%
  top 40px
  left 0
  background #ffffff
  z-index 10
  box-shadow 0 8px 12px #ebedf0
  // max-height calc(100vh - 100px)
  max-height 60vh
  overflow auto
  & .suggestion-item-comp_title
    font-size 15px
    color #CCCCCC
    display flex
    align-items center
    justify-content space-between
    box-sizing border-box
    height 40px
    padding 0 10px
    & .delete-all-btn
      height 100%
      display flex
      align-items center
      padding 0 10px
  & .text-tag-list
    padding 10px
    height 36px
    display flex
    flex-direction row
    align-items center
    justify-content space-between
    font-size 15px
    & .text-tag-content
      flex 1
      height 36px
      display flex
      align-items center
    & .delete
      width 36px
      display flex
      justify-content center
      align-items center
      & .icon-clear-2
        font-size 15px
        color #CCCCCC
</style>
