import { typeOf, genID } from '@/common/utils'

export default {
  methods: {
    // 使用检索条件进行检索
    handleConditionToQuery(payload) {
      if (!payload) return
      this.useAPICondition(payload)
      this.handleQuery()
    },
    /**
     *@description 使用检索条件数据作为标签数据
     *@param {Object}queryCondition 大数据接口参数
     *@param {Boolean}replace 是否替换
     */
    useAPICondition(queryCondition, replace = true) {
      const { fix, drag } = this.convertConditionToTags(queryCondition)
      // 替换或者将数组合并
      if (replace) {
        this.fixedTags = fix
        this.dragTags = drag
      } else {
        this.fixedTags = [...this.fixedTags, ...fix]
        this.dragTags = [...this.dragTags, ...drag]
        console.log(this.dragTags, 'dragTags')
      }
    },
    // 将检索条件转化为标签数据
    convertConditionToTags(payload) {
      const {
        filter_condition: f,
        query_condition: q,
        loc_condition: l
      } = payload
      let fix = []
      if (f && f.length) {
        fix = this.convertFilterConditionToFixedTag(f)
      }
      let loc = []
      if (l && l.length) {
        loc = this.converLocCondititonToDragTag(l)
      }
      let qur = []
      console.log(q, q.length)
      if (q && q.length) {
        qur = this.convertQueryConditionToDragTag(q)
      }
      const drag = [...loc, ...qur]
      return { fix, drag }
    },
    // 格式化fliter_conditions数据以用于拖拽组件
    convertFilterConditionToFixedTag(arr) {
      const r = []
      arr.forEach(item => {
        const e = this.checkFixedTagExsit(item)
        if (!e) {
          if (!item.id) {
            item.id = genID()
          }
          r.push(item)
        }
      })
      return r
    },
    // 将loc_condition数据转为可拖拽标签
    converLocCondititonToDragTag(arr) {
      return arr.map(tag => {
        const { value, interval } = tag
        if (value.length) {
          tag.value = value.map(v => {
            if (typeOf(v) === 'string') {
              return v
            } else if (typeOf(v) === 'object' && v.key) {
              return v.key
            }
          })
        }
        return { ...tag, label: '', interval: interval || null, old: false }
      })
    },
    // 将 query_condition 转为拖拽标签数据
    convertQueryConditionToDragTag(arr) {
      return this.genPlainTagBatch(arr)
    },
    // 批量生成无状态标签
    genPlainTagBatch(arr) {
      const isAllSingleWord = arr.every(t => typeOf(t) === 'string')
      console.log('批量生成无状态标签', isAllSingleWord, arr)
      if (isAllSingleWord) {
        return [...new Set(arr)].map(item => this.genPlainTag(item))
      }
      return arr.map(item => this.genPlainTag(item))
    },
    // 生成标签
    genPlainTag(keywordOrTag) {
      const plainTag = {
        interval: null,
        old: false,
        key: '',
        color: true,
        label: '',
        pattern: '',
        id: genID()
      }
      console.log(keywordOrTag, typeOf(keywordOrTag) === 'string')
      if (typeOf(keywordOrTag) === 'string') {
        plainTag.value = [keywordOrTag]
      } else if (typeOf(keywordOrTag) === 'object') {
        if (keywordOrTag.key) {
          plainTag.value = [keywordOrTag.key]
        }
      }
      return plainTag
    }
  }
}
