var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "van-popover",
        {
          attrs: {
            overlay: _vm.synonymList.length === 0 ? false : true,
            trigger: "click",
            "close-on-click-outside": "",
          },
          scopedSlots: _vm._u([
            {
              key: "reference",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-item" }, [
                    _c("div", { staticClass: "text-item__kw" }, [
                      _vm.result.length
                        ? _c("span", { staticClass: "syn-badge" }, [
                            _vm._v(_vm._s(_vm.result.length)),
                          ])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(_vm.text) + " "),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showPopover,
            callback: function ($$v) {
              _vm.showPopover = $$v
            },
            expression: "showPopover",
          },
        },
        [
          _c(
            "div",
            { staticClass: "syn-popover" },
            [
              _c(
                "van-checkbox-group",
                {
                  model: {
                    value: _vm.result,
                    callback: function ($$v) {
                      _vm.result = $$v
                    },
                    expression: "result",
                  },
                },
                _vm._l(_vm.synonymList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "check-option" },
                    [
                      _c(
                        "van-checkbox",
                        {
                          attrs: {
                            shape: "square",
                            name: item,
                            "icon-size": "24px",
                          },
                        },
                        [_vm._v(_vm._s(item))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }