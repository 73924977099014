<template>
  <div
    class="drag-mirror"
    v-show="dragMirrorPropStart || dragMirrorPropMove"
    :style="mirrorStyle"
  >
    <div class="mirror-node">
      <div class="text-span" v-if="isCloneItem">
        {{ dragMirrorPropStart.text }}
      </div>
      <label-item :item="itemData" />
      <text-wrap :item="itemData" v-if="itemData" />
      <div class="text-node"></div>
      <div class="tooltiptext" v-if="animationHintVisible">
        <i class="iconfont icon-4"></i> 拖过去
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import TextWrap from './textWrap'
import LabelItem from './labelItem'

export default {
  name: 'drag-mirror',
  props: {},
  components: {
    TextWrap,
    LabelItem
  },
  data() {
    return {
      // 在动画中
      isAnimating: false
    }
  },
  computed: {
    ...mapState('dnd', [
      'dragMirrorPropStart',
      'dragMirrorPropMove',
      'animationHintVisible'
    ]),
    isCloneItem() {
      return (
        this.dragMirrorPropStart && this.dragMirrorPropStart.type === 'clone'
      )
    },
    itemData() {
      return this.dragMirrorPropStart ? this.dragMirrorPropStart.item : null
    },
    mirrorStyle() {
      const bgs = {
        drag: 'rgba(64, 158, 255, 0.8)',
        still: 'rgba(144, 144, 144, 0.8)'
      }

      const padding = this.isCloneItem ? '0' : `0 ${(10 / 375) * 10}rem`

      if (this.dragMirrorPropStart && !this.dragMirrorPropMove) {
        const { size, wrapSize, type } = this.dragMirrorPropStart
        const oy = parseFloat(size.y) - parseFloat(wrapSize.y)
        const background = type === 'drag' ? bgs.drag : bgs.still
        const width = `${size.width}px`
        return {
          transform: `translate3d(${size.x}px, ${oy}px, 0px)`,
          padding,
          width,
          background
        }
      } else if (this.dragMirrorPropStart && this.dragMirrorPropMove) {
        const { size, wrapSize, pos, type } = this.dragMirrorPropStart
        const background = type === 'drag' ? bgs.drag : bgs.still
        const { pos: posM } = this.dragMirrorPropMove
        const oy = parseFloat(size.y) - parseFloat(wrapSize.y)
        const dy = parseFloat(posM.clientY) - parseFloat(pos.clientY)
        const dx = parseFloat(posM.clientX) - parseFloat(pos.clientX)
        const width = `${size.width}px`
        return {
          transform: `translate3d(${size.x + dx}px, ${oy + dy}px, 0px)`,
          padding,
          width,
          background
        }
      } else {
        return { display: 'none' }
      }
    }
  },
  methods: {
    // 动画
  },
  created() {},
  mounted() {}
}
</script>

<style lang="stylus" scoped>
.drag-mirror
  position absolute
  top 0
  left 0
  z-index 3
  background rgba(64, 158, 255, 0.8)
  color #ffffff
  will-change transform
  height 40px
  font-size 14px
  overflow hidden
  display flex
  user-select none
  text-align center
  // padding 0 10px
  & .mirror-node
    display flex
    flex 1
    justify-content space-between
    align-items center
    overflow hidden
    & .tooltiptext
      width 80px
      background-color rgba(0, 0, 0, 0.5)
      color #fff
      text-align center
      padding 5px 0
      border-radius 6px
      position absolute
      z-index 1
      left 105%
    & .text-span
      flex 1
      flex-grow 1
      flex-basis 0
      overflow hidden
      text-align center
</style>
